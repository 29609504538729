import { useMutation } from "@apollo/react-hooks";
import { ChoiceList, Modal, Stack, TextField } from "@shopify/polaris";
import React, { useEffect, useState } from "react";
import { TIKTOK_FETCH_PRODUCTS_BY_ID } from "../../../graphql/mutations";
import { handleError } from "../../../helper";
import { useToastContext } from "../../shared/ToastContext";

export default function FetchProductsByIds({ open, onClose, store }) {
  const [needUpdate, setNeedUpdate] = useState([false]);

  const [ids, setIds] = useState("");
  const [fetchProductsByIds, { loading }] = useMutation(
    TIKTOK_FETCH_PRODUCTS_BY_ID,
  );

  const { toggleToast, setNotify } = useToastContext();

  const handleSubmit = React.useCallback(() => {
    if (!store.id || !ids) return;
    toggleToast && toggleToast();
    setNotify && setNotify({ err: false, msg: null });

    const newIds = Array.from(
      new Set(
        (ids || "")
          .split("\n")
          .map((i) => (i ? i.trim() : i))
          .filter(Boolean),
      ).values(),
    );

    if (newIds.length === 0) {
      setNotify({ err: true, msg: "product ids is reqiored" });
      return;
    }

    fetchProductsByIds({
      variables: {
        storeID: store.id,
        originIDs: newIds,
        needUpdate: needUpdate?.length > 0 ? needUpdate[0] : undefined,
      },
    })
      .then(() => {
        setNotify({ err: false, msg: "Fetch products success" });
      })
      .catch((err) => {
        setNotify({ err: true, msg: handleError(err.toString()) });
      })
      .finally(() => {
        onClose && onClose();
      });
  }, [
    fetchProductsByIds,
    store.id,
    onClose,
    toggleToast,
    setNotify,
    ids,
    needUpdate,
  ]);

  useEffect(() => {
    setIds("");
  }, [open]);

  const trackInputRef = React.useRef(null);

  return (
    <Modal
      title="Fetch products by ids"
      sectioned
      open={open}
      onClose={onClose}
      primaryAction={{ content: "Fetch", onAction: handleSubmit, loading }}
      secondaryActions={[{ content: "Cancel", onAction: onClose }]}
    >
      <Stack vertical>
        <TextField
          label="Product IDs"
          multiline={4}
          value={ids}
          onChange={(...args) => {
            setIds(...args);
            trackInputRef.current = true;
          }}
          error={trackInputRef.current && !ids && "Product IDs is required"}
          helpText="Enter product ids with each product id separated by a new line."
        />

        <ChoiceList
          choices={[
            { value: false, label: "Only create new product" },
            { value: true, label: "Create / update product" },
          ]}
          selected={needUpdate}
          onChange={setNeedUpdate}
        />
      </Stack>
    </Modal>
  );
}
