import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  useContext,
} from "react";
import { Filters } from "@shopify/polaris";
import { useQuery } from "@apollo/react-hooks";

import { isEmpty } from "../../helper";
import { TEAM_ROLE, USER_ROLE } from "../../variable";

import { AppContext } from "../../context";
import { FilterNoSearchPolaris } from "../filters/FilterNoSearchPolaris";
import { FilterNoSearchMVPolaris } from "../filters/FilterNoSearchMVPolaris";
import { FilterHasSearchMVPolaris } from "../filters/FilterHasSearchMVPolaris";
import { QUERY_AUTHORS } from "../product/divisions/AuthorsSelectPolaris";
import { QUERY_DIVISIONS } from "../product/divisions/ProductDivisionsPolaris";
import { FILTER_TIME } from "../order/FilterOrdersControlPolaris";
import { FilterOrderByTimePolaris } from "../order/FilterOrderByTimePolaris";
import { FilterHasSearchPolaris } from "../filters/FilterHasSearchPolaris";
import { gql } from "apollo-boost";
import { get } from "lodash";

const filterAuth = {
  limit: 20,
  offset: 0,
  teamRoles: [
    TEAM_ROLE.Admin,
    TEAM_ROLE.MarketplaceManager,
    TEAM_ROLE.StoreManager,
    TEAM_ROLE.Supporter,
  ],
};

const PRODUCT_BASES = gql`
  query productBasesForSeller($filter: ProductBaseFilter) {
    productBasesForSeller(filter: $filter) {
      total
      nodes {
        id
        title
      }
    }
  }
`;


export const ExportAmazonFilterPolaris = ({ onChange, filter }) => {
  const typingTimeRef = useRef(null);

  // State
  const [queryValue, setQueryValue] = useState(filter.search);

  const [authors, setAuthors] = useState([]);
  const [author, setAuthor] = useState({
    value: filter.authorIds?.length > 0 ? filter.authorIds : [],
    label: null,
    search: null,
  });
  const [productBases, setProductBases] = useState([]);
  const [productBase, setProductBase] = useState({
    value: filter.productBaseIds ? filter.productBaseIds : [],
    label: [],
    search: null,
  });
  const [divisions, setDivisions] = useState([]);
  const [division, setDivision] = useState({
    value: filter.divisionIds ? filter.divisionIds : [],
    label: [],
    search: null,
  });

  const [filterTime, setFilterTime] = useState({
    range: null,
    rangeLabel: null,
  });

  // Context
  const { currentUser } = useContext(AppContext);

  // Query
  const { loading: loadingAuth, error: errorAuth } = useQuery(QUERY_AUTHORS, {
    onCompleted: (res) => {
      handleAuthorData(res);
    },
    onError: () => {},
    variables: {
      filter: {
        ...filterAuth,
        search: author.search,
      },
    },
  });

  const { loading: loadingBase, error: errorBase } = useQuery(PRODUCT_BASES, {
    onCompleted: (res) => {
      handleProductBaseData(res);
    },
    onError: () => {},
    variables: {
      filter: {
        limit: 20,
        offset: 0,
        search: productBase.search,
      }
    }
  });

  const { loading: loadingDivision, error: errorDivision } = useQuery(
    QUERY_DIVISIONS,
    {
      variables: {
        filter: {
          limit: 20,
          offset: 0,
          search: division.search,
        },
      },
      onCompleted: (res) => {
        handleDivisionData(res);
      },
      onError: () => {},
    },
  );

  // Handle action
  const handleAuthorData = useCallback((data) => {
    let newData =
      data?.teamMembers?.hits?.length > 0
        ? data.teamMembers.hits
            .map((tm) => {
              if (!tm || !tm.user) return null;
              const user = tm.user;
              return {
                value: user.id,
                label: `${user.firstName} ${user.lastName}`,
              };
            })
            .filter(Boolean)
        : [];
    newData = newData.filter(Boolean);

    // Current user
    let userCurrent = {};
    if (currentUser) {
      userCurrent = {
        value: currentUser?.id,
        label: `${currentUser?.firstName} ${currentUser.lastName}`,
      };
    }
    let hasCurrent = newData.find((i) => [i.value].includes(currentUser?.id));
    if (!hasCurrent) {
      newData = [userCurrent, ...newData];
    }
    setAuthors(newData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleProductBaseData = useCallback((data) => {
    let newData = getBases(data);
    setProductBases(newData);
  }, []);

  const handleDivisionData = useCallback((data) => {
    let newData =
      data?.listProductDivision?.nodes?.length > 0
        ? data.listProductDivision.nodes.map((d) => ({
            value: d.id,
            label: d.title,
          }))
        : [];
    setDivisions(newData);
  }, []);

  const handleQueryValue = useCallback((value) => setQueryValue(value), []);

  const handleQueryValueRemove = useCallback(() => setQueryValue(null), []);
  const handleAuthorRemove = useCallback(
    () => setAuthor(() => ({ value: null, label: null, search: null })),
    [],
  );
  const handleProductBaseRemove = useCallback(
    () => setProductBase(() => ({ value: [], label: [], search: null })),
    [],
  );
  const handleDivisionRemove = useCallback(
    () => setDivision(() => ({ value: [], label: [], search: null })),
    [],
  );
  const handleFitlerTimeRemove = useCallback(
    () => setFilterTime(() => ({ range: null, rangeLabel: null })),
    [],
  );

  const handleFiltersClearAll = useCallback(() => {
    handleQueryValueRemove();
    handleAuthorRemove();
    handleProductBaseRemove();
    handleDivisionRemove();
    handleFitlerTimeRemove();
  }, [
    handleQueryValueRemove,
    handleAuthorRemove,
    handleProductBaseRemove,
    handleDivisionRemove,
    handleFitlerTimeRemove,
  ]);

  // Get data
  useEffect(() => {
    // Author
    let authorIds = filter?.authorIds;
    if (authorIds?.length) {
      let authorsMatched =
        authors?.length > 0
          ? authors.find((i) => authorIds.includes(i.value))
          : null;
      if (authorsMatched) {
        setAuthor((prev) => ({
          ...prev,
          value: authorsMatched.value,
          label: authorsMatched.label,
        }));
      }
    }

    // Product base
    let pBaseIds = filter?.productBaseIds;
    if (pBaseIds?.length) {
      let baseMatched =
        productBases?.length > 0
          ? productBases.filter((b) => pBaseIds.includes(b.value))
          : [];

      if (baseMatched.length) {
        setProductBase((prev) => ({
          ...prev,
          value: baseMatched.map((b) => b.value),
          label: baseMatched.map((b) => b.label),
        }));
      }
    }

    // Product division
    let divisionIds = filter?.divisionIds;
    if (divisionIds?.length) {
      let divisionMatched =
        divisions?.length > 0
          ? divisions.filter((b) => divisionIds.includes(b.value))
          : [];

      if (divisionMatched.length) {
        setDivision((prev) => ({
          ...prev,
          value: divisionMatched.map((b) => b.value),
          label: divisionMatched.map((b) => b.label),
        }));
      }
    }
  }, [filter, authors, productBases, divisions]);

  useEffect(() => {
    if (typingTimeRef.current) {
      clearTimeout(typingTimeRef.current);
    }
    let range = filterTime.range;
    let authorIds = author.value?.length > 0 ? [author.value] : null;
    typingTimeRef.current = setTimeout(() => {
      if (onChange) {
        onChange({
          search: queryValue ? queryValue.trim() : queryValue,
          authorIds,
          productBaseIds:
            productBase?.value?.length > 0 ? productBase.value : null,
          divisionIds: division?.value?.length > 0 ? division.value : null,
          range,
        });
      }
    }, 300);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryValue, author, productBase, division, filterTime]);

  const filters = [
    {
      key: "author",
      label: "Authors",
      filter: (
        <FilterHasSearchPolaris
          value={author}
          options={authors}
          loading={loadingAuth}
          error={errorAuth}
          onChange={({ value, label }) =>
            setAuthor((prev) => ({ ...prev, value, label }))
          }
          onChangeSearch={(search) =>
            setAuthor((prevState) => ({ ...prevState, search }))
          }
        />
      ),
    },
    {
      key: "productBase",
      label: "Product bases",
      filter: (
        <FilterHasSearchMVPolaris
          options={productBases}
          value={productBase}
          loading={loadingBase}
          error={errorBase}
          onChange={({ value, label }) =>
            setProductBase((prev) => ({
              ...prev,
              value: value ? value : [],
              label,
            }))
          }
          onChangeSearch={(search) =>
            setProductBase((prev) => ({ ...prev, search }))
          }
        />
      ),
    },
    {
      key: "division",
      label: "Product divisions",
      filter: (
        <FilterHasSearchMVPolaris
          options={divisions}
          value={division}
          loading={loadingDivision}
          erorr={errorDivision}
          onChange={({ value, label }) =>
            setDivision((prev) => ({ ...prev, value, label }))
          }
          onChangeSearch={(search) =>
            setDivision((prev) => ({ ...prev, search }))
          }
        />
      ),
    },
    {
      key: "filter-time",
      label: "Filter time by",
      filter: (
        <FilterOrderByTimePolaris
          options={FILTER_TIME}
          filterTime={filterTime}
          onChangeRange={(range, rangeLabel) => {
            setFilterTime((prevState) => ({
              ...prevState,
              range,
              rangeLabel,
            }));
          }}
        />
      ),
    },
  ];
  const appliedFilters = [];
  if (!isEmpty(author.label)) {
    const key = "author";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, author.label),
      onRemove: handleAuthorRemove,
    });
  }
  if (productBase.label.length) {
    const key = "productBase";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, productBase.label),
      onRemove: handleProductBaseRemove,
    });
  }
  if (division.label.length) {
    const key = "division";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, division.label),
      onRemove: handleDivisionRemove,
    });
  }
  if (!isEmpty(filterTime.rangeLabel) && filterTime.range) {
    const key = "filter-time";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, filterTime.rangeLabel),
      onRemove: handleFitlerTimeRemove,
    });
  }
  return (
    <Filters
      filters={filters}
      appliedFilters={appliedFilters}
      queryPlaceholder="Filter file"
      queryValue={queryValue}
      onQueryChange={handleQueryValue}
      onClearAll={handleFiltersClearAll}
      onQueryClear={handleQueryValueRemove}
    />
  );

  function disambiguateLabel(key, value) {
    switch (key) {
      case "author":
        return `Author: ${value}`;
      case "productBase":
        let pBaseLabel = value && value.length > 0 ? value.join(", ") : "";
        return `Product bases: ${pBaseLabel}`;
      case "division":
        let divisionLabel = value && value.length > 0 ? value.join(", ") : "";
        return `Product divisions: ${divisionLabel}`;
      case "filter-time":
        return `Time: ${value}`;
      default:
        return value;
    }
  }
};

function getBases(data) {
  const nodes = get(data, "productBasesForSeller.nodes") || [];
  return nodes
    .map((i) => (i ? { value: i.id, label: i.title } : null))
    .filter(Boolean);
}
