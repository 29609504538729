import { useMutation } from "@apollo/react-hooks";
import React from "react";
import { Modal } from "@shopify/polaris";

import { REINDEX_ALL_STORE_ORDERS } from "../../../graphql/mutations";
import { handleError } from "../../../helper";
import { useToastContext } from "../../shared/ToastContext";

export default function IndexOrderByStore({ open, onClose, store }) {
  const { id } = store;

  const [indexOrderByStore, { loading }] = useMutation(
    REINDEX_ALL_STORE_ORDERS,
  );

  const { toggleToast, setNotify } = useToastContext();

  const handleSubmit = React.useCallback(() => {
    if (!id) return;

    indexOrderByStore({
      variables: {
        storeID: id,
      },
    })
      .then(() => {
        setNotify({ err: false, msg: "Index Order for this Store success" });
      })
      .catch((err) => {
        setNotify({ err: true, msg: handleError(err.toString()) });
      })
      .finally(() => {
        onClose && onClose();
      });
  }, [indexOrderByStore, id, onClose, toggleToast, setNotify]);

  return (
    <Modal
      title="Index Order"
      sectioned
      open={open}
      onClose={onClose}
      primaryAction={{ content: "Submit", onAction: handleSubmit, loading }}
      secondaryActions={[{ content: "Cancel", onAction: onClose }]}
    >
      <p>Are you sure to Index Order for this Store?</p>
    </Modal>
  );
}
