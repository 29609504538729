import { CaretRightOutlined } from "@ant-design/icons";
import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import {
  Card,
  Checkbox,
  Collapsible,
  DataTable,
  Heading,
  Scrollable,
  Spinner,
  Stack,
} from "@shopify/polaris";
import startCase from "lodash/startCase";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import { REPORT_SALE_BY_PRODUCT_BASES } from "../../../graphql/queries";
import { arrInvalid, formatter, reducerFn, removeEl } from "../../../helper";
import { EmptyStatePolaris } from "../../shared/EmptyStatePolaris";
import { useReportsContext } from "../context";
import { ComparedPercent } from "./ComparedPercent";
import { useCollapseContext } from "./LayoutSection";
import { PLATFORMS } from "../../../variable";
import { useStateByBase } from "../hooks/useStateByBase";
import { get } from "lodash";
import { genTable } from "../utils";

const collapseKey = "product-base";
export default function ProductBase({ saleChanel: saleChannel }) {
  // Context
  const {
    range,
    filter: filterCtx,
    isCompare,
    rangeToCompare,
    teamID,
  } = useReportsContext();

  const { collapseActive = {}, setCollapseActive = () => {} } =
    useCollapseContext() || {};
  const open = !!collapseActive[collapseKey];

  // State
  const [filter, setFilter] = useState({
    limit: 20,
    offset: 0,
    saleChannel,
    filterByTime: range,
    productBaseIDs: null,
    storeManagerID: null,
    storeID: null,
    fulfillmentID: undefined,
  });

  const [state, setState] = useReducer(reducerFn, {
    rows: [],
    data: 0,
    totalSale: 0,
    totalBaseCost: 0,
    totalRevenues: 0,

    loadMore: false,
    cantLoad: false,
    loadOwnerStore: false,
    count: {},
    currentScroll: null,
  });

  const [stateCompare, setStateCompare] = useState({
    totalSale: 0,
    totalBaseCost: 0,
    totalRevenues: 0,
  });
  const timeoutRef = useRef(null);
  const firstRequest = React.useRef(true);

  // Queries
  const { loading, fetchMore } = useQuery(REPORT_SALE_BY_PRODUCT_BASES, {
    variables: {
      filter,
    },
    onCompleted: (res) => {
      const count = getCount(res);
      if (!firstRequest.current) return;
      const result = formatData(res);

      const obLoadMore = checkPlatformLoadMore(
        res.reportSaleByProductBases,
        filter.limit,
      );
      setState({
        data: res.reportSaleByProductBases || [],
        rows: result.values,
        ...obLoadMore,
        ...count,
      });
    },
    fetchPolicy: "cache-and-network",
  });

  const [lazyReport, { data: dataC }] = useLazyQuery(
    REPORT_SALE_BY_PRODUCT_BASES,
  );

  useEffect(() => {
    if (filterCtx != null) {
      let {
        collectionIds,
        tagIds,
        productBaseIds: productBaseIDs,
        search,
        viewWholeTeamReport,
        ...restFilter
      } = filterCtx;

      setFilter((prev) => ({
        ...prev,
        productBaseIDs,
        ...restFilter,
        limit: 20,
        offset: 0,
      }));

      firstRequest.current = true;
    }
  }, [filterCtx]);

  useEffect(() => {
    if (isCompare) {
      let filterByTime = null;
      if (rangeToCompare?.from != null) {
        filterByTime = {
          ...filter.filterByTime,
          ...rangeToCompare,
        };
      }
      lazyReport({
        variables: {
          filter: {
            ...filter,
            filterByTime,
          },
        },
      });
    }
  }, [rangeToCompare, filter, isCompare]);

  useEffect(() => {
    if (!dataC) return;
    if (isCompare) {
      const count = getCount(dataC);
      setStateCompare((prev) => ({
        ...prev,
        totalSale: count.totalSale,
        totalBaseCost: count.totalBaseCost,
        totalRevenues: count.totalRevenues,
      }));
    } else {
      setStateCompare({ totalSale: 0 });
    }
  }, [dataC, isCompare]);

  useEffect(() => {
    setFilter((prev) => ({
      ...prev,
      filterByTime: range,
      teamID: teamID ?? undefined,
      limit: 20,
      offset: 0,
    }));

    firstRequest.current = true;
    setState({ cantLoad: false });
  }, [range, teamID]);

  const btnRef = useRef(null);
  const handleToggle = useCallback(() => {
    setCollapseActive((p) => ({ ...p, [collapseKey]: !p[collapseKey] }));
  }, [collapseKey, setCollapseActive]);
  const handleTriggerBtn = useCallback(() => {
    btnRef.current && btnRef.current.click();
  }, []);

  let subTitle = "";
  const headerMarkup = useMemo(
    () => (
      <Stack spacing="loose">
        <Stack.Item fill>
          <HeadingWrap>
            <span className="btn-wrap">
              <CaretRightOutlined
                rotate={open ? 90 : 0}
                style={{ color: "#000" }}
                ref={btnRef}
                onClick={handleToggle}
              />
            </span>
            <h2 className="Polaris-Heading" onClick={handleTriggerBtn}>
              Product Base sale stats
            </h2>
          </HeadingWrap>
        </Stack.Item>
        <span>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>
              <span>{state.totalSale} Line items</span>
              <ComparedPercent
                originalVal={state.totalSale}
                newVal={stateCompare.totalSale}
              />
            </span>

            <span>
              <span>Total cost: {formatter.format(state.totalBaseCost)}</span>
              <ComparedPercent
                originalVal={state.totalBaseCost}
                newVal={stateCompare.totalBaseCost}
              />
            </span>
            <span>
              <span>
                Total revenue: {formatter.format(state.totalRevenues)}
              </span>
              <ComparedPercent
                originalVal={state.totalRevenues}
                newVal={stateCompare.totalRevenues}
              />
            </span>
          </div>
        </span>
      </Stack>
    ),
    [subTitle, open, state.totalSale, state.totalCost, state.totalRevenues],
  );

  const handleScroll = React.useCallback(
    (key) => () => {
      timeoutRef.current && clearTimeout(timeoutRef);
      firstRequest.current = false;

      if (!state.cantLoad) {
        setState({ loadMore: true, currentScroll: key });
        timeoutRef.current = setTimeout(() => {
          fetchMore({
            variables: {
              filter: {
                ...filter,
                offset: filter.offset + filter.limit,
              },
            },
            updateQuery: (prev, { fetchMoreResult, variables }) => {
              setFilter((p) => ({ ...p, offset: variables.filter.offset }));
              if (!fetchMoreResult) return prev;
              const reportSaleByProductBases =
                fetchMoreResult.reportSaleByProductBases;

              const values = mergeValues(state.data, reportSaleByProductBases);
              const result = formatData({
                reportSaleByProductBases: { ...values },
              });
              const newState = {
                loadMore: false,
                currentScroll: null,
                rows: result.values,
                data: values,
              };

              const obLoadMore = checkPlatformLoadMore(
                reportSaleByProductBases,
                filter.limit,
              );
              if (Object.values(obLoadMore).every((i) => i === false)) {
                newState.cantLoad = true;
              }

              setState({ ...newState, ...obLoadMore });
              return {
                ...prev,
              };
            },
          });
        }, 500);
      }
    },
    [filter, state.cantLoad, state.data],
  );

  return (
    <Card>
      <Card.Header title={headerMarkup}></Card.Header>
      <Card.Section>
        <Collapsible
          open={open}
          id={collapseKey}
          transition={{
            duration: "500ms",
            timingFunction: "ease-in-out",
          }}
          expandOnPrint
        >
          {[firstRequest.current, loading].every((item) => item === true) ? (
            <SpinnerCustom />
          ) : state.rows?.length > 0 ? (
            <Stack vertical spacing="loose" wrap={false}>
              {state.rows.map((row, index) => (
                <PlatformWapper
                  key={`${row.title}-${index}`}
                  platform={row}
                  hasSaleChannel={!!filter.saleChannel}
                  canLoad={state[`load_more_${row.key}`]}
                  loadMore={state.loadMore && state.currentScroll === row.key}
                  handleScroll={handleScroll(row.key)}
                  limit={filter.limit}
                  count={state.count[row.key]}
                  filterByTime={filter.filterByTime}
                />
              ))}
            </Stack>
          ) : (
            <EmptyStatePolaris noData slim />
          )}
        </Collapsible>
      </Card.Section>
    </Card>
  );
}

function PlatformWapper({
  platform,
  hasSaleChannel,
  loadMore,
  canLoad,
  handleScroll,
  count,
  filterByTime,
}) {
  const [rows, setRows] = React.useState([]);
  const platformRef = React.useRef();

  const [state, setState] = React.useReducer(reducerFn, {
    loadMore: false,
  });

  let saleChannel = platform.key;
  if (saleChannel === "onlineStore") {
    saleChannel = "online store";
  }

  const [getState, stateValues] = useStateByBase({
    filterByTime,
    saleChannel,
  });

  const rowsRef = useRef(new Set());
  React.useEffect(() => {
    const newRows = (platform.values || [])
      .map((item, index) => {
        const row = [
          <div className="col-index">{index + 1}</div>,
          <div className="state-name">
            <span>{item.baseTitle}</span>
          </div>,
          <div className="total-sale">
            <span>{item.totalSale}</span>
          </div>,
          <div className="total-cost">
            <span>{formatter.format(item.totalBaseCost)}</span>
          </div>,
          <div className="total-revenue">
            <span>{formatter.format(item.totalRevenues)}</span>
          </div>,
        ];

        return [
          row,
          [
            <div
              data-base-id={item.baseID}
              style={{ display: "none" }}
              className="state-slot"
              ref={(n) => rowsRef.current.add(n)}
            />,
          ],
        ];
      })
      .flat();

    setRows(newRows);
  }, [platform.values]);

  React.useEffect(() => {
    if (!state.loadMore) return;

    const baseIds = getBaseIds(platform);
    if (baseIds.length > 0) {
      getState(baseIds);
    }
  }, [platform.values, state.loadMore]);

  React.useLayoutEffect(() => {
    if (rowsRef.current.size) {
      for (let row of Array.from(rowsRef.current.values())) {
        if (!row || !row.parentNode) continue;

        const baseId = row.getAttribute("data-base-id");
        if (row.parentNode && !row.parentNode.classList.contains("slot")) {
          row.parentNode.classList.add("slot");
          row.parentNode.setAttribute("colspan", 5);
        }

        let mapStores = stateValues.data[baseId];
        if (state.loadMore) {
          if (mapStores?.length > 0) {
            mapStores = mapStores.sort((a, b) => b.totalSale - a.totalSale);
            genTable(row.parentNode, mapStores, genStates);
          }
        } else {
          if (row.parentNode) {
            const table = row.parentNode.querySelector("table");
            if (table) {
              removeEl(table);
            }
          }
        }
      }
    }
  }, [rowsRef.current.size, stateValues.data, state.loadMore]);

  const chilMarkup = (
    <div ref={platformRef}>
      <Stack vertical spacing="loose" wrap={false}>
        <Scrollable
          shadow
          id={`scrollable-product-base`}
          style={{
            maxHeight: "30rem",
            paddingRight: "1.6rem",
            marginRight: "-1.6rem",
          }}
          onScrolledToBottom={canLoad ? handleScroll : () => {}}
        >
          <Wrapper>
            <DataTable
              rows={rows}
              columnContentTypes={["text", "text", "text", "text", "text"]}
              headings={["#", "Title", "Sales", "Total Cost", "Total Revenue"]}
              hideScrollIndicator
            />
          </Wrapper>
        </Scrollable>
        {canLoad && loadMore && <SpinnerCustom />}
      </Stack>
    </div>
  );

  const handleLoadMore = React.useCallback((checked) => {
    setState({ loadMore: checked });
  }, []);

  return hasSaleChannel ? (
    chilMarkup
  ) : (
    <Card
      sectioned
      title={
        <Stack spacing="extraTight" vertical>
          <Stack.Item fill>
            <Heading element="h3">{platform.title || ""}</Heading>
          </Stack.Item>
          <span>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                columnGap: "2rem",
              }}
            >
              <span>
                <b>Line items:</b>
                <span> {count.totalSale}</span>
              </span>
              <span>
                <b>Total cost: </b>
                <span> {formatter.format(count.totalBaseCost)}</span>
              </span>
              <span>
                <b>Total revenue: </b>
                <span> {formatter.format(count.totalRevenues)}</span>
              </span>
            </div>
          </span>
          <Checkbox
            label="Breakdown by States"
            onChange={handleLoadMore}
            checked={state.loadMore}
          />
        </Stack>
      }
    >
      {chilMarkup}
    </Card>
  );
}

const SpinnerCustom = () => (
  <Stack distribution="center">
    <Spinner size="small" />
  </Stack>
);

function getCount(data) {
  let res = {
    totalSale: 0,
    totalBaseCost: 0,
    totalRevenues: 0,
  };

  const keys = Object.keys(data?.reportSaleByProductBases || {});
  const source = data?.reportSaleByProductBases;
  const aggregations = data?.reportSaleByProductBases?.aggregations;

  const mapCountByPlatform = (platforms) => {
    const total = {
      totalSale: 0,
      totalBaseCost: 0,
      totalRevenues: 0,
    };
    const otps = (aggregations || []).filter((item) =>
      platforms.includes(item.platform),
    );

    for (let opt of otps) {
      total.totalSale += opt.totalSale || 0;
      total.totalBaseCost += opt.totalBaseCost || 0;
      total.totalRevenues += opt.totalRevenues || 0;
    }

    return total;
  };

  const count = {};
  for (let key of keys) {
    if (key === "__typename" || key === "aggregations") continue;
    if (source[key] && Array.isArray(source[key]) && source[key].length > 0) {
      let platforms = [key];
      if (key === "onlineStore") {
        platforms = [
          PLATFORMS.WooCommerce,
          PLATFORMS.ShopBase,
          PLATFORMS.Shopify,
        ];
      }

      const total = mapCountByPlatform(platforms);
      count[key] = total;
    }
  }

  for (let item of aggregations) {
    res.totalSale += item.totalSale;
    res.totalBaseCost += item.totalBaseCost;
    res.totalRevenues += item.totalRevenues;
  }

  return { ...res, count };
}

function formatData(data) {
  const values = [];
  const keys = Object.keys(data?.reportSaleByProductBases);
  const source = data?.reportSaleByProductBases;

  for (let key of keys) {
    if (key === "__typename" || key === "aggregations") continue;
    if (source[key] && Array.isArray(source[key]) && source[key].length > 0) {
      values.push({
        title: startCase(key),
        key,
        values: source[key],
      });
    }
  }
  return { values };
}

function mergeValues(prevData, data) {
  const prevKeys = Object.keys(prevData);
  const res = {};

  for (const key of prevKeys) {
    if (key === "__typename" || key === "aggregations") continue;
    const prevValues = [...(prevData[key] || [])];
    const newValues = [...(data[key] || [])];

    const merge = Array.from(
      new Map(
        [...prevValues, ...newValues]
          .map((item) => (item?.baseID ? [item.baseID, item] : null))
          .filter(Boolean),
      ).values(),
    );

    if (merge.length > 0) {
      res[key] = merge;
    }
  }

  return res;
}

function checkPlatformLoadMore(data, limit) {
  const keys = Object.keys(data);
  let res = {};
  for (const key of keys) {
    if (key === "__typename" || key === "aggregations") continue;
    res[`load_more_${key}`] =
      !arrInvalid(data[key]) && data[key].length >= limit;
  }
  return res;
}

function getBaseIds(data) {
  const node = get(data, "values") || [];
  return node.map((item) => item?.baseID).filter(Boolean);
}

function genStates(data) {
  const userDiv = document.createElement("div");
  userDiv.title = data.state || "";
  userDiv.innerHTML = `<span role="img" aria-label="minus" class="anticon anticon-minus">
    <svg
      viewBox="64 64 896 896"
      focusable="false"
      data-icon="minus"
      width="10px"
      height="10px"
      fill="currentColor"
      aria-hidden="true"
    >
      <path d="M872 474H152c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h720c4.4 0 8-3.6 8-8v-60c0-4.4-3.6-8-8-8z"></path>
    </svg>
  </span> ${data.state || ""}`;
  userDiv.className = "state-name state-wrapper";
  return userDiv;
}

const HeadingWrap = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-left: -1.4rem;

  .Polaris-Heading {
    cursor: pointer;
  }

  .btn-wrap {
    margin-top: 0.2rem;
    margin-right: 0.5rem;
  }
`;

const Wrapper = styled.div`
  .item {
    white-space: normal;
    word-break: break-word;
  }

  .col-index {
    width: 20px;
    display: inline-block;
  }

  .state-name {
    white-space: break-spaces;
    width: 300px;
  }

  .total-sale,
  .total-cost,
  .total-revenue {
    width: 100px;
    display: inline-block;
  }

  .Polaris-DataTable__Cell {
    &.slot {
      padding: 0;
      display: table-cell !important;

      .Polaris-DataTable__Cell {
        background-color: #f9fafb;
        padding-top: 5px;
        padding-bottom: 5px;
      }

      .state-wrapper {
        display: flex;
        overflow: hidden;
        flex-wrap: wrap;
        white-space: break-spaces;
        padding-left: 16px;
        width: 300px;
      }
    }

    &:has(.state-slot) {
      display: none;
    }
  }
`;
