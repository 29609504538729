import React, { useState, useCallback, useEffect } from "react";
import { Autocomplete } from "@shopify/polaris";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";

import { ComponentLabelPolaris } from "../shared/ComponentLabelPolaris";
import { reducerFn } from "../../helper";
import useTimeout from "../../hooks/useTimeout";

const productBaseQuery = gql`
  query productBaseByFulfillment($filter: ProductBaseByFulfillmentFilter!) {
    productBaseByFulfillment(filter: $filter) {
      id
      name
    }
  }
`;

export const ProductBaseSelectPolaris = (props) => {
  const { fulfillmentId, onChange, error } = props;

  const [inputValue, setInputValue] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [deselectedOptions, setDeselectedOptions] = useState([]);
  const [options, setOptions] = useState([]);

  const [filter, setFilter] = React.useReducer(reducerFn, {
    limit: 20,
    offset: 0,
    fulfillmentId: fulfillmentId ? fulfillmentId : null,
    search: inputValue,
  });

  const { data, loading } = useQuery(productBaseQuery, {
    variables: {
      filter,
    },
  });

  useEffect(() => {
    setSelectedOptions([]);
    setInputValue("");
    setFilter({ fulfillmentId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fulfillmentId]);

  useEffect(() => {
    let newData =
      data && data.productBaseByFulfillment
        ? data.productBaseByFulfillment.map((pb) => ({
            value: pb.id,
            label: pb.name,
          }))
        : [];
    setDeselectedOptions(newData);
    setOptions(newData);
  }, [data]);

  const [delayFn] = useTimeout();
  const handleInputChange = useCallback(
    (value) => {
      setInputValue(value);

      delayFn(() => {
        setFilter({ search: value });
      });
      // if (!value) {
      //   setOptions(deselectedOptions);
      //   return;
      // }

      // const filterRegex = new RegExp(value, "i");
      // const resultOptions = deselectedOptions.filter((option) =>
      //   option.label.match(filterRegex)
      // );

      // setOptions(resultOptions);
    },
    [deselectedOptions],
  );

  const handleSection = useCallback(
    (selected) => {
      const selectedValue = selected.map((selectedItem) => {
        const matchedOptions = options.find((option) => {
          return option.value === selectedItem;
        });
        return matchedOptions && matchedOptions.label;
      });
      setSelectedOptions(selected);
      let value = selected ? selected[0] : null;
      let label = selectedValue ? selectedValue[0] : "";
      setInputValue(label);
      if (onChange) {
        onChange(value);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [options],
  );

  const textField = (
    <Autocomplete.TextField
      label="Product base"
      labelHidden
      value={inputValue}
      onChange={handleInputChange}
      error={error}
    />
  );

  return (
    <>
      <ComponentLabelPolaris label="Product base" required />
      <Autocomplete
        textField={textField}
        selected={selectedOptions}
        onSelect={handleSection}
        options={options}
        emptyState={<span>No items found!</span>}
        loading={loading}
      />
    </>
  );
};
