import { ActionList, Button, Popover, Toast } from "@shopify/polaris";
import {
  DeleteMajorMonotone,
  ManagedStoreMajorTwotone,
  MarkPaidMinor,
  RefreshMajorMonotone,
  RefreshMinor,
} from "@shopify/polaris-icons";
import React, { Fragment, useCallback, useState } from "react";
import history from "../../../history";
import useToggle from "../../../hooks/useToggle";
import MarkStoreStatus from "../../store/MarkStoreStatus";
import DeleteEtsyButton from "../DeleteEtsyButton";
import { FetchOrder } from "./FetchOrder";
import { FetchSingleOrder } from "./FetchSingleOrder";
import { RegenAPIKey } from "./RegenAPIKey";
import IndexOrderByStore from "../../tiktok/actions/index-order-by-store";

export * from "./FetchOrder";
export * from "./FetchSingleOrder";

export function Actions({ store, refetch, currentParam, isEtsy }) {
  // State
  const [open, toggleOpen] = useToggle(false);
  const [showModal, toggleShowModal] = useToggle(false);
  const [action, setAction] = useState(null);
  const [activeToast, toggleActiveToast] = useToggle(false);
  const [notify, setNotify] = useState({
    msg: null,
    err: false,
  });

  // Handle actions
  const id = store?.id;
  const onAction = useCallback(
    (act) => {
      setAction(act);
      toggleShowModal();
    },
    [id],
  );

  const isDeactivated = store?.status === "Deactivated";
  const actions = [
    isEtsy && {
      content: "Fetch Single Order",
      icon: RefreshMajorMonotone,
      onAction: () => onAction("fetchSingleOrder"),
    },
    isEtsy && {
      content: "Fetch Orders",
      icon: RefreshMinor,
      onAction: () => onAction("fetchOrder"),
    },
    {
      content: "Manage",
      icon: ManagedStoreMajorTwotone,
      onAction: () => history.push(`/${currentParam}/store/edit/${store.id}`),
    },
    store.apiKey && {
      content: "Re-gen API Key",
      icon: RefreshMinor,
      onAction: () => onAction("reGen"),
    },
    {
      content: `Mark as ${isDeactivated ? "Connected" : "Deactivated"}`,
      icon: MarkPaidMinor,
      onAction: () => onAction("markStatus"),
    },
    {
      content: "Index Order",
      icon: RefreshMajorMonotone,
      onAction: () => onAction("indexOrder"),
    },
    {
      content: "Delete",
      icon: DeleteMajorMonotone,
      onAction: () => onAction("delete"),
    },
  ].filter(Boolean);

  const props = {
    open: showModal,
    onClose: toggleShowModal,
    storeId: store?.id,
    refetch,
    store,
    toggleActiveToast,
    setNotify,
    isDeactivated,
  };
  const actionsMarkup = {
    fetchSingleOrder: <FetchSingleOrder {...props} />,
    fetchOrder: <FetchOrder {...props} />,
    delete: <DeleteEtsyButton {...props} />,
    reGen: <RegenAPIKey {...props} />,
    markStatus: <MarkStoreStatus {...props} />,
    indexOrder: <IndexOrderByStore {...props} />,
  };

  const activator = (
    <Button children="Actions" disclosure onClick={toggleOpen} />
  );

  const toastMarkup = activeToast && notify.msg && (
    <Toast
      content={notify.msg}
      error={notify.err}
      duration={2000}
      onDismiss={toggleActiveToast}
    />
  );

  return (
    <Fragment>
      {toastMarkup}
      <Popover activator={activator} onClose={toggleOpen} active={open}>
        <ActionList items={actions} />
      </Popover>
      {actionsMarkup[action]}
    </Fragment>
  );
}
