import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import {
  Button,
  Card,
  Form,
  FormLayout,
  InlineError,
  Page,
  TextField,
  Toast,
} from "@shopify/polaris";
import { gql } from "apollo-boost";
import { get, update } from "lodash";
import React, { useCallback, useState } from "react";
import { handleError } from "../../helper";
import history from "../../history";
import { ComponentLabelPolaris } from "../shared/ComponentLabelPolaris";
import { useToastContext } from "../shared/ToastContext";
import { SwitchFulfillmentProvider } from "./context";
import { FulfillmentSelectPolaris } from "./FulfillmentSelectPolaris";
import { OrderSourcePolaris } from "./OrderSourcePolaris";
// import { ProductBaseSelectPolaris } from "./ProductBaseSelectPolaris";
import { ProductBaseSelectPolaris } from "./product-base-select-v2";
// import FulfillmentSelect from "./FulfillmentSelect";
// import ProductBaseSelect from "./ProductBaseSelect";
import SwitchFulfillmentForm from "./SwitchFulfillmentForm";

const createSwitchFulfillmentMutation = gql`
  mutation createSwitchFulfillment($input: CreateSwitchFulfillmentInput!) {
    createSwitchFulfillment(input: $input) {
      id
      title
    }
  }
`;

const productBaseVariantsQuery = gql`
  query productBaseVariantSelect($productBaseId: ID!) {
    productBaseVariantSelect(productBaseId: $productBaseId) {
      id
      name
    }
  }
`;

const AddSwitchFulfillment = () => {
  const [title, setTitle] = useState("");
  const [fulfillment, setFulfillment] = useState(null);
  const [allFulfillment, setAllFulfillment] = useState([]);
  const [productBase, setProductBase] = useState(null);
  const [step, setStep] = useState(1);
  const [error, setError] = useState({});
  const [disabled, setDisabled] = useState(true);
  const [items, setItems] = useState([]);
  // const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [orderSource, setOrderSource] = useState(["nil"]);
  const [shippingOption, setShippingOption] = useState({
    usingShippingOption: false,
    shippingOptionConfigs: new Map(),
  });

  const shippingOptionConfigs = [];
  if (typeof shippingOption.shippingOptionConfigs === "object") {
    for (let item of shippingOption.shippingOptionConfigs.values()) {
      const { mappingOptions, ...rest } = item || {};
      const opts = [];
      if (typeof mappingOptions === "object") {
        for (let entry of mappingOptions.values()) {
          opts.push(entry);
        }
      }
      if (opts.length > 0) {
        shippingOptionConfigs.push({ ...rest, mappingOptions: opts });
      }
    }
  }

  const [getBase] = useLazyQuery(productBaseVariantsQuery, {
    onCompleted: (res) => {
      const variants = getBaseVariant(res);
      setProductBase(p => ({...p, variants}));
      setStep(2);
    },
  });

  const [create, { loading }] = useMutation(createSwitchFulfillmentMutation, {
    onCompleted: () => {
      setItems([]);
      history.push(`/seller/fulfillment/switch`);
    },
    onError: (err) => {
      setMessage(handleError(err.toString()));
    },
  });
  const validate = () => {
    let isValid = true;
    if (!title || !productBase) {
      isValid = false;
    }
    setError((prevState) => {
      return {
        ...prevState,
        title: !title ? "Enter switch title" : null,
        productBase: !productBase ? "Select product base" : null,
      };
    });
    return isValid;
  };

  const handleSubmit = useCallback(() => {
    const haveDup = countIterate(items);
    if (haveDup) {
      setMessage("Only keep one setting per target variant");
      return;
    } else {
      setMessage(null);
    }

    const newItems = items.map(({ orderSource, ...rest }) => {
      if (
        !orderSource ||
        orderSource.length === 0 ||
        orderSource[0] === "nil"
      ) {
        return {
          ...rest,
          orderSource: undefined,
        };
      }

      return {
        ...rest,
        orderSource,
      };
    });

    const input = {
      title: title,
      productBaseId: productBase.id,
      fulfillmentId: fulfillment ? fulfillment.id : null,
      items: newItems,
      orderSource:
        orderSource?.length && orderSource[0] === "nil" ? null : orderSource,
      usingShippingOption: shippingOption.usingShippingOption,
      shippingOptionConfigs:
        shippingOption.usingShippingOption && shippingOptionConfigs?.length > 0
          ? shippingOptionConfigs
          : undefined,
    };

    create({
      variables: {
        input,
      },
    });
  }, [
    create,
    items,
    title,
    productBase,
    fulfillment,
    orderSource,
    shippingOption,
    shippingOptionConfigs,
  ]);

  return (
    <div>
      <Page
        fullWidth
        breadcrumbs={[
          {
            content: (
              <div
                className={"b-link"}
                onClick={() => {
                  if (step === 2) {
                    setStep(1);
                  } else {
                    history.push(`/seller/fulfillment/switch`);
                  }
                }}
              >
                Switch Fulfillment
              </div>
            ),
          },
        ]}
        primaryAction={
          step === 2 ? (
            <Button
              onClick={handleSubmit}
              loading={loading}
              primary
              disabled={disabled}
            >
              Save
            </Button>
          ) : null
        }
        title="Add Switch Fulfillment"
      >
        {step === 1 && (
          <Card sectioned>
            <Form
              onSubmit={() => {
                if (validate()) {
                  // setStep(2);
                  if (productBase.id) {
                    getBase({
                      variables: {
                        productBaseId: productBase.id,
                      },
                    });
                  }
                }
              }}
            >
              <FormLayout>
                <div>
                  <ComponentLabelPolaris label="Switch title" required />
                  <TextField
                    error={!!get(error, "title", null)}
                    value={title}
                    helpText={<InlineError message={get(error, "title", "")} />}
                    onChange={(v) => setTitle(v)}
                    // label="Switch Title(*)"
                    type="text"
                    placeholder="Enter switch title"
                  />
                </div>

                {/* <FulfillmentSelect
                  onLoaded={(data) => setAllFulfillment(data)}
                  value={fulfillment ? fulfillment.id : null}
                  error={get(error, "fulfillment")}
                  onChange={(id, ff) => {
                    setFulfillment(ff);
                  }}
                /> */}
                <FulfillmentSelectPolaris
                  onLoaded={(data) => setAllFulfillment(data)}
                  value={fulfillment ? fulfillment.id : null}
                  error={get(error, "fulfillment")}
                  onChange={(_id, ff) => {
                    setFulfillment(ff);
                  }}
                />
                {/* <ProductBaseSelect
                  isAddForm={true}
                  value={productBase ? productBase.id : null}
                  onChange={(id, b) => {
                    setProductBase(b);
                  }}
                  error={get(error, "productBase")}
                  fulfillmentId={fulfillment ? fulfillment.id : null}
                /> */}
                <ProductBaseSelectPolaris
                  isAddForm
                  value={productBase ? productBase.id : null}
                  onChange={(_id, b) => {
                    setProductBase(b);
                  }}
                  error={get(error, "productBase")}
                  fulfillmentId={fulfillment ? fulfillment.id : null}
                />
                <OrderSourcePolaris
                  value={orderSource}
                  onChange={(newValue) => {
                    setOrderSource(() => [...newValue]);
                  }}
                />
                <Button submit>Continue</Button>
              </FormLayout>
            </Form>
          </Card>
        )}
        {step === 2 && (
          <SwitchFulfillmentProvider
            shippingOption={shippingOption}
            setShippingOption={setShippingOption}
            fulfillment={fulfillment || {}}
            allFulfillment={allFulfillment || []}
            orderSource={orderSource}
          >
            <SwitchFulfillmentForm
              onChange={(value) => {
                setItems(value);
                let isValid = true;
                for (let i = 0; i < value.length; i++) {
                  const item = value[i];
                  if (
                    !item.variantId ||
                    !item.targetProductBaseId ||
                    !item.targetVariantId
                  ) {
                    isValid = false;
                    break;
                  }
                }
                setDisabled(!isValid);
              }}
              allFulfillment={allFulfillment}
              productBase={productBase}
              fulfillment={fulfillment}
              title={title}
            />
          </SwitchFulfillmentProvider>
        )}
        {message && (
          <Toast
            error
            content={message}
            onDismiss={() => {
              setMessage(null);
            }}
          />
        )}
      </Page>
    </div>
  );
};

function genKey(item) {
  const {
    variantId,
    targetFulfillmentId,
    targetProductBaseId,
    targetVariantId,
  } = item;
  const key = [
    variantId,
    targetFulfillmentId,
    targetProductBaseId,
    targetVariantId,
  ]
    .filter(Boolean)
    .join("-");
  return key;
}

export function countIterate(items) {
  if (!items || items.length === 0) return false;
  const res = items.reduce((acc, item) => {
    const key = genKey(item);
    acc[key] = (acc[key] || 0) + 1;
    return acc;
  }, {});

  return Object.values(res).some((val) => val > 1);
}

export default AddSwitchFulfillment;

function getBaseVariant(res) {
  let variants = get(res, "productBaseVariantSelect") || [];
  return variants;
}
