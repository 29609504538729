import { InvoiceDetailsPagePolaris } from "../components/admin/invoices/InvoiceDetailsPagePolaris";
import MainLayout from "../layout/MainLayout";
import { InvoicesPagePolaris } from "../pages/admin/InvoicesPagePolaris";
import {
  AmazonChannelPage,
  EbayChannelPage,
  EtsyChannelPage,
  FacebookMKPage,
  OnlineStoresPage,
  TiktokChannelPage,
} from "../pages/reports";
import { DesignTasksPagePolaris } from "../pages/seller/DesignTasksPagePolaris";

const routes = [
  {
    path: "/seller-accountant/reports/amazon",
    exact: true,
    component: AmazonChannelPage,
    title: "Amazon Channel Page",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Accountant",
  },
  {
    path: "/seller-accountant/reports/tiktok",
    exact: true,
    component: TiktokChannelPage,
    title: "Tiktok Channel Page",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Accountant",
  },
  {
    path: "/seller-accountant/reports/facebook-mk",
    exact: true,
    component: FacebookMKPage,
    title: "Facebook Marketplace Channel Page",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Accountant",
  },
  {
    path: "/seller-accountant/reports/online-stores",
    exact: true,
    component: OnlineStoresPage,
    title: "Online stores Page",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Accountant",
  },
  {
    path: "/seller-accountant/reports/etsy",
    exact: true,
    component: EtsyChannelPage,
    title: "Etsy Channel Page",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Accountant",
  },
  {
    path: "/seller-accountant/reports/ebay",
    exact: true,
    component: EbayChannelPage,
    title: "Ebay Channel Page",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Accountant",
  },
  {
    path: "/seller-accountant/design-tasks",
    exact: true,
    // component: DesignTasksPage,
    component: DesignTasksPagePolaris,
    title: "All Tasks",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Accountant",
  },
  {
    path: "/seller-accountant/billings/invoices",
    exact: true,
    // component: DesignTasksPage,
    component: InvoicesPagePolaris,
    title: "All Invoices",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Accountant",
  },
  {
    path: "/seller-accountant/billings/invoice/:id",
    exact: true,
    component: InvoiceDetailsPagePolaris,
    title: "Invoice Detail",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Accountant",
  },
];

export default routes;
