import { Icon as LegacyIcon } from "@ant-design/compatible";
import { DeleteOutlined } from "@ant-design/icons";
import { Collapsible } from "@shopify/polaris";
import { Button, Input, InputNumber, Select, Table } from "antd";
import { get, sum, isEqual } from "lodash";
import PropTypes from "prop-types";
import React, { Component } from "react";
import styled from "styled-components";
import { SCALABLEPRESS_HORIZONTAL_POSITION } from "../../variable";
import MediaSelectorButton from "../supplier/MediaSelectorButton";
import { ArtWorkGuideline } from "./ArtWorkGuideline";
import { DesignPositionSelect } from "./DesignPositionSelect";
import DesignPositionVariantsSelect from "./DesignPositionVariantsSelect";
import { ResizeSelect } from "./ResizeSelect";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";

const type = "DRAGABLE_DESIGN_POSITION";

const DragableBodyRow = ({
  index,
  moveRow,
  className,
  style,
  ...restProps
}) => {
  const ref = React.useRef();
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: (monitor) => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) return {};

      return {
        isOver: monitor.isOver(),
        dropClassName:
          dragIndex > index ? " drop-over-downward" : " drop-over-upwrad",
      };
    },
    drop: (item) => {
      if (moveRow) moveRow(item.index, index);
    },
  });

  const [, drag] = useDrag({
    item: { type, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drop(drag(ref));

  return (
    <tr
      ref={ref}
      className={`${className}${isOver ? dropClassName : ""}`}
      style={{ cursor: "move", ...style }}
      {...restProps}
    />
  );
};

class ProductBaseDesignPositions extends Component {
  state = {
    visible: false,
    artworkGuidelines: [],
    indexBtnClick: null,
    stateValue: this.props.value,
  };

  components = {
    body: {
      row: DragableBodyRow,
    },
  };

  showModal = () => {
    this.setState((prevState) => {
      return {
        visible: !prevState.visible,
      };
    });
  };

  btnClick = (index) => {
    this.setState({ indexBtnClick: index }, () => this.showModal());
  };

  moveRow = (dragIndex, hoverIndex) => {
    const { stateValue: value } = this.state;
    [value[dragIndex], value[hoverIndex]] = [
      value[hoverIndex],
      value[dragIndex],
    ];

    this.setState({ stateValue: value }, this.onChangeImpl);
  };

  timer;
  onChangeImpl = () => {
    this.timer && clearTimeout(this.timer);
    const { stateValue } = this.state;
    const { onChange } = this.props;
    this.timer = setTimeout(() => {
      onChange && onChange(stateValue);
    }, 500);
  };

  componentWillUnmount() {
    this.timer && clearTimeout(this.timer);
  }

  componentDidUpdate(nextProps) {
    if(!isEqual(nextProps.value, this.state.stateValue)) {
      this.setState({ stateValue: [...nextProps.value] });
    }
  }

  render() {
    let {
      variants,
      // value,
      isDreamship,
      isFFScalabelPress,
      scalablePressDT,
      openDPCollapse,
      hasFulfillment,
      isEdit,
    } = this.props;
    const { stateValue: value } = this.state;

    // Apply scalablepress design type;
    // let showColumnColors = false;
    let showColumnDimensions = false;
    let showColumnPosition = false;
    let isPosterType = false;
    // if (isFFScalabelPress && ["screenprint"].includes(scalablePressDT)) {
    //   showColumnColors = true;
    // }

    if (isFFScalabelPress) {
      if (["screenprint", "dtg", "poster"].includes(scalablePressDT)) {
        showColumnDimensions = true;
      }
      if (["screenprint", "dtg"].includes(scalablePressDT)) {
        showColumnPosition = true;
      }
      // if (["screenprint"].includes(scalablePressDT)) {
      //   showColumnColors = true;
      // }
      if (["poster"].includes(scalablePressDT)) {
        isPosterType = true;
      }
    }

    // Horizontal
    let horizontalPosition = SCALABLEPRESS_HORIZONTAL_POSITION;
    let horizontalPositionArr = [];
    for (let [key, value] of Object.entries(horizontalPosition)) {
      horizontalPositionArr.push({ key, value });
    }

    if (value.length) {
      value.map((v, index) => {
        if (
          value[index].artworkGuidelines &&
          value[index].artworkGuidelines.length
        ) {
          value[index].artworkGuidelines = value[
            index
          ].artworkGuidelines.filter((i) => i.file || i.fileId);

          value[index].artworkGuidelines.map((a, idx) => {
            if (
              value[index].artworkGuidelines[idx] &&
              value[index].artworkGuidelines[idx].file
            ) {
              if (a.file && a.file.name) {
                value[index].artworkGuidelines[idx].name = a.file.name;
              }
              if (a.id && a.file) {
                value[index].artworkGuidelines[idx].fileId = a.file.id;
              }
            }
            return a;
          });
        }
        return v;
      });
    }
    const tdStyle = {
      width: "200px",
      overflow: "hidden",
      display: "inline-block",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    };
    const columns = [
      {
        title: "Placeholder",
        key: "placeholder",
        dataIndex: "placeholder",
        width: 150,
        render: (_, __, index) => {
          const image = get(value, `[${index}].image`, null);
          return (
            <MediaSelectorButton
              width="100"
              name="Upload"
              value={image && image.url ? [image] : []}
              onChange={(files) => {
                if (files.length) {
                  value[index].image = files[0];
                } else {
                  value[index].image = null;
                  value[index].imageUrl = null;
                }
                // if (this.props.onChange) {
                //   this.props.onChange(value);
                // }
                this.setState({ stateValue: value }, this.onChangeImpl);
              }}
              accept={"image/*"}
              singleUpload={true}
              listType={"picture-card"}
              multiple={false}
            />
          );
        },
      },
      {
        title: "Display Name",
        key: "displayName",
        dataIndex: "displayName",
        width: 150,
        render: (_, __, index) => (
          <div style={{ width: 150 }}>
            <div style={{ minHeight: 40 }} />
            <Input
              value={value[index].displayName}
              placeholder="Enter display name"
              onChange={(e) => {
                const { disableSubmitButton } = this.props;
                disableSubmitButton && disableSubmitButton(false);
                value[index].displayName = e.target.value;
                this.setState({ stateValue: value }, this.onChangeImpl);
                // onChange && onChange(value);
              }}
            />
          </div>
        ),
      },
      {
        title: "Position Name",
        key: "position",
        dataIndex: "position",
        width: 150,
        render: (_, __, index) => (
          <div style={{ width: 150 }}>
            <div style={{ minHeight: 40 }} />
            <Input
              // defaultValue={value[index].name}
              value={value[index].name}
              onBlur={(e) => {
                value[index].name = e.target.value;
                if (this.props.onChange) {
                  // this.props.onChange(value);
                }
                this.setState({ stateValue: value }, this.onChangeImpl);
              }}
              onChange={(e) => {
                const { disableSubmitButton } = this.props;
                disableSubmitButton && disableSubmitButton(false);
                value[index].name = e.target.value;
                // onChange && onChange(value);
                this.setState({ stateValue: value }, this.onChangeImpl);
              }}
              /*onChange={(e) => {
                return;
                value[index].name = e.target.value;
                if (this.props.onChange) {
                  this.props.onChange(value);
                }
              }}*/
              placeholder={"Master"}
            />
          </div>
        ),
      },
      {
        title: "Target",
        key: "target",
        dataIndex: "target",
        width: 600,
        render: (_, __, index) => (
          <div style={{ width: "100%" }}>
            <div style={{ minHeight: 40 }} />
            <DesignPositionVariantsSelect
              onChange={(changeValues) => {
                value[index].productBaseVariants = changeValues;
                // this.props.onChange(value);
                this.setState({ stateValue: value }, this.onChangeImpl);
              }}
              value={value ? value[index].productBaseVariants || [] : []}
              variants={variants}
            />
          </div>
        ),
      },
      ...(isDreamship
        ? [
            {
              title: "Position",
              key: "position",
              dataIndex: "position",
              width: 150,
              render: (_value, _record, index) => (
                <div>
                  <div style={{ minHeight: 40 }} />
                  <DesignPositionSelect
                    value={value ? value[index].position : null}
                    onChange={(newValue) => {
                      value[index].position = newValue;
                      value[index].resize = "fit";
                      // this.props.onChange(value);
                      this.setState({ stateValue: value }, this.onChangeImpl);
                    }}
                  />
                </div>
              ),
            },
            {
              title: "Resize",
              key: "resize",
              dataIndex: "resize",
              width: 120,
              render: (_value, _record, index) => {
                return (
                  <div>
                    <div style={{ minHeight: 40 }}></div>
                    <ResizeSelect
                      value={value ? value[index].resize : null}
                      onChange={(newValue) => {
                        value[index].resize = newValue;
                        // this.props.onChange(value);
                        this.setState({ stateValue: value }, this.onChangeImpl);
                      }}
                    />
                  </div>
                );
              },
            },
          ]
        : []),
      ...(isFFScalabelPress
        ? [
            ...(showColumnDimensions
              ? [
                  {
                    title: "Dimensions",
                    key: "dimensions",
                    dataIndex: "dimensions",
                    width: 150,
                    render: (_value, _record, index) => (
                      <div>
                        <div style={{ minHeight: 20 }}></div>
                        <div className="dimensions_wrap">
                          <div className="width_wrap">
                            <label>width</label>
                            {isPosterType ? (
                              <Select
                                options={[
                                  // { label: "0", value: 0 },
                                  { label: "17", value: 17 },
                                  { label: "24", value: 24 },
                                ]}
                                onChange={(newValue) => {
                                  value[index]["width"] = newValue;
                                  value[index]["height"] = 0;
                                  // this.props.onChange(value);
                                  this.setState(
                                    { stateValue: value },
                                    this.onChangeImpl,
                                  );
                                }}
                                value={value[index]["width"]}
                              />
                            ) : (
                              <InputNumber
                                min={0}
                                value={value[index]["width"]}
                                onChange={(newValue) => {
                                  value[index]["width"] = newValue;
                                  value[index]["height"] = 0;
                                  // this.props.onChange(value);
                                  this.setState(
                                    { stateValue: value },
                                    this.onChangeImpl,
                                  );
                                }}
                              />
                            )}
                          </div>
                          <div className="height_wrap">
                            <label>height</label>
                            {isPosterType ? (
                              <Select
                                options={[
                                  // { label: "0", value: 0 },
                                  { label: "17", value: 17 },
                                  { label: "24", value: 24 },
                                ]}
                                onChange={(newValue) => {
                                  value[index]["height"] = newValue;
                                  value[index]["width"] = 0;
                                  // this.props.onChange(value);
                                  this.setState(
                                    { stateValue: value },
                                    this.onChangeImpl,
                                  );
                                }}
                                value={value[index]["height"]}
                              />
                            ) : (
                              <InputNumber
                                min={0}
                                value={value[index]["height"]}
                                onChange={(newValue) => {
                                  value[index]["height"] = newValue;
                                  value[index]["width"] = 0;
                                  // this.props.onChange(value);
                                  this.setState(
                                    { stateValue: value },
                                    this.onChangeImpl,
                                  );
                                }}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    ),
                  },
                ]
              : []),
            ...(showColumnPosition
              ? [
                  {
                    title: "Position",
                    key: "position",
                    dataIndex: "position",
                    width: 150,
                    render: (_value, _record, index) => (
                      <div>
                        <div style={{ minHeight: 20 }}></div>
                        <div className="positions_wrap">
                          <div className="horizontal_wrap">
                            <label>horizontal</label>
                            <Select
                              defaultValue={value[index]["horizontal"]}
                              onChange={(newValue) => {
                                value[index]["horizontal"] = newValue;
                                // this.props.onChange(value);
                                this.setState(
                                  { stateValue: value },
                                  this.onChangeImpl,
                                );
                              }}
                            >
                              {horizontalPositionArr &&
                              horizontalPositionArr.length > 0
                                ? horizontalPositionArr.map((hp, idx) => (
                                    <Select.Option value={hp.key} key={idx}>
                                      {hp.value}
                                    </Select.Option>
                                  ))
                                : null}
                            </Select>
                          </div>
                          <div className="offset_wrap">
                            <div className="top_wrap">
                              <label>offset top</label>
                              <InputNumber
                                value={value[index]["top"]}
                                onChange={(newValue) => {
                                  value[index]["top"] = newValue;
                                  value[index]["bottom"] = 0;
                                  // this.props.onChange(value);
                                  this.setState(
                                    { stateValue: value },
                                    this.onChangeImpl,
                                  );
                                }}
                                min={0}
                              />
                            </div>
                            <div className="bottom_wrap">
                              <label>offset bottom</label>
                              <InputNumber
                                value={value[index]["bottom"]}
                                onChange={(newValue) => {
                                  value[index]["bottom"] = newValue;
                                  value[index]["top"] = 0;
                                  // this.props.onChange(value);
                                  this.setState(
                                    { stateValue: value },
                                    this.onChangeImpl,
                                  );
                                }}
                                min={0}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    ),
                  },
                ]
              : []),

            // ...(showColumnColors
            //   ? [
            //       {
            //         title: "Colors",
            //         key: "colors",
            //         dataIndex: "colors",
            //         width: 150,
            //         render: (_value, _record, index) => <div>colors</div>,
            //       },
            //     ]
            //   : []),
          ]
        : []),
      {
        title: "Description",
        key: "description",
        dataIndex: "description",
        width: 350,
        render: (_, __, index) => (
          <div style={{ width: 350 }}>
            <div
              style={{
                minHeight: 40,
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                tabIndex={-1}
                onClick={() => {
                  value.splice(index, 1);
                  const newValue = value.filter((_, i) => i !== index.id);
                  // this.props.onChange(newValue);
                  this.setState({ stateValue: newValue }, this.onChangeImpl);
                }}
                type={"link"}
                disabled={isEdit}
              >
                Remove
              </Button>
            </div>
            <Input
              // defaultValue={value[index].description}
              value={value[index].description}
              onBlur={(e) => {
                value[index].description = e.target.value;
                // if (this.props.onChange) {
                  // this.props.onChange(value);
                // }
                this.setState({ stateValue: value }, this.onChangeImpl);
              }}
              onChange={(e) => {
                const { disableSubmitButton, onChange } = this.props;
                disableSubmitButton && disableSubmitButton(false);
                value[index].description = e.target.value;
                // onChange && onChange(value);
                this.setState({ stateValue: value }, this.onChangeImpl);
              }}
              placeholder={"5000x5500px|JPG|1500PI"}
            />
          </div>
        ),
      },
      hasFulfillment && {
        title: "Extra Fee",
        key: "extraFee",
        dataIndex: "extraFee",
        width: 150,
        render: (_, _r, index) => {
          return (
            <div>
              <div style={{ minHeight: 30 }} />
              <Input
                type="number"
                value={value ? value[index].extraFee : ""}
                min={0}
                step={0.01}
                onChange={(event) => {
                  const { value: val } = event.target;
                  value[index].extraFee = val;
                  // this.props.onChange(value);
                  this.setState({ stateValue: value }, this.onChangeImpl);
                }}
              />
            </div>
          );
        },
      },
    ].filter(Boolean);

    const tableWidth = sum(columns.map((c) => c.width));

    return (
      <Container>
        <Collapsible
          id="collapsible-design-postions"
          open={openDPCollapse}
          transition={{ duration: "150ms", timingFunction: "ease" }}
        >
          <DndProvider backend={HTML5Backend}>
            <Table
              pagination={false}
              dataSource={[...value]}
              components={this.components}
              onRow={(_, index) => ({
                index,
                moveRow: this.moveRow,
              })}
              columns={columns}
              rowKey={(_, index) => index}
              scroll={{ x: tableWidth }}
              expandable={{
                expandedRowRender: (_, index) => {
                  let AG = value[index].artworkGuidelines;
                  AG &&
                    AG.map((i) => {
                      if (i.file && i.file.name) {
                        i.name = i.file.name;
                      }
                      if (i.file && i.file.id) {
                        i.fileId = i.file.id;
                      }
                      return i;
                    });
                  return (
                    <>
                      <table>
                        <thead>
                          <tr>
                            <th>File</th>
                            <th>Description</th>
                            <th style={{ textAlign: "right" }}>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {AG && AG.length ? (
                            AG.map((ag, idx) => (
                              <tr key={idx}>
                                <td style={tdStyle}>
                                  <span className="mb-2">
                                    {ag.name ? ag.name : ""}
                                  </span>
                                </td>
                                <td>
                                  <span className="mb-2">
                                    {ag.description ? ag.description : ""}
                                  </span>
                                </td>
                                <td style={{ textAlign: "right" }}>
                                  {ag && ag.name && (
                                    <DeleteOutlined
                                      onClick={() => {
                                        AG = AG.filter(
                                          (i) => i.fileId !== ag.fileId,
                                        );
                                        if (AG.length) {
                                          value[index].artworkGuidelines = AG;
                                        } else {
                                          delete value[index].artworkGuidelines;
                                        }

                                        // if (this.props.onChange) {
                                        //   this.props.onChange(value);
                                        // }
                                        this.setState(
                                          { stateValue: [...value] },
                                          this.onChangeImpl,
                                        );
                                      }}
                                    />
                                  )}
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr></tr>
                          )}
                          <tr>
                            <td>
                              <Button
                                className="mt-8"
                                onClick={() => this.btnClick(index)}
                              >
                                Add artwork guideline
                              </Button>
                            </td>
                            <td></td>
                            <td></td>
                          </tr>
                        </tbody>
                      </table>
                      <ArtWorkGuideline
                        index={index}
                        visible={
                          this.state.visible &&
                          index === this.state.indexBtnClick
                        }
                        showModal={this.showModal}
                        onChange={(v) => {
                          let { indexBtnClick } = this.state;
                          let newValue = value.map((item, index) => {
                            if (index === indexBtnClick) {
                              let newData = [v];
                              if (
                                item.artworkGuidelines &&
                                item.artworkGuidelines.length
                              ) {
                                newData = [v, ...item.artworkGuidelines];
                              }
                              return {
                                ...item,
                                artworkGuidelines: newData,
                              };
                            }
                            return item;
                          });

                          // if (this.props.onChange) {
                          //   this.props.onChange(newValue);
                          // }
                          this.setState(
                            { stateValue: [...newValue] },
                            this.onChangeImpl,
                          );
                        }}
                      />
                    </>
                  );
                },
              }}
            />
          </DndProvider>
          <Button
            icon={<LegacyIcon type={"plus"} />}
            onClick={() => {
              // this.props.onChange([
              //   ...value,
              //   {
              //     image: null,
              //     imageUrl: null,
              //     name: "",
              //     description: "",
              //   },
              // ]);
              const { stateValue } = this.state;

              const newItem = {
                image: null,
                imageUrl: null,
                name: "",
                displayName: "",
                description: "",
              };
              this.setState(
                { stateValue: [...stateValue, newItem] },
                this.onChangeImpl,
              );
            }}
            style={{ marginTop: "10px" }}
          >
            {value.length
              ? "Add another design position"
              : "Add design position"}
          </Button>
        </Collapsible>
      </Container>
    );
  }
}

ProductBaseDesignPositions.propTypes = {
  variants: PropTypes.array,
  value: PropTypes.array,
  onChange: PropTypes.func,
  disableSubmitButton: PropTypes.func,
};

const Container = styled.div`
  .dimensions_wrap {
    width: 200px;
    display: flex;
    flex-direction: row;
    justify-content: start;
    column-gap: 2rem;
    .width_wrap,
    .height_wrap {
      display: flex;
      flex-direction: column;
    }
  }
  .positions_wrap {
    display: flex;
    flex-direction: row;
    column-gap: 2rem;
    align-items: flex-end;
    .offset_wrap {
      width: 200px;
      display: flex;
      flex-direction: row;
      column-gap: 1.5rem;
      .offset_inner {
        display: flex;
        flex-direction: row;
        justify-content: start;
        column-gap: 2rem;
      }
    }
  }
`;

export default ProductBaseDesignPositions;
