import React, { useState, useCallback, useEffect } from "react";
import { Autocomplete } from "@shopify/polaris";
import { gql } from "apollo-boost";
import { useLazyQuery } from "@apollo/react-hooks";
import _ from "lodash";

import { handleError } from "../../helper";
import { ComponentLabelPolaris } from "../shared/ComponentLabelPolaris";

const productBasesQuery = gql`
  query productBaseByFulfillment($filter: ProductBaseByFulfillmentFilter!) {
    productBaseByFulfillment(filter: $filter) {
      id
      name
      # variants {
      #   id
      #   name
      # }
    }
  }
`;

export const ProductBaseSelectPolaris = (props) => {
  const [getData, { data, loading, error }] = useLazyQuery(productBasesQuery);
  const [deselectedOptions, setDeselectedOptions] = useState([]);
  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [inputValue, setInputValue] = useState(null);

  useEffect(() => {
    getData({
      variables: {
        filter: {
          limit: -1,
          offset: -1,
          fulfillmentId: props.fulfillmentId ? props.fulfillmentId : null,
        }
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.fulfillmentId]);

  useEffect(() => {
    if (
      data &&
      data.productBaseByFulfillment &&
      data.productBaseByFulfillment.length
    ) {
      let formatData = data.productBaseByFulfillment.map((n) => ({
        label: n.name,
        value: n.id,
      }));

      setOptions(formatData);
      setDeselectedOptions(formatData);

      let productBase = data.productBaseByFulfillment;
      if (props.isAddForm) {
        setSelectedOptions([productBase[0].id]);
        setInputValue(productBase[0].name);
        if (props.onChange) {
          props.onChange(productBase[0].id, productBase[0]);
        }
        return;
      }
      if (!props.value) {
        setSelectedOptions([productBase[0].id]);
        setInputValue(productBase[0].name);
        if (props.onChange) {
          props.onChange(productBase[0].id, productBase[0]);
        }
      } else {
        setSelectedOptions(props.value ? props.value : []);
        let currentPB = productBase.find((d) => d.id === props.value);
        setInputValue(currentPB && currentPB.name ? currentPB.name : null);
        if (props.onChange) {
          props.onChange(props.value, currentPB);
        }
      }
    } else {
      setOptions([]);
      setDeselectedOptions([]);
      setInputValue(null);
      if (props.isAddForm) {
        if (props.onChange) {
          props.onChange(null, null);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  const nodes =
    data && data.productBaseByFulfillment ? data.productBaseByFulfillment : [];

  const handleInputChange = useCallback(
    (value) => {
      setInputValue(value);
      if ("" === value) {
        setOptions(deselectedOptions);
        return;
      }

      const filterRegex = new RegExp(value, "i");
      const resultOptions = deselectedOptions.filter((option) =>
        option.label.match(filterRegex)
      );

      setOptions(resultOptions);
    },
    [deselectedOptions]
  );

  const handleSection = useCallback(
    (selected) => {
      const selectedValue = selected.map((selectedItem) => {
        const matchedOptions = options.find((option) => {
          return option.value === selectedItem;
        });
        return matchedOptions && matchedOptions.label;
      });
      setSelectedOptions(selected);
      setInputValue(_.head(selectedValue));
      if (props.onChange) {
        props.onChange(
          selected[0],
          nodes.find((n) => n.id === selected[0])
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [options, nodes]
  );

  const textField = (
    <Autocomplete.TextField
      label="Choose a product base"
      labelHidden
      placeholder="Search product base"
      value={inputValue}
      onChange={handleInputChange}
      error={props.error}
    />
  );

  if (error) return <div>Eror: {handleError(error.toString())}</div>;
  return (
    <div>
      {!props.labelHidden ? (
        <ComponentLabelPolaris label="Choose a product base" required />
      ) : null}
      <Autocomplete
        options={options}
        onSelect={handleSection}
        selected={selectedOptions}
        textField={textField}
        emptyState={<span>No items found!</span>}
        loading={loading}
      />
    </div>
  );
};
