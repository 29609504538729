import React, { useState, useCallback } from "react";
import { Page, Loading, Card, Modal, TextContainer, Toast } from "@shopify/polaris";
import styled from "styled-components";
import { InvoiceDetailsPolaris } from "./InvoiceDetailsPolaris";
import { gql } from "apollo-boost";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { EmptyStatePolaris } from "../../shared/EmptyStatePolaris";
import { SkeletonPagePolaris } from "../../shared/SkeletonPagePolaris";
import { checkRole, getParamByRole, getParamByUserRole, handleError } from "../../../helper";
import { useAppContext } from "../../../context";

export const INVOICE_BY_ID = gql`
	query invoiceByID($id: ID!) {
		invoiceByID(
			id: $id
		) {
			id
			name
			createdAt
   			paymentDueDate
			totalPrice
			status
			orderIDs
			summary
			team {
				id
				name
				address
			}
			summary
			rootUser {
				firstName
				lastName
				email
				address
			}
			paymentMethod
		}
	}
`;

const MARK_INVOICE_AS_PAID = gql`
	mutation adminMarkInvoiceAsPaid($id: ID!) {
		adminMarkInvoiceAsPaid(id: $id) {
			id
			name
			createdAt
   			paymentDueDate
			totalPrice
			status
			orderIDs
			summary
			team {
				id
				name
			}
			summary
			rootUser {
				firstName
				lastName
				email
			}
			paymentMethod
		}
	}
`;

const Container = styled.div`
  margin: -1rem;
  @media (min-width: 640px) {
    margin: -1.5rem;
  }
`;

export const InvoiceDetailsPagePolaris = (props) => {
	const [open, setOpen] = useState(false);
	const [activeToast, setActiveToast] = useState(false);

	const { currentUser } = useAppContext()
	const { isAdministrator } = checkRole(currentUser);
	const paramUser = getParamByUserRole(currentUser);
	const paramTeamUser = getParamByRole(currentUser);

	const { id } = props.match.params;
	const { data, loading, refetch} = useQuery(INVOICE_BY_ID, {
		variables: { id },
		fetchPolicy: "network-only",
	});
	let invoice = (data && data.invoiceByID && data.invoiceByID.id.length) ? data.invoiceByID : null;

	const [markAsPaid, markInvoicePaidResult] = useMutation(
		MARK_INVOICE_AS_PAID,
		{
			onCompleted: () => {
				if (markInvoicePaidResult && markInvoicePaidResult.data && markInvoicePaidResult.data.adminMarkInvoiceAsPaid) {
					invoice = markInvoicePaidResult.data.adminMarkInvoiceAsPaid;
				}
				handleModalChange();
				setActiveToast(true);
			},
			onError: () => {
				handleModalChange();
				setActiveToast(true);
			}
		}
	);

	const toggleActive = useCallback(() => { setActiveToast((activeToast) => !activeToast); }, []);

	const toastMarkup = activeToast ? (markInvoicePaidResult.error || (markInvoicePaidResult.data && markInvoicePaidResult.data.adminMarkInvoiceAsPaid)) && (
		<Toast
			content={ markInvoicePaidResult.error ? handleError(markInvoicePaidResult.error.toString()) : markInvoicePaidResult.data && markInvoicePaidResult.data.adminMarkInvoiceAsPaid && "The invoice has been successfully updated!" }
			error={markInvoicePaidResult.error}
			duration={10000}
			onDismiss={toggleActive}
		/>
	) : null;

	const loadingMarkup = loading && <Loading />;

	

	let otherProps = {};
	if (isAdministrator && invoice && invoice.status == 'Pending') {
		otherProps = {
			primaryAction: {
				content: "Mark as Paid",
				onAction: () => handleAction('markAsPaid', invoice),
			},
		}
	}

	const handleAction = (action, invoice) => {
		console.log(`Action: ${action}`, invoice);
		switch (action) {
			case 'markAsPaid':
				handleModalChange();
				break;
		}
	};
	const handleModalChange = useCallback(() => setOpen((open) => !open), []);

	return (
		<Container>
			<Page
				title={`Invoice Details`}
				breadcrumbs={[
					{ content: "All Invoices", url: `/${isAdministrator ? paramUser : paramTeamUser}/billings/invoices` },
				]}
				{...otherProps}
			>
				<Card sectioned>
					{loadingMarkup}
					{loading ? (
						<SkeletonPagePolaris />
					) : invoice ? (
						<InvoiceDetailsPolaris invoice={invoice} refetch={refetch} />
					) : (
						<EmptyStatePolaris />
					)}
				</Card>

				<Modal
					title={"Mark as Paid"}
					open={open}
					onClose={handleModalChange}
					primaryAction={{
						content: "Mark as Paid",
						loading: loading,
						onAction: () => {
							markAsPaid({
								variables: { id: invoice.id },
							})
								.then((res) => {

								}).catch((err) => {
									console.log('Error: ', err)
								});
						}
					}}
					secondaryActions={[
						{
							content: "Cancel",
							onAction: handleModalChange,
						},
					]}
				>
					<Modal.Section>
						<TextContainer>
							<p>Are you sure to mark this invoice <strong>#{invoice?.id}</strong> to <strong>Paid</strong>?</p>
						</TextContainer>
					</Modal.Section>
				</Modal>
				{toastMarkup}
			</Page>
		</Container>
	);
};
