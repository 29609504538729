import { useMutation, useQuery } from "@apollo/react-hooks";
import { ChoiceList, Stack, TextField } from "@shopify/polaris";
import { notification } from "antd";
import React from "react";

import { SETTING_VARIABLES } from "../../../constants";
import { useAppContext } from "../../../context";
import { SET_VARIABLE } from "../../../graphql/mutations";
import { GET_VARIABLES } from "../../../graphql/queries";
import { getTeamID, handleError, reducerFn } from "../../../helper";
import { get } from "lodash";
import TeamMemberSelect from "../../team-member/components/team-member-select";
import { TEAM_ROLE } from "../../../variable";

function TiktokPushMass({ setLoading }, ref) {
  const [selected, setSelected] = React.useState(["false"]);
  const [inputVal, setInputVal] = React.useState("30");

  const [state, setState] = React.useReducer(reducerFn, {
    users: [],
    selected: [],
  });

  const { currentUser } = useAppContext();
  const teamId = getTeamID(currentUser);

  const variableKey = React.useMemo(
    () => (teamId ? `${teamId}${SETTING_VARIABLES.TPM}` : null),
    [teamId],
  );

  const { data } = useQuery(GET_VARIABLES, {
    variables: {
      filter: {
        search: variableKey,
      },
    },
    skip: !variableKey,
  });

  const [setVariables] = useMutation(SET_VARIABLE, {
    onCompleted: () => {
      notification.success({
        message: "Enable Tiktok Push Mass for seller success",
      });
    },
    onError: (err) => {
      notification.error({ message: handleError(err?.toString()) });
    },
  });

  const changed = React.useRef(false);

  React.useEffect(() => {
    const users = getCurrentUsers(data);
    setState({ selected: users });
  }, [data]);

  const handleSubmit = React.useCallback(async () => {
    if (!changed.current) return;

    let val = getUsers(state);
    setLoading(true);
    await setVariables({
      variables: {
        input: {
          key: variableKey,
          value: val,
        },
      },
    }).finally(() => {
      setLoading(false);
      changed.current = false;
    });
  }, [variableKey, setVariables, setLoading, state.selected]);

  // const onInputChange = React.useCallback((value) => {
  //   setInputVal(value);
  //   changed.current = true;
  // }, []);

  const onSelectChange = React.useCallback((value) => {
    setState({ selected: value })
    changed.current = true;
  }, []);

  React.useImperativeHandle(ref, () => ({
    onSubmit: handleSubmit,
  }));

  return (
    <Stack vertical>
      <TeamMemberSelect
        label="Users"
        value={state.selected}
        onChange={onSelectChange}
        getValueImmediate
        allowMultiple
        filter={{
          roles: [
            TEAM_ROLE.Admin,
            TEAM_ROLE.MarketplaceManager,
            TEAM_ROLE.StoreManager,
          ],
        }}
      />
    </Stack>
  );
}

function getCurrentUsers(data) {
  const val = get(data, "variables[0].value") || "";
  return val
    .trim()
    .split(",")
    .map((i) => (i || "").trim())
    .filter(Boolean);
}

function getUsers(state) {
  const val = get(state, "selected") || [];
  return val.filter(Boolean).join(",");
}

export default React.forwardRef(TiktokPushMass);
