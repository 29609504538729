import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import { Stack, TextStyle, Heading } from "@shopify/polaris";
import React from "react";
import { REPORT_TIKTOK_STATISTIC } from "../../../graphql/queries";
import { useReportsContext } from "../context";
import { CardBox } from "./CardBox";

const collapseKey = "TiktokStatistic";

const styleColumn = {
	width: "12rem",
	display: "inline-block",
};

const getFlexStyle = (width, justify=null) => {
	let conf = {
		width,
		display: "inline-flex",
		"word-wrap": "break-word",
	};
	if (justify) {
		conf["justify-content"] = justify;
	}
	return conf;
}


function TiktokStatistic({ saleChanel }) {
	// Context
	const {
		range,
		isCompare,
		rangeToCompare,
		loading: loadingRoot,
		teamID,
		filter: filterCtx,
	} = useReportsContext();

	// State
	const [filter, setFilter] = React.useState({
		filterByTime: range,
		saleChanel,
	});
	const [state, setState] = React.useReducer((p, s) => ({ ...p, ...s }), {
		items: [],
		total: 0,
		compareTotal: 0,
		loading: true,
	});

	// Queries
	const { data, loading, error } = useQuery(REPORT_TIKTOK_STATISTIC, {
		variables: {
			filter,
		},
	});

	const [lazyReport, { data: d2, loading: l2, error: e2 }] = useLazyQuery(
		REPORT_TIKTOK_STATISTIC,
	);

	React.useEffect(() => {
		const mergedL = loading || l2 || loadingRoot;
		setState({ loading: mergedL });
	}, [loading, l2, loadingRoot]);

	React.useEffect(() => {
		if (filterCtx != null && "fulfillmentID" in filterCtx) {
			let { fulfillmentID } = filterCtx;
			setFilter((prev) => ({ ...prev, fulfillmentID }));
		}
	}, [filterCtx]);

	React.useEffect(() => {
		setFilter((p) => ({
			...p,
			filterByTime: range,
			teamId: teamID ?? undefined,
		}));
	}, [range, teamID]);

	React.useEffect(() => {
		if (data?.getTiktokReport == null) return;

		const items = data.getTiktokReport;
		setState({ items });
	}, [data]);

	React.useEffect(() => {
		if (!isCompare) return;
		let filterByTime = null;
		if (rangeToCompare?.from != null) {
			filterByTime = {
				...filter.filterByTime,
				...rangeToCompare,
			};
		}
		lazyReport({
			variables: {
				filter: {
					...filter,
					filterByTime,
				},
			},
		});
	}, [rangeToCompare, filter, isCompare]);

	// Markup
	const contentMarkup = React.useMemo(() => {
		console.log('state.items: ', state.items.crawlItems);
		
		const sectionDataSources = {
			crawlItems: state.items?.crawlItems ?? null,
			mbProducts: state.items?.mbProducts ?? null,
		}
		
		const sectionStoreGroups = {
			stores: state.items?.stores ?? [],
			groups: state.items?.groups ?? [],
		}

		const labelMap = {
			crawlItems: "Crawl Items",
			mbProducts: "MB Products",
			stores: "Stores",
			groups: "Groups",
			teams: "Teams",
		};

		const managers = state.items?.managers ?? [];
		const teams = state.items?.teams ?? [];

		return (sectionDataSources.crawlItems || sectionDataSources.mbProducts || sectionStoreGroups.stores?.length > 0 || sectionStoreGroups.groups?.length > 0 || (managers && managers.length > 0) || (teams && teams.length > 0) )
			? (
				<>
					{(sectionDataSources.crawlItems || sectionDataSources.mbProducts) ? (
						<div id="section-data-source" style={{marginTop: '20px', 'margin-top': '20px'}}>
							<Heading element="h4">Data sources</Heading>
							
							<div style={{marginTop: '10px'}}>
								<Stack wrap={false}>
									<span style={getFlexStyle('9rem')}>
										<TextStyle variation="strong">Type</TextStyle>
									</span>
									<span style={getFlexStyle('8rem')}>
										<TextStyle variation="strong">Total Items</TextStyle>
									</span>
									<span style={getFlexStyle('9rem')}>
										<TextStyle variation="strong">Total Pushed</TextStyle>
									</span>
									<span style={getFlexStyle('14rem')}>
										<TextStyle variation="strong">Total API Pushed</TextStyle>
									</span>
									<span style={getFlexStyle('14rem')}>
										<TextStyle variation="strong">Total Excel Exported</TextStyle>
									</span>
								</Stack>
							</div>
							
							<div style={{marginTop: '5px'}}>
								{Object.entries(sectionDataSources).map(([key, item]) =>
									item ? (
										<Stack
											key={`data-source-${key}`}
											distribution="equalSpacing"
											wrap={false}
										>
											<span style={getFlexStyle('9rem')}>
												<strong>{labelMap[key] || "Unknow"}</strong>
											</span>
											<span style={getFlexStyle('8rem')}>
												{item.totalItems}
											</span>
											<span style={getFlexStyle('9rem')}>
												{(item.totalItemsPushed || 0)}
											</span>
											<span style={getFlexStyle('14rem')}>
												{(item.totalItemsPushedViaAPI || 0)}
											</span>
											<span style={getFlexStyle('14rem')}>
												{(item.totalItemsExportedViaExcel || 0)}
											</span>
										</Stack>
									) : null
								)}
							</div>
						</div>
					) : null }
					
					
					{(sectionStoreGroups.stores?.length > 0 || sectionStoreGroups.groups?.length > 0) ? (
						<div id="section-store-groups" style={{marginTop: '20px', 'margin-top': '20px', 'border-top': '1px solid #eee', 'padding-top': '20px'}}>
							{Object.entries(sectionStoreGroups).map(([key, sections]) =>
								sections && Array.isArray(sections) && sections.length > 0 ? (
									<div key={key}>
										<Heading element="h4">{labelMap[key] || ""}</Heading>

										<div style={{marginTop: '10px'}}>
											<Stack wrap={false}>
												<span style={getFlexStyle('8rem')}>
													<TextStyle variation="strong"># ID</TextStyle>
												</span>
												<span style={getFlexStyle('29rem')}>
													<strong><TextStyle variation="strong">Title</TextStyle></strong>
												</span>
												<span style={getFlexStyle('9rem')}>
													<TextStyle variation="strong">Total Pushed</TextStyle>
												</span>
												<span style={getFlexStyle('9rem')}>
													<TextStyle variation="strong">Total Orders</TextStyle>
												</span>
											</Stack>
										</div>
										<div style={{marginTop: '8px'}}>
											{
												sections.map((item, index) => (
													<Stack
														key={`data-source-${key}`}
														distribution="equalSpacing"
														wrap={false}
													>
														<span style={getFlexStyle('8rem')}>
															{item.id}
														</span>
														<span style={getFlexStyle('29rem')}>
															<strong>{(item.title || '')}</strong>
														</span>
														<span style={getFlexStyle('9rem')}>
															{(item.totalPushedItems || 0)}
														</span>
														<span style={getFlexStyle('9rem')}>
															{(item.totalOrders || 0)}
														</span>
													</Stack>
												))
											}
										</div>
									</div>
								) : null
							)}
						</div>
					) : null}
					
					{managers && managers.length > 0 ? (
						<div id="section-managers" style={{marginTop: '20px', 'margin-top': '20px', 'border-top': '1px solid #eee', 'padding-top': '20px'}}>
							<Heading element="h4">Managers</Heading>

							<div style={{marginTop: '10px'}}>
								<Stack wrap={false}>
									<span style={getFlexStyle('8rem')}>
										<TextStyle variation="strong"># ID</TextStyle>
									</span>
									<span style={getFlexStyle('12rem')}>
										<TextStyle variation="strong">Full name</TextStyle>
									</span>
									<span style={getFlexStyle('12rem')}>
										<TextStyle variation="strong">Role</TextStyle>
									</span>
									<span style={getFlexStyle('5rem')}>
										<TextStyle variation="strong">Stores</TextStyle>
									</span>
									<span style={getFlexStyle('9rem')}>
										<TextStyle variation="strong">Total pushed</TextStyle>
									</span>
									<span style={getFlexStyle('9rem')}>
										<TextStyle variation="strong">Total orders</TextStyle>
									</span>
								</Stack>
							</div>

							<div style={{marginTop: '8px'}}>
								{managers.map((item, key) => {
									return (
										<Stack
											key={`data-managers-${key}`}
											distribution="equalSpacing"
											wrap={false}
										>
											<span style={getFlexStyle('8rem')}>
												{item?.id}
											</span>
											<span style={getFlexStyle('12rem')}>
												<strong>{item?.fullName}</strong>
											</span>
											<span style={getFlexStyle('12rem')}>
												{item.teamRole}
											</span>
											<span style={getFlexStyle('5rem')}>
												{item.totalStores}
											</span>
											<span style={getFlexStyle('9rem')}>
												{item.totalPushedItems}
											</span>
											<span style={getFlexStyle('9rem')}>
												{item.totalOrders}
											</span>
										</Stack>
									)
								})}
							</div>
						</div>
					) : null}

					{teams && teams.length > 0 ? (
						<div id="section-teams" style={{marginTop: '20px', 'margin-top': '20px', 'border-top': '1px solid #eee', 'padding-top': '20px'}}>
							<Heading element="h4">Teams</Heading>

							<div style={{marginTop: '10px'}}>
								<Stack wrap={false}>
									<span style={getFlexStyle('8rem')}>
										<TextStyle variation="strong"># ID</TextStyle>
									</span>
									<span style={getFlexStyle('12rem')}>
										<TextStyle variation="strong">Name</TextStyle>
									</span>
									<span style={getFlexStyle('9rem')}>
										<TextStyle variation="strong">Total stores</TextStyle>
									</span>
									<span style={getFlexStyle('9rem')}>
										<TextStyle variation="strong">Total pushed</TextStyle>
									</span>
									<span style={getFlexStyle('9rem')}>
										<TextStyle variation="strong">Total orders</TextStyle>
									</span>
								</Stack>
							</div>

							<div style={{marginTop: '8px'}}>
								{teams.map((item, key) => {
									return (
										<Stack
											key={`data-teams-${key}`}
											// distribution="equalSpacing"
											wrap={false}
										>
											<span style={getFlexStyle('8rem')}>
												{item?.id}
											</span>
											<span style={getFlexStyle('12rem')}>
												<strong>{item?.name}</strong>
											</span>
											<span style={getFlexStyle('9rem')}>
												{item.totalStores}
											</span>
											<span style={getFlexStyle('9rem')}>
												{item.totalPushedItems}
											</span>
											<span style={getFlexStyle('9rem')}>
												{item.totalOrders}
											</span>
										</Stack>
									)
								})}
							</div>
						</div>
					) : null}
				</>
			)
			: null;
	}, [state.items]);

	return (
		<CardBox
			title="Tiktok Statistic"
			headMarkup={null}
			contentMarkup={contentMarkup}
			filter={filter}
			loading={state.loading}
			error={error || e2}
			setFilter={setFilter}
			collapseKey={collapseKey}
		/>
	);
}

export { TiktokStatistic };
