import React from "react";
import { Avatar } from "@shopify/polaris";
import styled from "styled-components";

const Container = styled.div`
  width: 220px;
  display: flex;
  flex-wrap: wrap;
`;

const OwnerContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  z-index: 1;
  transform: ${(props) =>
    props.index
      ? `translateX(-${(props.index % 5) * 12}px)`
      : "translateX(0px)"};
  .Polaris-Avatar {
    border: 2px solid #fff;
    width: calc(4rem + 4px);
  }
  .tolltip {
    visibility: hidden;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 8px;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
  }
  &:hover {
    z-index: 2;
    .tolltip {
      visibility: visible;
    }
  }
`;

export const ColOwnerPolaris = ({ owners }) => {
  let newOwners = (owners || []).filter((owner) => owner.role === "Owner");
  return (
    <Container>
      {newOwners.map((owner, index) => (
        <OwnerContainer key={index} index={index}>
          <Avatar
            source={
              owner.user && owner.user.avatar && owner.user.avatar.thumbnailUrl
                ? owner.user.avatar.thumbnailUrl
                : null
            }
            initials={
              (owner &&
                owner.user &&
                owner.user.firstName &&
                owner.user.firstName.charAt(0)) ||
              (owner &&
                owner.user &&
                owner.user.lastName &&
                owner.user.lastName.charAt(0))
            }
          />
          <span className="tolltip">{`${
            owner && owner.user && owner.user.firstName
          } ${owner && owner.user && owner.user.lastName} `}</span>
        </OwnerContainer>
      ))}
    </Container>
  );
};
