import {
  DataTable,
  Thumbnail,
  TextField,
  Badge,
  Button,
  Toast,
} from "@shopify/polaris";
import get from "lodash/get";
import React, { useEffect, useState, forwardRef } from "react";
import styled from "styled-components";
import moment from "moment";
import { useAppContext } from "../../../context";
import {
  handleError,
  objectInvalid,
  elementContains,
  getParamByRole,
  checkRole,
} from "../../../helper";
import SuspenseComp from "../../shared/SuspenseComp";
import EmptyStatePolaris from "../../shared/EmptyStatePolaris";
import SkeletonPage from "../../shared/SkeletonPagePolaris";
import ElementScrollable from "../../shared/ElementScrollable";
import { PaginationPolaris } from "../../shared/PaginationPolaris";
import CrawlItemImage from "./CrawlItemImage";
import DeleteCrawlItem from "./actions/DeleteCrawlItem";
import EditCrawlItem from "./actions/EditCrawlItem";

import { useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import SelectRow from "../../shared/SelectRow";
import { BulkAction } from "./BulkActions";
import { ProductCrawlProvider } from "./context";
import PushedToStore from "./PushedToStore";

const GET_TIKTOK_CRAWL_PRODUCTS = gql`
  query getTiktokCrawlProducts($filter: TiktokCrawlProductFilter!) {
    getTiktokCrawlProducts(filter: $filter) {
      total
      nodes {
        id
        createdAt
        title
        originID
        originUrl
        status
        teamID
        team {
          id
        }
        collectionIDs
        collections {
          id
          name
        }
        tagIDs
        tags {
          id
          name
        }
        mainImage
        image2
        image3
        image4
        image5
        image6
        image7
        image8
        image9
        templateID
        template {
          id
          title
        }
        createdBy {
          firstName
          lastName
        }
        randomText
        originSKU
        isPersonalized
      }
    }
  }
`;

const headings = [
  "ID",
  "Image",
  "Title",
  "Origin SKU",
  "Taxon",
  "Template",
  "Pushed to Stores",
  "Random Text",
  "Personalized",
  "Created At",
  "Actions",
];

export default forwardRef(function ProductCrawlItemsTable(props, ref) {
  let currentFilters = props.filters;

  const { currentUser } = useAppContext();

  const [activeToast, setActiveToast] = useState(false);
  const [toastMessage, setToastMessage] = useState({});
  const [crawlProducts, setCrawlProducts] = useState([]);
  const [aggregation, setAggregation] = useState({});

  const onItemChanged = async (data) => {
    let successMessage =
      data && data.type && "success" == data.type && data.message
        ? data.message
        : null;
    let failMessage =
      data && data.type && "fail" == data.type && data.message
        ? data.message
        : null;
    setToastMessage({
      success_message: successMessage,
      error_message: failMessage,
    });
    setActiveToast(true);

    try {
      await refetch();
    } catch (err) {
      console.error("Error refreshing data:", err);
    }
  };

  const getRows = (nodes, checkItemMarkup) => {
    const res = [];
    const { isStoreManager } = checkRole(currentUser);
    for (let node of nodes) {
      if (objectInvalid(node)) continue;
      let {
        mainImage,
        id,
        title,
        createdAt,
        originUrl,
        collections,
        tags,
        template,
        image2,
        image3,
        image4,
        image5,
        image6,
        image7,
        image8,
        image9,
        createdBy,
        randomText,
        originSKU,
        isPersonalized,
      } = node;
      tags = Array.from(
        new Map(
          [...(tags || [])]
            .map((i) => (i?.id ? [i.id, i] : null))
            .filter(Boolean),
        ).values(),
      );
      createdAt = moment(createdAt).format("YYYY-MM-DD HH:mm:ss");

      let galleryImageUrls = [
        image2 || null,
        image3 || null,
        image4 || null,
        image5 || null,
        image6 || null,
        image7 || null,
        image8 || null,
        image9 || null,
      ];

      let itemGalleryImages = null;
      if (image2 && image2.length > 0) {
        itemGalleryImages = (
          <div className="similar_wrap">
            <span className="pseudo">&nbsp;</span>
            <div className="similar_inner">
              {galleryImageUrls.length > 0 ? (
                <div className="similar-products">
                  {galleryImageUrls
                    .filter((imageUrl) => imageUrl) // Lọc các URL hợp lệ
                    .map((imageUrl, index) => (
                      <div key={imageUrl || index} className="similar-product">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <CrawlItemImage
                            imageUrl={imageUrl}
                            galleryImageUrls={galleryImageUrls}
                          />
                        </div>
                      </div>
                    ))}
                </div>
              ) : null}
            </div>
          </div>
        );
      }
      const galleryImages = [
        <div className="custom-row">{itemGalleryImages}</div>,
      ];

      let disableActions =
        isStoreManager &&
        currentUser?.id &&
        createdBy?.id &&
        currentUser.id !== createdBy.id
          ? true
          : false;

      const item = [
        checkItemMarkup(id),
        // Field ID
        <div className="id item">
          <span>{id}</span>
        </div>,

        // Field main image
        <div className="thumb item">
          <CrawlItemImage
            imageUrl={mainImage}
            galleryImageUrls={galleryImageUrls}
          />
        </div>,

        // Field title
        <div className="title item">
          <div>{title}</div>
          <div className="origin-url">
            <TextField value={originUrl} />
          </div>
        </div>,

        <div className="origin-sku item">
          <span>{originSKU}</span>
        </div>,

        // Field taxon
        <div className="taxons item">
          {collections && collections.length > 0 ? (
            <div className="collections item">
              <div>
                <strong>Collections: </strong>
              </div>
              <div>
                {collections.map((collection) => (
                  <Badge key={collection.id}>{collection.name}</Badge>
                ))}
              </div>
            </div>
          ) : null}

          {tags && tags.length > 0 ? (
            <div className="tags item">
              <div>
                <strong>Tags: </strong>
              </div>
              <div>
                {tags.map((tag, index) => (
                  <Badge key={`${id}-tag-${tag?.id || index}`}>
                    {tag.name}
                  </Badge>
                ))}
              </div>
            </div>
          ) : null}
        </div>,

        <div className="template item">
          <Badge status="success">{template?.title || null}</Badge>
        </div>,
        <div className="pushed-to-store item">
          <PushedToStore node={node} />
        </div>,
        <div className="random-text item">
          <span>{randomText}</span>
        </div>,
        <div className="personalized item">
          <Badge
            status={isPersonalized ? "success" : "new"}
            children={isPersonalized ? "Yes" : "No"}
          />
        </div>,

        <div className="created-at item">
          <span>{createdAt}</span>

          {createdBy?.firstName || createdBy?.lastName ? (
            <div style={{ marginTop: "10px" }}>
              <span>
                <strong>By: </strong>
                {`${createdBy?.firstName ?? ""} ${createdBy?.lastName ?? ""}`}
              </span>
            </div>
          ) : null}
        </div>,

        <div className="actions item">
          <EditCrawlItem item={node} onEdited={onItemChanged} />
          {!disableActions ? (
            <DeleteCrawlItem item={node} onDeleted={onItemChanged} />
          ) : null}
        </div>,
      ];

      res.push(item);
      if (itemGalleryImages) {
        res.push(galleryImages);
      }
    }
    return res;
  };

  const [filters, setFilters] = useState({
    paged: 1,
    limit: currentFilters?.limit || 20,
    offset: currentFilters?.offset || 0,
  });

  const { loading, error, data, refetch } = useQuery(
    GET_TIKTOK_CRAWL_PRODUCTS,
    {
      variables: {
        filter: {
          limit: filters.limit,
          offset: filters.offset,
          search: currentFilters?.search || null,
          originSource: currentFilters?.domain || null,
          orderBy: currentFilters?.orderBy || null,
          order: currentFilters?.order || null,
          createdByID: currentFilters?.createdByID || null,
          collectionIDs: currentFilters?.collectionIDs,
          tagIDs: currentFilters?.tagIDs,
          isPushed: currentFilters?.isPushed,
          isPersonalized:
            currentFilters?.isPersonalized != null
              ? currentFilters.isPersonalized
              : null,
          range: currentFilters?.range != null ? currentFilters.range : null,
        },
      },
      fetchPolicy: "network-only",
    },
  );

  React.useImperativeHandle(ref, () => ({
    refetch,
  }));

  useEffect(() => {
    let nodes = data?.getTiktokCrawlProducts?.nodes;
    if (nodes) {
      setCrawlProducts(nodes);
    } else {
      setCrawlProducts([]);
    }

    let total = data?.getTiktokCrawlProducts?.total;

    let agg = {
      page: filters.paged,
      totalPage: Math.ceil(total / filters.limit),
      offset: filters.offset,
      limit: filters.limit,
      onChange: onPaginationChanged,
      total: total,
    };
    setAggregation(agg);
  }, [data, filters]);

  const rows = React.useCallback(
    (checkItemMarkup) => getRows(crawlProducts, checkItemMarkup),
    [crawlProducts],
  );
  useEffect(() => {
    // Colspan th
    const domTHs = document.querySelectorAll(".Polaris-DataTable__Cell");
    const domCustomRows = document.querySelectorAll(".custom-row");

    for (let domTH of domTHs) {
      for (let domCustomRow of domCustomRows) {
        if (elementContains(domTH, domCustomRow)) {
          domTH.style.background = "var(--p-surface, #f4f6f8)";
          domTH.setAttribute("colspan", headings.length);
        } else {
          let attr = domTH.getAttribute("colspan");
          if (attr && !!domTH.nextElementSibling) {
            domTH.removeAttribute("style");
            domTH.removeAttribute("colspan");
          }
        }
      }
    }
  }, [JSON.stringify(crawlProducts), data]);

  const onPaginationChanged = (offset, limit) => {
    let newPaged = Math.floor(offset / limit) + 1;
    setFilters((prev) => ({ ...(prev || {}), limit, offset, paged: newPaged }));
  };

  const ToastMessage = activeToast ? (
    <Toast
      content={toastMessage.success_message}
      error={toastMessage.error_message}
      duration={5000}
      onDismiss={() => setActiveToast(false)}
    />
  ) : null;

  return (
    <>
      {ToastMessage}
      <SuspenseComp fallback={<SkeletonPage />}>
        {loading ? (
          <SkeletonPage />
        ) : error ? (
          <div>Error: {handleError(error?.toString())}</div>
        ) : crawlProducts?.length > 0 ? (
          <Wrapper>
            <ProductCrawlProvider data={data}>
              <SelectRow
                data={data?.getTiktokCrawlProducts?.nodes || []}
                refetch={refetch}
                actionMarkup={<BulkAction />}
              >
                {({ checkAllMarkup, checkItemMarkup }) => (
                  <ElementScrollable fixedColumn>
                    <DataTable
                      rows={rows(checkItemMarkup)}
                      columnContentTypes={[
                        ...Array.from({ length: 11 }).fill("text"),
                        "numeric",
                      ]}
                      headings={[checkAllMarkup, ...headings]}
                      verticalAlign="middle"
                      hideScrollIndicator
                    />
                  </ElementScrollable>
                )}
              </SelectRow>
            </ProductCrawlProvider>
            <PaginationContainer>
              <PaginationPolaris
                aggregation={aggregation}
                showTotal
                limitRange={[20, 50, 100, 200, 300, 500, 1000]}
              />
            </PaginationContainer>
          </Wrapper>
        ) : (
          <EmptyStatePolaris />
        )}
      </SuspenseComp>
    </>
  );
});

const PaginationContainer = styled.div`
  padding: 1.6rem;
  overflow-x: scroll;
  background: var(--p-surface-subdued, #f4f6f8);
`;

export const Wrapper = styled.div`
  overflow-x: auto;

  .Polaris-DataTable__Table {
    overflow-x: inherit;
  }

  .Polaris-DataTable__Cell--firstColumn {
    width: 100px;
  }

  .id {
    width: 100px;
  }

  .origin-sku {
    width: 150px;
  }

  .thumb,
  .Polaris-Thumbnail--sizeLarge {
    width: 200px;
  }

  .item {
    white-space: normal;
    word-break: break-word;
  }

  .title {
    width: 300px;
  }

  .other-images {
    width: 250px;
  }
  .origin-url {
    margin-top: 10px;
  }

  .taxons,
  .random-text {
    width: 200px;
  }
  .template {
    width: 150px;

    .Polaris-Badge__Content {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 150px;
    }
  }

  .pushed-to-store {
    width: 350px;
  }

  .collections {
    margin-bottom: 20px;
  }

  .actions,
  .created-at {
    width: 150px;
  }

  .actions .Polaris-Button:first-child {
    margin-right: 15px;
  }

  // Similar
  .custom-row {
    .similar_wrap {
      position: relative;
      .similar_inner {
        display: flex;
      }
      .pseudo {
        position: absolute;
        width: 24px;
        height: 24px;
        top: -2.8rem;
        left: 129px;
        transform: rotate(45deg);
        background: var(--p-surface, #f4f6f8);
      }
      .similar-products,
      .similar-items {
        display: flex;
        flex-direction: row;
        gap: 10px;

        .similar-product,
        .similar-item {
          display: flex;
          flex-direction: column;
          width: 200px;
          justify-content: center;
          text-align: center;
          align-self: flex-start;
          .title_inner {
            margin: 0 1rem;
            display: inline-block;
          }
        }
      }
    }
  }
`;
