import React, { Fragment, useCallback, useReducer } from "react";
import { Button } from "@shopify/polaris";

import useToggle from "../../hooks/useToggle";
import BulkDeleteStore from "./components/BulkDeleteStore";
import { reducerFn, checkRole } from "../../helper";
import BulkUpdateStoreManager from "./components/bulk-update-store-manager";
import BulkUpdateMarketplaceManager from "./components/bulk-update-marketplace-manager";
import BulkAddGroup from "./components/bulk-add-group";
import { useAppContext } from "../../context";

export const BulkActionStore = () => {
	const { currentUser } = useAppContext();
	const { isSeller } = checkRole(currentUser);

  // State
  const [open, toggleOpen] = useToggle(false);
  const [state, setState] = useReducer(reducerFn, {
    open: false,
    action: null,
  });

  const handleClickAct = useCallback(
    (act) => () => {
      setState({ action: act, open: true });
    },
    [],
  );

  const handleClose = useCallback(() => {
    setState({ action: null, open: false });
  }, []);

  const props = {
    open: state.open,
    onClose: handleClose,
  };

  const actionOb = {
    updateStoreManager: <BulkUpdateStoreManager {...props} />,
    addGroup: <BulkAddGroup {...props} />,
    updateMarketplaceManager: <BulkUpdateMarketplaceManager {...props} />,
  };

  const actionMarkup = actionOb[state.action] || null;

  return (
    <Fragment>
      <Button
        children="Update Store Manager"
        onClick={handleClickAct("updateStoreManager")}
      />

		{isSeller ? (
			<Button
				children="Update Marketplace Manager"
				onClick={handleClickAct("updateMarketplaceManager")}
			/>
		) : null}

      <Button children="Add Group" onClick={handleClickAct("addGroup")} />
      <Button children="Delete" destructive onClick={toggleOpen} />
      <BulkDeleteStore onClose={toggleOpen} open={open} />
      {actionMarkup}
    </Fragment>
  );
};
