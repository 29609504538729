import { Card, Page, SkeletonDisplayText } from "@shopify/polaris";
import SuspenseComp from "../../shared/SuspenseComp";
import PushProductsFilter from "./PushProductsFilter";
import PushProductsTable from "./PushProductsTable";
import React, {useState, useEffect, useRef, useMemo} from "react";

import { useAppContext } from "../../../context";
import { getParamByRole } from "../../../helper";


export default function TiktokProductCrawlItems() {
	const [filters, setFilters] = useState({
		limit: 20,
		offset: 0,
		search: null,
		storeID: null,
		orderBy: 'created_at',
		order: 'DESC',
		createdByID: null,
		isPersonalized: null,
		isPushMass: null,
		status: null,
	});
	const isMounted = useRef(true);

	useEffect(() => {
		isMounted.current = true;
		return () => {
			isMounted.current = false;
		};
	}, []);

	const safeSetFilters = (newFilters) => {
		if (isMounted.current) {
			setFilters(newFilters);
		}
	}

	const { currentUser } = useAppContext();
	const param = getParamByRole(currentUser);

	return (
		<Page title="Push Products" fullWidth primaryAction={{
			content: "New Push",
			url: `/${param}/tiktok-hub/push-products/add`,
		}}>
			<Card sectioned>
				<SuspenseComp fallback={<SkeletonDisplayText />}>
					<PushProductsFilter filters={filters} onFilterChange={safeSetFilters}/>
				</SuspenseComp>
			</Card>
			<Card>
				<PushProductsTable filters={filters}/>
			</Card>
		</Page>
	);
}
