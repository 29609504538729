import {
    Button,
    Checkbox,
    Heading,
    Labelled,
    Stack,
    TextStyle,
} from "@shopify/polaris";
import { MobileHorizontalDotsMajorMonotone } from "@shopify/polaris-icons";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import useToggle from "../../../../hooks/useToggle";

import { MediaSelectorButton2Polaris } from "../../../file/MediaSelectorButton2Polaris";
import { DesignPosition } from "../../../product/print-file/Create/PrintFileComp";
import { ViewModalImage } from "../../../product/ViewModalImage";
// import ModalImageClaimPolaris from "../../../seller/ModalImageClaimPolaris";
import { useCreateIdea } from "../context/createIdea";
import { checkStatusTask } from "../utils";
import { genMediaChild, MediaComp } from "./IdeaSection";
import DonwloadAllFiles from "../../../shared/download-all-files";

export function PrintFile({
    base,
    handleUpdateBase,
    item,
    hideTitle,
    hideBtnRemove,
    taskId,
    isTeamLeader,
    handleChange,
}) {
    // Props
    const { status, id: itemId, mainMockups } = item || {};
    const { isApproved, isNeedReview, isRejected } = checkStatusTask(status);

    const {
        id: baseId,
        designPositions: dps = [],
        designPositionsForGen: dpsGen = [],
        mockupTemplates,
        customMockups = [],
    } = base || {};
    const hasMockupTemplates = mockupTemplates?.length > 0;
    const targetId = [itemId, baseId].filter(Boolean).join("-");
    // const dps = base?.designPositions || [];
    const designPositions =
        isApproved || isNeedReview
            ? dps.filter((item) => item?.file?.length > 0)
            : dps;

    // const dpsGen = base?.designPositionsForGen || [];
    const designPositionsForGen =
        isApproved || isNeedReview
            ? dpsGen.filter((item) => item?.file?.length > 0)
            : dpsGen;

    // Context
    const { setErrorDimensions } = useCreateIdea();

    // State
    const [checked, setChecked] = useState(false);
    const [mainImageId, setMainImageId] = useState(mainMockups || []);

    // Get data
    useEffect(() => {
        if (mainMockups?.length > 0) {
            setMainImageId(mainMockups);
        }
    }, [mainMockups]);

    // Actions
    const handleFileChange = useCallback(
        (value, dpId) => {
            base.designPositions = updateFile(
                base.designPositions,
                dpId,
                value
            );
            handleUpdateBase && handleUpdateBase(base);
        },
        [base, handleUpdateBase]
    );
    const handleCheckedChange = useCallback(
        (newValue) => {
            const cloneDp =
                base.designPositions?.length > 0
                    ? [...base.designPositions]
                    : [];

            if (!newValue) {
                base.designPositions = cloneDesignPosition(cloneDp);
                handleUpdateBase && handleUpdateBase(base);
            } else {
                base.designPositions = cloneDesignPosition(cloneDp, true);
                handleUpdateBase && handleUpdateBase(base);
            }
            setChecked(newValue);
        },
        [base, handleUpdateBase]
    );

    const handleFileForGenChange = useCallback(
        (value, dpId) => {
            base.designPositions = updateFile(
                base.designPositions,
                dpId,
                value,
                false
            );
            handleUpdateBase && handleUpdateBase(base);
        },
        [handleUpdateBase, base]
    );

    const handleCustomMockupChange = useCallback(
        (value) => {
            base.customMockups = value;
            handleUpdateBase && handleUpdateBase(base);
        },
        [handleUpdateBase, base]
    );

    const descriptionMarkup = (
        <DesignPosition
            targetId={targetId}
            designPositions={designPositions}
            handleFileChange={handleFileChange}
            setErrorDimensions={setErrorDimensions}
            checked={isApproved}
            hideBtnRemove={isApproved || hideBtnRemove}
            showArtGuide={isTeamLeader && isNeedReview} // show is TeamLeader and need review status
            approveProps={{ item, taskId }}
        />
    );

    const canGenFile =
        designPositionsForGen?.filter((i) => i?.file?.length > 0).length > 0;

    let showCheckbox = designPositions?.length > 0 && hasMockupTemplates;
    if (isApproved || isNeedReview) {
        showCheckbox = showCheckbox && canGenFile;
    }

    const canUpdateMainImage = isRejected || isNeedReview;
    const handleChecked = useCallback(
        (check, id, isCustom = false) => {
            if (!check || id == null) return;

            let clone = [...mainImageId];
            const index = clone.findIndex((i) => i === id);
            if (index > -1) {
                clone.splice(index, 1);
            } else {
                clone = [id];
            }
            handleChange && handleChange({ clone, isCustom });
        },
        [mainImageId, handleChange]
    );

    const handleMainCustomMockup = useCallback(
        (value) => {
            handleChecked(true, value, true);
        },
        [handleChecked]
    );

    return (
        <Wrapper hideTitle={hideTitle}>
            {!hideTitle && <Heading children="Print file" />}
            <Stack vertical>
                {designPositions?.length > 0 ? descriptionMarkup : null}
                {showCheckbox && (
                    <div>
                        <Checkbox
                            checked={canGenFile || checked}
                            onChange={handleCheckedChange}
                            label={
                                <TextStyle
                                    variation="strong"
                                    children="Using separate files for generating mockup"
                                />
                            }
                        />
                        {(canGenFile || checked) && (
                            <DesignPosition
                                checked
                                targetId={`${targetId}-gen`}
                                designPositions={designPositionsForGen}
                                handleFileChange={handleFileForGenChange}
                                setErrorDimensions={setErrorDimensions}
                                hideBtnRemove={hideBtnRemove}
                            />
                        )}
                    </div>
                )}
                {((customMockups?.length > 0 && isNeedReview) ||
                    !isNeedReview) && (
                    <CustomMockupWrapper isNeedReview={isNeedReview}>
                        <Stack distribution="equalSpacing" alignment="center"  spacing="none">
                            <Labelled label="Custom mockups" />
                            <DonwloadAllFiles files={customMockups} filename={taskId}/>
                        </Stack>
                        <MediaSelectorButton2Polaris
                            multiple
                            accept={"image/*"}
                            onChange={handleCustomMockupChange}
                            sizeSmall
                            value={customMockups}
                            hideBtnRemove={hideBtnRemove || isNeedReview}
                            setMainImage={handleMainCustomMockup}
                            hasMainImg
                            mainImageId={
                                mainImageId?.length > 0 ? mainImageId[0] : null
                            }
                            fromIdeaTask
                        />
                    </CustomMockupWrapper>
                )}
                <GeneratedMockup
                    taskBaseSubmissions={base?.taskBaseSubmissions}
                    canUpdateMainImage={canUpdateMainImage}
                    mainImageId={mainImageId}
                    handleChecked={handleChecked}
                />
            </Stack>
        </Wrapper>
    );
}

function GeneratedMockup({
    taskBaseSubmissions,
    // canUpdateMainImage,
    mainImageId,
    // handleChecked,
}) {
    // Props
    const [submission] = taskBaseSubmissions || [];
    const { taskBaseMockups } = submission || {};
    const mockups = (taskBaseMockups || [])
        .map((i) =>
            i?.outputMockup
                ? {
                      ...i.outputMockup,
                      isMainMockup: i.isMainMockup,
                      mockupId: i.id,
                  }
                : null
        )
        .filter(Boolean);

    // State
    const [open, toggleOpen] = useToggle(false);

    const newMockups = useMemo(() => {
        const threeItems = [];
        const remainItems = [];
        const cloneMockups = [...mockups];
        const mainMockup = cloneMockups.find((i) => i.isMainMockup);
        const remainMockups = cloneMockups.filter((i) => !i.isMainMockup);
        const mergedMockups = mainMockup
            ? [mainMockup, ...remainMockups]
            : remainMockups;

        for (let i = 0; i < mergedMockups.length; i++) {
            const mockup = mergedMockups[i];

            if (i < 3) {
                threeItems.push(mockup);
            } else {
                remainItems.push(mockup);
            }
        }

        return {
            threeItems,
            remainItems: remainItems.map((i) => ({
                file: i,
            })),
        };
    }, [mockups]);

    const { threeItems, remainItems } = newMockups;
    const filesMarkup = genMediaChild(
        threeItems,
        // canUpdateMainImage,
        // handleChecked,
        mainImageId
    );

    const threeItemsHaveFile = (threeItems || []).map((f) => ({ file: f }));
    const suffixEl = remainItems?.length > 0 && (
        <>
            <div className="more-action">
                <Button
                    icon={MobileHorizontalDotsMajorMonotone}
                    plain
                    onClick={toggleOpen}
                />
            </div>
            <ViewModalImage
                item={{ images: [...remainItems, ...threeItemsHaveFile] }}
                open={open}
                toggleShowModal={toggleOpen}
                large
            />
        </>
    );

    const files = [...threeItemsHaveFile, ...remainItems];

    return (
        mockups?.length > 0 && (
            <GeneratedMockupWrapper>
                <Heading children="Generated mockups" element="h4" />
                <MediaComp
                    children={filesMarkup}
                    suffixEl={suffixEl}
                    files={files}
                />
            </GeneratedMockupWrapper>
        )
    );
}

function updateFile(arr, dpId, value, updateFile = true) {
    if (!arr || arr.length === 0) return null;
    return arr.map((dp) => {
        if (dp.id === dpId) {
            if (updateFile) {
                dp.file = value;
            } else {
                dp.generateMockupFile = value;
            }
        }
        return dp;
    });
}

function cloneDesignPosition(arr, hasGen) {
    if (!arr || arr.length === 0) return [];
    return arr.map((dp) => {
        const { generateMockupFile, ...rest } = dp || [];
        return hasGen ? dp : rest;
    });
}

const GeneratedMockupWrapper = styled.div`
    .media-wrapper {
        align-items: end;
    }

    .more-action {
        margin-bottom: -1rem;
    }

    .img-box-shadow {
        position: relative;

        .Polaris-Choice {
            position: absolute;
            top: 0;
            left: 0;
            padding: 0;

            &__Control {
                margin-top: 0;
            }
        }
    }
`;

const Wrapper = styled.div`
    --width: 16rem;

    ${({ hideTitle }) =>
        !hideTitle &&
        `margin-top: 1.6rem;
        padding-top: 1.6rem;
        border-top: 1px solid #c4cdd5;`}

    .dp-wrap {
        display: flex;
        flex-flow: row wrap;
        gap: 1.6rem;

        .dp-inner {
            width: var(--width);

            .Polaris-DropZone,
            .list-file_wrap {
                height: var(--width);

                .file_wrap {
                    height: var(--width);
                    width: var(--width);
                }
            }
        }
    }
`;

const CustomMockupWrapper = styled.div`
    --width: 16rem;
    ${({ isNeedReview }) =>
        isNeedReview &&
        `.dropzone_container {
            display: none;
        }`}

    .Polaris-DropZone {
        height: var(--width);
        width: var(--width);
    }

    .list-file_wrap.multiple {
        .file-item {
            height: var(--width);
            width: var(--width);
            border: none;
        }
        .file_wrap {
            width: 100%;
            height: 100%;
        }
    }
`;
