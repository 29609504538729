import { useMutation } from "@apollo/react-hooks";
import { Button, DropZone, Modal, TextStyle } from "@shopify/polaris";
import { CircleCancelMinor } from "@shopify/polaris-icons";
import React from "react";
import * as XLSX from "xlsx";

import { BULK_CREATE_TIKTOK_CRAWL_PRODUCTS } from "../../../graphql/mutations/tiktok";
import { arrInvalid, handleError, reducerFn } from "../../../helper";
import { ComponentLabelPolaris } from "../../shared/ComponentLabelPolaris";
import { useToastContext } from "../../shared/ToastContext";
import useTimeout from "../../../hooks/useTimeout";

const initialValue = {
  name: "",
  open: false,
  data: [],
  error: "",
};

function ProductCrawlImport({ open, onClose, onCompleted }) {
  const [state, setState] = React.useReducer(reducerFn, initialValue);

  const { setNotify, toggleToast } = useToastContext();

  const [setDelay] = useTimeout();
  const [bulkCreate, { loading }] = useMutation(
    BULK_CREATE_TIKTOK_CRAWL_PRODUCTS,
    {
      onCompleted: () => {
        setNotify({ msg: "Import product crwal success", err: false });
        onClose && onClose();
        setDelay(onCompleted, 1500);
      },
      onError: (err) => {
        setNotify({ msg: handleError(err?.toString()), err: true });
      },
    },
  );
  const handleSubmit = React.useCallback(() => {
    setNotify({ msg: null, err: false });
    toggleToast(true);
    if (arrInvalid(state.data)) {
      setNotify({ msg: "Please choose the file to import", err: true });
      return;
    }

    bulkCreate({
      variables: {
        input: state.data,
      },
    });
  }, [bulkCreate, toggleToast, state.data]);

  const handleDropAccepted = React.useCallback(async (files) => {
    try {
      setState(initialValue);
      let [file] = files;

      const data = await getDataFromFile(file);
      setState({ name: file?.name, data });
    } catch (err) {
      console.error(err?.toString());
    }
  }, []);

  const handleDropRejected = React.useCallback((files) => {
    setState(initialValue);
    let [file] = files;
    let name = file?.name || "The file";
    setState({ error: `File: ${name} error, type invalid` });
  }, []);

  const handleRemove = React.useCallback(() => {
    setState(initialValue);
  }, []);

  const handleValidator = React.useCallback((file) => {
    if (file instanceof File) {
      const { type } = file;
      if (
        ![
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          "application/vnd.ms-excel",
          "text/csv",
        ].includes(type)
      ) {
        return false;
      }
    }
    return true;
  }, []);

  return (
    <Modal
      title="Import Product Crawl"
      sectioned
      open={open}
      onClose={onClose}
      secondaryActions={[{ content: "Cancel", onAction: onClose }]}
      primaryAction={{ content: "Submit", onAction: handleSubmit, loading }}
    >
      <div>
        <ComponentLabelPolaris label="Crawler file" required />
        <DropZone
          allowMultiple={false}
          onDropAccepted={handleDropAccepted}
          onDropRejected={handleDropRejected}
          disabled={!!loading}
          customValidator={handleValidator}
        >
          <DropZone.FileUpload />;
        </DropZone>

        <div style={{ marginTop: 8 }}>
          <TextStyle
            variation="subdued"
            children="The valid file types are CSV or XLSX"
          />
        </div>
        {state.error ? (
          <TextStyle variation="negative" children={state.error} />
        ) : state.name ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span className="file-name">{state.name}</span>
            <Button
              plain
              size="slim"
              icon={CircleCancelMinor}
              onClick={handleRemove}
            />
          </div>
        ) : null}

        <div style={{ marginTop: "8px" }}>
          Product Crawl Template 
          <a
            className="Polaris-Link"
            target="_blank"
            href="https://docs.google.com/spreadsheets/d/14bCTtYwfVTIyS8p6ecjBjdz9ZZfhlxxT_GFzEnwekO4/edit?gid=0#gid=0"
          >
            {" "} example
          </a>
        </div>
      </div>
    </Modal>
  );
}

const colNameKey = "collectionNames";
const tagNameKey = "tagNames";
const originSKUKey = "originSKU";
const MAP_HEADER = {
  title: "title",
  url: "originUrl",
  "image 1": "image1",
  "image 2": "image2",
  "image 3": "image3",
  "image 4": "image4",
  "image 5": "image5",
  "image 6": "image6",
  "image 7": "image7",
  "image 8": "image8",
  "image 9": "image9",
  "collection name": colNameKey,
  "collection names": colNameKey,
  "tag name": tagNameKey,
  "tag names": tagNameKey,
  "random text": "randomText",
  "origin sku": originSKUKey,
};

async function getDataFromFile(file) {
  return new Promise((res, rej) => {
    const reader = new FileReader();
    reader.onload = function (evt) {
      const data = new Uint8Array(evt.target.result);

      const workbook = XLSX.read(data, { type: "array" });

      // Get the first sheet (you can loop through sheets if you want)
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];

      // Convert to JSON
      const jsonData = XLSX.utils.sheet_to_json(worksheet);

      if (arrInvalid(jsonData)) rej("could not get data from file");

      const arr = [];
      for (let item of jsonData) {
        const newItem = {};

        let images = Array.from({ length: 9 });
        const pt = /image/i;

        for (let [key, val] of Object.entries(item)) {
          const keyLower = key.toLowerCase().trim();

          const keyValid = MAP_HEADER[keyLower];
          if (!keyValid) continue;

          if ([colNameKey, tagNameKey].includes(keyValid)) {
            val = val
              .split(",")
              .map((i) => i.trim())
              .filter(Boolean);
          }

          if (originSKUKey === keyValid) {
            val = val + ""
          }

          newItem[keyValid] = val;

          if (!!keyValid.match(pt)) {
            let [, imgIndex] = keyValid.split(pt);
            imgIndex = Number(imgIndex.trim());
            images[imgIndex - 1] = val;
          }
        }

        const [mainImage] = images.filter(Boolean);
        newItem["mainImage"] = mainImage;
        delete newItem["image1"];

        arr.push(newItem);
      }
      res(arr);
    };

    reader.onerror = function (ev) {
      rej(`Error occurred reading file: ${file.name}`);
    };

    reader.readAsArrayBuffer(file);
  });
}

export default ProductCrawlImport;
