import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useReducer,
} from "react";
import { GET_USER_BY_ROLES } from "../../graphql/queries";
import { useQuery } from "@apollo/react-hooks";
import { arrInvalid, reducerFn } from "../../helper";
import { get } from "lodash";
import { Autocomplete, Tag } from "@shopify/polaris";
import { ComponentLabelPolaris } from "../shared/ComponentLabelPolaris";
import styled from "styled-components";

function MarketplaceManagerSelect(
  { roles, skip, allowMultiple, label, required },
  ref,
) {
  const [state, setState] = useReducer(reducerFn, {
    input: "",
    selected: [],
    options: [],
    deselectedOptions: [],
    error: "",
  });

  const { data, loading } = useQuery(GET_USER_BY_ROLES, {
    variables: {
      roles,
    },
    skip,
  });

  useEffect(() => {
    const options = getOptions(data);
    setState({ options, deselectedOptions: options });
  }, [JSON.stringify(data)]);

  const handleInput = useCallback(
    (value) => {
      const newState = { input: value };
      if ("" === value) {
        setState({ ...newState, options: state.deselectedOptions });
        return;
      }

      const filterRegex = new RegExp(value, "i");
      const resultOptions = state.deselectedOptions.filter((option) =>
        option.label.match(filterRegex),
      );

      setState({ ...newState, options: resultOptions });
    },
    [state.deselectedOptions],
  );

  const handleSelection = useCallback((selected) => {
    setState({ selected });
  }, []);

  const handleRemove = useCallback(
    (value) => () => {
      let newSelected = state.selected.filter((s) => s !== value);
      setState({ selected: newSelected });
    },
    [state.selected],
  );

  const handleGetSelected = useCallback(() => {
    if (!required) {
      setState({ error: "" });
      return;
    }
    if (arrInvalid(state.selected))
      setState({ error: "The value is required" });

    return state.selected;
  }, [required, state.selected]);

  useImperativeHandle(ref, () => ({
    getSelected: handleGetSelected,
  }));

  const textField = (
    <Autocomplete.TextField
      value={state.input}
      onChange={handleInput}
      placeholder="Search .."
      error={state.error}
    />
  );

  return (
    <>
      <ComponentLabelPolaris
        label={label || "Choose marketplace managers"}
        required={required}
      />
      <Autocomplete
        options={state.options}
        onSelect={handleSelection}
        selected={state.selected}
        textField={textField}
        emptyState={<span>No items found!</span>}
        allowMultiple={allowMultiple}
        loading={loading}
      />
      <TagContainer>
        {state.selected && state.selected.length
          ? state.selected.map((s) => {
              let matchedOptions = (state.options || []).find(
                (option) => option.value === s,
              );
              if (matchedOptions) {
                return (
                  <Tag
                    children={matchedOptions && matchedOptions.label}
                    onRemove={handleRemove(s)}
                    key={s}
                  />
                );
              }
            })
          : null}
      </TagContainer>
    </>
  );
}
function genOpt(node) {
  return {
    label: `${node.user.firstName} ${node.user.lastName}`,
    value: node.user.id,
  };
}

function getOptions(data) {
  const arr = get(data, "listUserTeamByRoles") || [];

  const res = [];
  for (let item of arr) {
    if (!item || !item.user) continue;
    res.push(genOpt(item));
  }

  return res;
}

const TagContainer = styled.div`
  display: flex;
  padding-top: 0.8rem;
  flex-wrap: wrap;
  > * {
    margin-right: 0.8rem;
    margin-bottom: 0.8rem;
  }
`;

export default forwardRef(MarketplaceManagerSelect);
