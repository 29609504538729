import { Heading, TextStyle } from "@shopify/polaris";
import React from "react";
import { objectInvalid } from "../../../helper";

export default function AttributesComp({ order }) {
  if (objectInvalid(order)) return null;

  const attributes =
    order?.productVariant?.productBaseVariant?.attributes?.length > 0
      ? order.productVariant.productBaseVariant.attributes
      : [];

  let attributeMarkup;
  if (Array.isArray(attributes) && attributes.length) {
    attributeMarkup = attributes.map((att, index) => {
      return (
        <React.Fragment key={index}>
          <div className="attribute-item" key={index}>
            <TextStyle variation="strong">{att.name}: </TextStyle>
            <span>{att.option}</span>
          </div>
        </React.Fragment>
      );
    });
  }

  return attributeMarkup ? (
    <div className="attributes-wrap">
      <Heading element="h3">Attributes:</Heading>
      {attributeMarkup}
    </div>
  ) : null;
}
