import React from "react";
import { useTiktokPage } from "../../pages/seller/TiktokPage";
import { Badge, SkeletonDisplayText } from "@shopify/polaris";

function TotalProductPushed({ node }) {
  const { data, loading } = useTiktokPage() || {};

  if (loading) return <SkeletonDisplayText />;

  const count = (data || {})[node?.id];
  return count == null ? null : <Badge children={count} />;
}

export default TotalProductPushed;
