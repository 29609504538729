import { Button, Modal } from "antd";
import React from "react";
import * as XLSX from "xlsx";

import { arrInvalid } from "../../../helper";
import useToggle from "../../../hooks/useToggle";
import { ATTR_MODEL } from "../utils";

export default function AddVariantsFromCSV({
  onChange,
  isMerchize,
  isBurgerPrints,
}) {
  const [open, toggleOpen] = useToggle(false);

  const handleForce = React.useCallback(
    (rows) => {
      if (!rows || rows.length === 0) return;
      const attrs = new Map();
      const vars = new Map();

      /**
     * {
      color: "ASH",
      product_title: "Youth T-shirt Gildan 5000B",
      size: "XS",
      sku: "G5000BUS_101",
      }
     */
      for (let r of rows) {
        vars.set(`${r.color}|${r.size}`, r);
      }

      const newVariants = [];
      const newVars = Array.from(vars.values());
      for (let i = 0; i < newVars.length; i++) {
        const r = newVars[i];
        const attributes = [
          { name: "Size", slug: "size", option: r.size },
          { name: "Color", slug: "color", option: r.color },
        ];
        newVariants.push({
          attributes,
          fulfillmentProductId: r.sku,
          regularPrice: 0,
          salePrice: 0,
          sellerPrice: 0,
          sorting: i,
        });

        for (let v of ["color", "size"]) {
          const attr = attrs.get(v);
          if (attr) {
            attrs.set(v, attr.add(r[v]));
          } else {
            attrs.set(v, new Set([r[v]]));
          }
        }
      }

      const newAttributes = [];
      for (let [slug, options] of Array.from(attrs.entries())) {
        const name = slug.charAt(0).toUpperCase() + slug.slice(1).toLowerCase();
        const opts = Array.from(options.values());

        newAttributes.push({ name, slug, options: opts });
      }
      onChange &&
        onChange({ variants: newVariants, attributes: newAttributes });
      toggleOpen(false);
    },
    [onChange],
  );

  // https://merchize.com/product/classic-unisex-crew-neck-sweatshirt-us/
  const handleFileChange = React.useCallback(
    async (event) => {
      let file;

      // onchange
      if (event.target?.files?.length > 0) {
        file = event.target.files[0];
      }
      // ondrop
      else if (event.dataTransfer?.files?.length > 0) {
        file = event.dataTransfer.files[0];
      }

      if (!file) return;
      try {
        // const { variants, attributes } = await readFileImpl(file);
        let readFileImpl = async () => {};
        // if (isMerchize) readFileImpl = readFileImpl3;
        if (isMerchize) readFileImpl = readFileImplXLSX;
        if (isBurgerPrints) readFileImpl = readFileImpl2;

        const { variants, attributes } = await readFileImpl(file);
        if (arrInvalid(variants) || arrInvalid(attributes))
          throw Error("variant is empty");

        onChange && onChange({ variants, attributes });
        toggleOpen(false);
      } catch (err) {
        console.error("catch error: ", err?.toString());
      }
    },
    [onChange, isMerchize, isBurgerPrints],
  );

  return (
    <>
      <Button children="Add Variants by CSV" onClick={toggleOpen} />
      <Modal
        title="Choose file"
        visible={open}
        onCancel={toggleOpen}
        footer={false}
      >
        {/* <CSVReader
          cssClass="csv-reader-input"
          label={
            <div style={{ display: "block" }}>
              <span>Choose file CSV</span>
            </div>
          }
          onFileLoaded={handleForce}
          parserOptions={papaparseOptions}
          inputId="ObiWan"
          inputName="ObiWan"
        /> */}

        <input
          type="file"
          onChange={handleFileChange}
          onDrop={handleFileChange}
          onDragOver={(e) => e.preventDefault()}
        />
      </Modal>
    </>
  );
}

// // Handle file upload for Marchize
// async function readFileImpl(file) {
//   return new Promise((resolve, reject) => {
//     if (file == null) reject("file is null");
//     const reader = new FileReader();
//     reader.onload = function (evt) {
//       const bstr = evt.target.result;
//       const tDecode = new TextDecoder("utf-8");
//       const str = tDecode.decode(bstr);

//       // line:
//       //    + 1: base_cost
//       //    + 2: title
//       if (!str || typeof str !== "string") {
//         reject("could not read data");
//       }

//       let [, header, ...content] = str.split(/\n/);
//       const pt_type = ATTR_MODEL.type.pt;
//       const pt_color = ATTR_MODEL.color.pt;
//       const pt_size = ATTR_MODEL.size.pt;
//       const pt_sku = /sku variant/i;
//       const pt_tier = /tier/i;
//       const pt_shipping = /shipping fee/i;

//       const headerSplit = (header || "").split(",");
//       const typeIndex = headerSplit.findIndex((i) => !!i.match(pt_type));
//       const colorIndex = headerSplit.findIndex((i) => !!i.match(pt_color));
//       const sizeIndex = headerSplit.findIndex((i) => !!i.match(pt_size));
//       const skuIndex = headerSplit.findIndex((i) => !!i.match(pt_sku));
//       const tierIndex = headerSplit.findIndex((i) => !!i.match(pt_tier));
//       const shippingIndex = headerSplit.findIndex(
//         (i) => !!i.match(pt_shipping),
//       );
//       content = content.filter(Boolean);

//       const attrMap = {};
//       if (content.length === 0) {
//         reject("content is empty");
//       }

//       const newVariants = [];
//       for (let i = 0; i < content.length; i++) {
//         const line = content[i];

//         if (!line || typeof line !== "string") continue;

//         const rows = line.split(",");
//         const typeVal = rows[typeIndex];
//         const colorVal = rows[colorIndex];
//         const sizeVal = rows[sizeIndex];
//         const skuVal = rows[skuIndex];
//         const tierVal = rows[tierIndex];
//         const shippingVal = rows[shippingIndex];

//         const attrs = [];
//         if (typeVal) {
//           attrs.push({
//             name: ATTR_MODEL.type.name,
//             slug: ATTR_MODEL.type.slug,
//             option: typeVal,
//           });

//           const attCache = attrMap[ATTR_MODEL.type.slug];
//           if (!attCache) attrMap[ATTR_MODEL.type.slug] = new Set();
//           attrMap[ATTR_MODEL.type.slug].add(typeVal);
//         }

//         if (colorVal) {
//           attrs.push({
//             name: ATTR_MODEL.color.name,
//             slug: ATTR_MODEL.color.slug,
//             option: colorVal,
//           });

//           const attCache = attrMap[ATTR_MODEL.color.slug];
//           if (!attCache) attrMap[ATTR_MODEL.color.slug] = new Set();
//           attrMap[ATTR_MODEL.color.slug].add(colorVal);
//         }

//         if (sizeVal) {
//           attrs.push({
//             name: ATTR_MODEL.size.name,
//             slug: ATTR_MODEL.size.slug,
//             option: sizeVal,
//           });
//           const attCache = attrMap[ATTR_MODEL.size.slug];
//           if (!attCache) attrMap[ATTR_MODEL.size.slug] = new Set();
//           attrMap[ATTR_MODEL.size.slug].add(sizeVal);
//         }

//         const count = Number(tierVal || 0) + Number(shippingVal || 0);
//         const sellerPrice = count.toFixed(2);
//         const regularPrice = (sellerPrice * 3).toFixed(2);

//         newVariants.push({
//           sorting: i,
//           attributes: attrs,
//           regularPrice: +regularPrice,
//           sellerPrice: +sellerPrice,
//           salePrice: 0,
//           fulfillmentProductId: skuVal,
//         });
//       }

//       const newAttributes = [];
//       if (Object.keys(attrMap).length > 0) {
//         for (let [key, value] of Object.entries(attrMap)) {
//           const name = key.charAt(0).toUpperCase() + key.slice(1).toLowerCase();
//           const arrVal = Array.from(value);
//           newAttributes.push({ name, slug: key, options: arrVal });
//         }
//       }

//       resolve({ variants: newVariants, attributes: newAttributes });
//     };
//     reader.readAsArrayBuffer(file);
//   });
// }

async function readFileImpl3(file) {
  return new Promise((resolve, reject) => {
    if (file == null) reject("file is null");
    const reader = new FileReader();
    reader.onload = function (evt) {
      const bstr = evt.target.result;
      const tDecode = new TextDecoder("utf-8");
      const str = tDecode.decode(bstr);

      // line:
      //    + 1: base_cost
      //    + 2: title
      if (!str || typeof str !== "string") {
        reject("could not read data");
      }

      let [, header, ...content] = str.split(/\n/);
      const pt_type = ATTR_MODEL.type.pt;
      const pt_color = ATTR_MODEL.color.pt;
      const pt_size = ATTR_MODEL.size.pt;
      const pt_sku = /sku variant/i;
      const pt_tier = /tier/i;
      const pt_shipping = /shipping fee/i;

      const headerSplit = (header || "").split(",");
      const typeIndex = headerSplit.findIndex((i) => !!i.match(pt_type));
      const colorIndex = headerSplit.findIndex((i) => !!i.match(pt_color));
      const sizeIndex = headerSplit.findIndex((i) => !!i.match(pt_size));
      const skuIndex = headerSplit.findIndex((i) => !!i.match(pt_sku));
      const tierIndex = headerSplit.findIndex((i) => !!i.match(pt_tier));
      const shippingIndex = headerSplit.findIndex(
        (i) => !!i.match(pt_shipping),
      );
      content = content.filter(Boolean);

      if (content.length === 0) {
        reject("content is empty");
      }

      const [newVariants, newAttributes] = getVariants({
        typeIndex,
        colorIndex,
        sizeIndex,
        skuIndex,
        costIndex: tierIndex,
        shippingIndex,
        rowsStr: content,
      });

      resolve({ variants: newVariants, attributes: newAttributes });
    };
    reader.readAsArrayBuffer(file);
  });
}

async function readFileImplXLSX(file) {
  return new Promise((resolve, reject) => {
    if (file == null) reject("file is null");
    const reader = new FileReader();
    reader.onload = function (evt) {
      const data = new Uint8Array(evt.target.result);

      const workbook = XLSX.read(data, { type: "array" });
      // Get the first sheet (you can loop through sheets if you want)
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];

      // Convert to JSON
      const jsonData = XLSX.utils.sheet_to_json(worksheet);

      if (arrInvalid(jsonData)) reject("could not get data from file");
      const pt_type = ATTR_MODEL.type.pt;
      const pt_color = ATTR_MODEL.color.pt;
      const pt_size = ATTR_MODEL.size.pt;
      const pt_sku = /sku variant/i;
      const pt_tier = /base cost/i;
      const pt_shipping = /shipping fee/i;

      let typeKey, colorKey, sizeKey, skuKey, costKey, shippingKey;
      for (let [key, val] of Object.entries(jsonData[0])) {
        if (val.match(pt_type)) {
          typeKey = key
        } else if (val.match(pt_color)) {
          colorKey = key
        } else if (val.match(pt_size)) {
          sizeKey = key
        } else if (val.match(pt_sku)) { 
          skuKey = key
        } else if (key.match(pt_tier)) {
          costKey = key
        } else if (key.match(pt_shipping)) {
          shippingKey = key
        }
      }
      const rows = jsonData.slice(1);
      if (rows.length === 0) {
        reject("rows is empty");
      }

      const [newVariants, newAttributes] = getVariantsByKey({
        typeKey,
        colorKey,
        sizeKey,
        skuKey,
        costKey,
        shippingKey,
        rowsStr: rows,
      });

      resolve({ variants: newVariants, attributes: newAttributes });
    };
    reader.readAsArrayBuffer(file);
  });
}

async function readFileImpl2(file) {
  return new Promise((resolve, reject) => {
    if (file == null) reject("file is null");
    const reader = new FileReader();
    reader.onload = function (evt) {
      const bstr = evt.target.result;
      const tDecode = new TextDecoder("utf-8");
      const str = tDecode.decode(bstr);

      if (!str || typeof str !== "string") {
        reject("could not read data");
      }

      let [header, header2, ...content] = str.split(/\n/);

      const pt_type = ATTR_MODEL.type.pt;
      const pt_color = ATTR_MODEL.color.pt;
      const pt_size = ATTR_MODEL.size.pt;
      const pt_sku = /sku/i;
      const pt_cost = /base cost/i;
      const pt_shipping = /us/i; // Shipping fee

      const headerSplit = (header || "").split(",");
      const header2Split = (header2 || "").split(",");
      const typeIndex = headerSplit.findIndex((i) => !!i.match(pt_type));
      const colorIndex = headerSplit.findIndex((i) => !!i.match(pt_color));
      const sizeIndex = headerSplit.findIndex((i) => !!i.match(pt_size));
      const skuIndex = headerSplit.findIndex((i) => !!i.match(pt_sku));
      const costIndex = headerSplit.findIndex((i) => !!i.match(pt_cost));
      const shippingIndex = header2Split.findIndex(
        (i) => !!i.match(pt_shipping),
      );
      content = content.filter(Boolean);

      if (content.length === 0) {
        reject("content is empty");
      }

      const [newVariants, newAttributes] = getVariants({
        typeIndex,
        colorIndex,
        sizeIndex,
        skuIndex,
        costIndex,
        shippingIndex,
        rowsStr: content,
      });
      resolve({ variants: newVariants, attributes: newAttributes });
    };
    reader.readAsArrayBuffer(file);
  });
}

function replacePrefix(target, prefix = "\\$") {
  if (typeof target !== 'string') return target 
  const reg = new RegExp(prefix);
  return (target || "").replace(reg, "");
}

function newAttr({ model, attrMap, val, attrs }) {
  const slug = model.slug;
  attrs.push(newAttrObj(model, val));
  pushAttr(attrMap, slug, val);
}

function newAttrObj(model, val) {
  /**
   * model: { name, slug }
   */
  return {
    name: model.name,
    slug: model.slug,
    option: val,
  };
}

function pushAttr(attrMap, slug, val) {
  /**
   * attrMap: {}
   */
  const attCache = attrMap[slug];
  if (!attCache) attrMap[slug] = new Set();
  attrMap[slug].add(val);
}

function getPrice(cost, shipCost) {
  const count =
    Number(replacePrefix(cost) || 0) + Number(replacePrefix(shipCost) || 0);
  const sellerPrice = count.toFixed(2);
  const regularPrice = (sellerPrice * 3).toFixed(2);

  return {
    sellerPrice: +sellerPrice,
    regularPrice: +regularPrice,
  };
}

function getVariants({
  typeIndex,
  colorIndex,
  sizeIndex,
  skuIndex,
  costIndex,
  shippingIndex,
  rowsStr,
}) {
  if (rowsStr.length == 0) return [];

  const attrMap = {};
  const newVariants = [];
  for (let i = 0; i < rowsStr.length; i++) {
    const line = rowsStr[i];

    if (!line || typeof line !== "string") continue;

    const rows = line.split(",");
    const typeVal = rows[typeIndex];
    const colorVal = rows[colorIndex];
    const sizeVal = rows[sizeIndex];
    const skuVal = rows[skuIndex];

    const costVal = rows[costIndex];
    const shippingVal = rows[shippingIndex];

    const attrs = [];
    if (typeVal) {
      const model = ATTR_MODEL.type;
      newAttr({ model, attrMap, val: typeVal, attrs });
    }

    if (colorVal) {
      const model = ATTR_MODEL.color;
      newAttr({ model, attrMap, val: colorVal, attrs });
    }

    if (sizeVal) {
      const model = ATTR_MODEL.size;
      newAttr({ model, attrMap, val: sizeVal, attrs });
    }

    const { sellerPrice, regularPrice } = getPrice(costVal, shippingVal);
    newVariants.push({
      sorting: i,
      attributes: attrs,
      regularPrice: +regularPrice,
      sellerPrice: +sellerPrice,
      salePrice: 0,
      fulfillmentProductId: skuVal,
    });
  }

  const newAttributes = [];
  if (Object.keys(attrMap).length > 0) {
    for (let [key, value] of Object.entries(attrMap)) {
      const name = key.charAt(0).toUpperCase() + key.slice(1).toLowerCase();
      const arrVal = Array.from(value);
      newAttributes.push({ name, slug: key, options: arrVal });
    }
  }

  return [newVariants, newAttributes];
}


function getVariantsByKey({
  typeKey,
  colorKey,
  sizeKey,
  skuKey,
  costKey,
  shippingKey,
  rowsStr,
}) {
  if (rowsStr.length == 0) return [];

  const attrMap = {};
  const newVariants = [];
  for (let i = 0; i < rowsStr.length; i++) {
    const row = rowsStr[i];
    if (!row || typeof row !== "object") continue;


    const typeVal = row[typeKey];
    const colorVal = row[colorKey];
    const sizeVal = row[sizeKey];
    const skuVal = row[skuKey];

    const costVal = row[costKey];
    const shippingVal = row[shippingKey];

    const attrs = [];
    if (typeVal) {
      const model = ATTR_MODEL.type;
      newAttr({ model, attrMap, val: typeVal, attrs });
    }

    if (colorVal) {
      const model = ATTR_MODEL.color;
      newAttr({ model, attrMap, val: colorVal, attrs });
    }

    if (sizeVal) {
      const model = ATTR_MODEL.size;
      newAttr({ model, attrMap, val: sizeVal, attrs });
    }

    const { sellerPrice, regularPrice } = getPrice(costVal, shippingVal);
    newVariants.push({
      sorting: i,
      attributes: attrs,
      regularPrice: +regularPrice,
      sellerPrice: +sellerPrice,
      salePrice: 0,
      fulfillmentProductId: skuVal,
    });
  }

  const newAttributes = [];
  if (Object.keys(attrMap).length > 0) {
    for (let [key, value] of Object.entries(attrMap)) {
      const name = key.charAt(0).toUpperCase() + key.slice(1).toLowerCase();
      const arrVal = Array.from(value);
      newAttributes.push({ name, slug: key, options: arrVal });
    }
  }

  return [newVariants, newAttributes];
}