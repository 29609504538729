import React from "react";

// MB_TT_PRODUCT_STORE_ID, MB_TT_ITEM_ID, MB_TT_ITEM_TITLE, MB_TT_PUSH_ID, MB_TT_PUSH_TITLE, MB_RANDOM_ID
const descHelpText = {
  MB_TT_PRODUCT_STORE_ID: {
    key: "MB_TT_PRODUCT_STORE_ID",
    value: "ID of item created by the Push",
  },
  MB_TT_ITEM_ID: {
    key: "MB_TT_ITEM_ID",
    value:
      'ID of Product Crawl Item if Push\'s Product source is "Using Tiktok crawl products", ID of MerchBridge product if Push\'s Product source is "Using MerchBridge products"',
  },
  MB_TT_ITEM_TITLE: {
    key: "MB_TT_ITEM_TITLE",
    value:
      'Title of Product Crawl Item if Push\'s Product source is "Using Tiktok crawl products", Title of MerchBridge product if Push\'s Product source is "Using MerchBridge products"',
  },
  MB_TT_PUSH_ID: {
    key: "MB_TT_PUSH_ID",
    value: "ID of the Push",
  },
  MB_TT_PUSH_TITLE: {
    key: "MB_TT_PUSH_TITLE",
    value: "Title of the Push",
  },
  MB_RANDOM_ID: {
    key: "MB_RANDOM_ID",
    value: "The string is randomly generated from characters or numbers",
  },
};

export function DescriptionHelpText() {
  return (
    <ul style={{ paddingLeft: 16, marginBottom: 0 }}>
      {Object.keys(descHelpText).map((key) => {
        const ob = descHelpText[key];
        return (
          <li key={key}>
            <span>
              <strong>{ob.key}: </strong>
              {ob.value}
            </span>
          </li>
        );
      })}
    </ul>
  );
}
