import { useMutation } from "@apollo/react-hooks";
import { ChoiceList, Modal, Stack } from "@shopify/polaris";
import React, { useState } from "react";

import { TIKTOK_FETCH_PRODUCTS_BY_STORE } from "../../../graphql/mutations";
import { handleError } from "../../../helper";
import { useToastContext } from "../../shared/ToastContext";

export default function FetchProducts({ open, onClose, store }) {
  const [needUpdate, setNeedUpdate] = useState([false]);
  const [fetchProductsByStore, { loading }] = useMutation(
    TIKTOK_FETCH_PRODUCTS_BY_STORE,
  );

  const { toggleToast, setNotify } = useToastContext();

  const handleSubmit = React.useCallback(() => {
    if (!store.id) return;

    toggleToast && toggleToast();
    setNotify && setNotify({ err: false, msg: null });

    fetchProductsByStore({
      variables: {
        storeID: store.id,
        needUpdate: needUpdate?.length > 0 ? needUpdate[0] : undefined,
      },
    })
      .then(() => {
        setNotify({ err: false, msg: "Fetch products success" });
      })
      .catch((err) => {
        setNotify({ err: true, msg: handleError(err.toString()) });
      })
      .finally(() => {
        onClose && onClose();
      });
  }, [
    fetchProductsByStore,
    store.id,
    onClose,
    toggleToast,
    setNotify,
    needUpdate,
  ]);

  return (
    <Modal
      title="Fetch products"
      sectioned
      open={open}
      onClose={onClose}
      primaryAction={{ content: "Fetch", onAction: handleSubmit, loading }}
      secondaryActions={[{ content: "Cancel", onAction: onClose }]}
    >
      <Stack vertical>
        <p>
          Are you sure to fetch products from store{" "}
          <strong>{store.name}</strong>
        </p>

        <ChoiceList
          choices={[
            { value: false, label: "Only create new product" },
            { value: true, label: "Create / update product" },
          ]}
          selected={needUpdate}
          onChange={setNeedUpdate}
        />
      </Stack>
    </Modal>
  );
}
