import React, { useState, useCallback, useEffect, useRef } from "react";
import { Filters } from "@shopify/polaris";
import { useQuery } from "@apollo/react-hooks";

import { isEmpty } from "../../helper";
import { FilterHasSearchPolaris } from "../filters/FilterHasSearchPolaris";
import { gql } from "apollo-boost";
import { get } from "lodash";
import { FilterHasSearchMVPolaris } from "../filters/FilterHasSearchMVPolaris";

const PRODUCT_BASES = gql`
  query productBasesForSeller($filter: ProductBaseFilter) {
    productBasesForSeller(filter: $filter) {
      total
      nodes {
        id
        title
      }
    }
  }
`;

const QUERY_DIVISIONS = gql`
  query listProductDivision($filter: DivisionFilter) {
    listProductDivision(filter: $filter) {
      total
      nodes {
        id
        title
      }
    }
  }
`;

export const RecreateMockupFilterPolaris = ({ filter, onChange }) => {
  // State
  const [queryValue, setQueryValue] = useState(filter.search);

  const [productBases, setProductBases] = useState([]);
  const [productBase, setProductBase] = useState({
    value: filter.productBaseIds ? filter.productBaseIds : [],
    label: [],
    search: null,
  });
  const [divisions, setDivisions] = useState([]);
  const [division, setDivision] = useState({
    value: filter.divisionId ? filter.divisionId : null,
    label: null,
    search: null,
  });

  const typingTimeoutRef = useRef(null);

  // Queries
  const { loading: loadingBase, error: errorBase } = useQuery(PRODUCT_BASES, {
    onCompleted: (res) => {
      handleProductBaseData(res);
    },
    onError: () => {},
    variables: {
      filter: {
        limit: 20,
        offset: 0,
        search: productBase.search,
      },
    },
  });
  const { loading: loadingDivision, error: errorDivision } = useQuery(
    QUERY_DIVISIONS,
    {
      variables: {
        filter: {
          limit: 20,
          offset: 0,
          search: division.search,
        },
      },
      onCompleted: (res) => {
        handleDivisionData(res);
      },
      onError: () => {},
    },
  );

  const handleProductBaseData = useCallback((data) => {
    let newData = getBases(data);
    setProductBases(newData);
  }, []);

  const handleDivisionData = useCallback((data) => {
    let newData =
      data?.listProductDivision?.nodes?.length > 0
        ? data.listProductDivision.nodes.map((d) => ({
            value: d.id,
            label: d.title,
          }))
        : [];
    setDivisions(newData);
  }, []);

  useEffect(() => {
    // Product base
    let pBaseIds = filter?.productBaseIds;
    if (pBaseIds?.length) {
      let baseMatched =
        productBases?.length > 0
          ? productBases.filter((b) => pBaseIds.includes(b.value))
          : [];

      if (baseMatched.length) {
        setProductBase((prev) => ({
          ...prev,
          value: baseMatched.map((b) => b.value),
          label: baseMatched.map((b) => b.label),
        }));
      }
    }

    // Product division
    let divisionId = filter?.divisionId;
    if (divisionId) {
      let divisionMatched =
        divisions?.length > 0
          ? divisions.find((b) => [divisionId].includes(b.value))
          : [];
      if (divisionMatched) {
        setDivision((prev) => ({
          ...prev,
          value: divisionMatched.value,
          label: divisionMatched.label,
        }));
      }
    }
  }, [filter, productBases, divisions]);

  // Handle actions
  const handleQueryValueChange = useCallback((value) => {
    value = value ? value : null;
    setQueryValue(value);
  }, []);

  const handleQueryValueRemove = useCallback(() => setQueryValue(null), []);
  const handleProductBaseRemove = useCallback(
    () => setProductBase(() => ({ value: [], label: [], search: null })),
    [],
  );
  const handleDivisionRemove = useCallback(
    () => setDivision(() => ({ value: null, label: null, search: null })),
    [],
  );

  const handleFilterClears = [
    handleQueryValueRemove,
    handleProductBaseRemove,
    handleDivisionRemove,
  ];
  const handleFilterClearAll = useCallback(() => {
    for (let fn of handleFilterClears) {
      if (fn) {
        fn();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...handleFilterClears]);

  useEffect(() => {
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }
    typingTimeoutRef.current = setTimeout(() => {
      if (onChange) {
        onChange({
          search: queryValue ? queryValue.trim() : queryValue,
          productBaseIds:
            productBase?.value?.length > 0 ? productBase.value : null,
          divisionId: division.value,
        });
      }
    }, 300);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryValue, productBase, division]);

  // Markup
  const filters = [
    {
      key: "productBase",
      label: "Product bases",
      filter: (
        // <FilterNoSearchMVPolaris
        <FilterHasSearchMVPolaris
          options={productBases}
          value={productBase}
          loading={loadingBase}
          error={errorBase}
          onChange={({ value, label }) =>
            setProductBase((prev) => ({
              ...prev,
              value: value ? value : [],
              label,
            }))
          }
          onChangeSearch={(search) =>
            setProductBase((prev) => ({ ...prev, search }))
          }
        />
      ),
    },
    {
      key: "division",
      label: "Product divisions",
      filter: (
        <FilterHasSearchPolaris
          options={divisions}
          value={division}
          loading={loadingDivision}
          erorr={errorDivision}
          onChange={({ value, label }) =>
            setDivision((prev) => ({ ...prev, value, label }))
          }
          onChangeSearch={(search) =>
            setDivision((prev) => ({ ...prev, search }))
          }
        />
      ),
    },
  ];
  const appliedFilters = [];
  if (productBase.label.length) {
    const key = "productBase";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, productBase.label),
      onRemove: handleProductBaseRemove,
    });
  }
  if (!isEmpty(division.label)) {
    const key = "division";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, division.label),
      onRemove: handleDivisionRemove,
    });
  }

  return (
    <Filters
      filters={filters}
      appliedFilters={appliedFilters}
      queryValue={queryValue}
      queryPlaceholder={"Filter item"}
      onQueryChange={handleQueryValueChange}
      onQueryClear={handleQueryValueRemove}
      onClearAll={handleFilterClearAll}
    />
  );

  function disambiguateLabel(key, value) {
    switch (key) {
      case "productBase":
        let pBaseLabel = value && value.length > 0 ? value.join(", ") : "";
        return `Product bases: ${pBaseLabel}`;
      case "division":
        return `Product divisions: ${value}`;
      default:
        return value;
    }
  }
};

function getBases(data) {
  const nodes = get(data, "productBasesForSeller.nodes") || [];
  return nodes
    .map((i) => (i ? { value: i.id, label: i.title } : null))
    .filter(Boolean);
}
