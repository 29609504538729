import {
  Button,
  ButtonGroup,
  Card,
  ChoiceList,
  Modal,
  Stack,
  TextField,
  TextStyle,
} from "@shopify/polaris";
import React from "react";
import { useMutation } from "@apollo/react-hooks";

import {
  arrInvalid,
  handleError,
  hasError,
  reducerFn,
  validateField,
  validateFields,
} from "../../../../helper";
import TiktokProductTemplatesSelect from "../../push-products/TiktokProductTemplatesSelect";
import StoreSelect from "../../../store/components/StoreSelect";
import { ComponentLabelPolaris } from "../../../shared/ComponentLabelPolaris";
import { useSelectRowsCtx } from "../../../shared/SelectRow";
import { CREATE_TIKTOK_QUICK_PUSH } from "../../../../graphql/mutations";
import { useToastContext } from "../../../shared/ToastContext";
import GroupSelect from "../../../group/components/group-select";

function QuickPush({ open, onClose }) {
  const { ids, onCompleted } = useSelectRowsCtx();

  const [state, setState] = React.useReducer(reducerFn, {
    storeIDs: [],
    tiktokProductTemplateID: null,
    title: "",
    titlePrefix: "",
    titleSuffix: "",
    mainImageIndex: [1],
    groupIDs: [],
    isStore: false,

    errors: {},
  });

  const { setNotify, toggleToast } = useToastContext();

  const [createQuickPush, { loading }] = useMutation(CREATE_TIKTOK_QUICK_PUSH, {
    onCompleted: () => {
      setNotify({
        msg: "The new push has been added successfully",
        err: false,
      });
      onCompleted && onCompleted();
    },
    onError: (error) => {
      setNotify({ msg: handleError(error?.toString()), err: true });
    },
  });

  const handleStateChanged = React.useCallback(
    (id) => (value, _id) => {
      let mId = id ? id : _id;
      setState((p) => updateState(p, value, mId));
    },
    [],
  );

  const handleStoreChange = React.useCallback((val) => {
    let newVal = val;
    if (newVal && typeof newVal === "string") {
      newVal = [newVal];
    }
    handleStateChanged("storeIDs")(newVal);
  }, []);

  const handleGroupChange = React.useCallback((val) => {
    let newVal = val;
    if (newVal && typeof newVal === "string") {
      newVal = [newVal];
    }
    handleStateChanged("groupIDs")(newVal);
  }, []);

  const handleSubmit = React.useCallback(() => {
    if (arrInvalid(ids)) return;
    const {
      storeIDs,
      tiktokProductTemplateID,
      titlePrefix,
      titleSuffix,
      mainImageIndex,
      groupIDs,
      isStore,
      title,
    } = state;

    let fr = {
      title: FIELD_REQUIRED.title,
      tiktokProductTemplateID: FIELD_REQUIRED.tiktokProductTemplateID,
    };

    let val = {
      tiktokProductTemplateID,
      title,
    };

    const input = {
      title,
      productCrawlIDs: ids,
      tiktokProductTemplateID,
      titlePrefix,
      titleSuffix,
    };

    if (isStore) {
      fr.storeIDs = FIELD_REQUIRED.storeIDs;
      val.storeIDs = storeIDs;
      input.storeIDs = storeIDs;
    } else {
      fr.groupIDs = FIELD_REQUIRED.groupIDs;
      val.groupIDs = groupIDs;
      input.groupID = groupIDs[0];
    }
    const errors = validateFields(val, fr);
    setState({ errors });

    const hasErr = hasError(errors);
    if (hasErr) return;
    toggleToast && toggleToast(true);
    setNotify && setNotify({ msg: null, err: false });

    if (mainImageIndex?.length > 0) {
      input.mainImageIndex = mainImageIndex[0];
    }

    createQuickPush({
      variables: { input },
    });
  }, [state, ids, createQuickPush, setNotify, toggleToast]);

  const toggleStore = React.useCallback(
    (val) => () => {
      setState({ isStore: val });
    },
    [],
  );

  return (
    <Modal
      sectioned
      title="Quick Push"
      open={open}
      onClose={onClose}
      primaryAction={{ content: "Submit", onAction: handleSubmit, loading }}
      secondaryActions={[{ content: "Cancel", onAction: onClose }]}
    >
      <Card sectioned title="Genernal">
        <Stack vertical>
          <div>
            <ComponentLabelPolaris label="Title" required />
            <TextField
              id="title"
              onChange={handleStateChanged()}
              placeholder="Enter title "
              value={state.title || ""}
              error={state.errors["title"] || null}
            />
          </div>
          <div>
            <ButtonGroup segmented>
              <Button
                primary={!state.isStore}
                children="Choose Group"
                onClick={toggleStore(false)}
                size="slim"
              />
              <Button
                primary={state.isStore}
                children="Choose Store"
                onClick={toggleStore(true)}
                size="slim"
              />
            </ButtonGroup>
          </div>
          {!!state.isStore ? (
            <div>
              <ComponentLabelPolaris label="Target stores" required />
              <StoreSelect
                allowMultiple
                labelHidden={true}
                value={state?.storeIDs}
                onChange={handleStoreChange}
                filter={{ platform: "tiktok" }} // Only allow select Tiktok store
                error={state.errors["storeIDs"] || null}
              />
            </div>
          ) : (
            <div>
              <ComponentLabelPolaris label="Target group" required />
              <GroupSelect
                labelHidden
                value={state?.groupIDs}
                onChange={handleGroupChange}
                error={state.errors["groupIDs"] || null}
              />
            </div>
          )}

          <div>
            <TiktokProductTemplatesSelect
              required
              label="Tiktok product template"
              onChange={handleStateChanged("tiktokProductTemplateID")}
              filters={{
                must_has_excel_template_file: false,
              }}
              value={state?.tiktokProductTemplateID}
              error={state.errors["tiktokProductTemplateID"] || null}
            />
            <TextStyle variation="subdued">
              This template will be applied to MerchBridge products when pushing
              them to TikTok.
            </TextStyle>
          </div>
        </Stack>
      </Card>
      <Card sectioned title="Extra settings">
        <Stack vertical>
          <TextField
            label="Title Prefix"
            id="titlePrefix"
            onChange={handleStateChanged()}
            placeholder="Enter title prefix"
            value={state.titlePrefix || ""}
          />
          <TextField
            label="Title Suffix"
            id="titleSuffix"
            onChange={handleStateChanged()}
            placeholder="Enter title suffix"
            value={state.titleSuffix || ""}
          />

          <ChoiceList
            title="Which image index do you want to set as the main image?"
            name="mainImageIndex"
            choices={[
              { value: 1, label: "Main image" },
              { value: 2, label: "Image 2" },
              { value: 3, label: "Image 3" },
            ]}
            selected={state.mainImageIndex || []}
            onChange={handleStateChanged()}
          />
        </Stack>
      </Card>
    </Modal>
  );
}

const FIELD_REQUIRED = {
  title: "Please enter Titlte",
  storeIDs: "Please choose at least one store",
  groupIDs: "Please choose at least one group",
  tiktokProductTemplateID: "Please choose a product template",
};

function updateState(state, value, id) {
  let err = validateField(value, id, FIELD_REQUIRED);
  return {
    ...state,
    [id]: value,
    errors: {
      ...state.errors,
      [id]: err,
    },
  };
}

export default QuickPush;
