import get from "lodash/get";
import { arrInvalid, objectInvalid } from "../../../helper";
import { FULFILLMENTS_SLUG, WAREHOUSE_VALUES } from "../../../constants";
import { META_DATA_KEY_MB } from "../../../variable";

export function getVariantsByWarehouse(warehouses, variants) {
  if (arrInvalid(variants) || arrInvalid(warehouses)) return variants;

  // validate warehouse
  const newWh = warehouses.filter((item) => WAREHOUSE_VALUES.includes(item));
  if (arrInvalid(newWh)) return variants;

  const mapVariants = [];
  for (let va of variants) {
    if (
      objectInvalid(va) ||
      !va.fulfillmentWarehouses ||
      arrInvalid(va.fulfillmentWarehouses)
    )
      continue;

    if (va.fulfillmentWarehouses.some((item) => warehouses.includes(item))) {
      mapVariants.push(va);
    }
  }

  return mapVariants;
}

export function mapVariantsByWarehouseByBase(selectedItem) {
  const variants = get(selectedItem, "variants");

  const newVariants = [];

  for (let va of variants) {
    const bVariant = get(va, "productBaseVariant");
    const fulfillmentWarehouses = get(bVariant, "fulfillmentWarehouses");
    if (!arrInvalid(fulfillmentWarehouses)) {
      va.fulfillmentWarehouses = fulfillmentWarehouses;
    }

    newVariants.push(va);
  }

  return newVariants;
}

export function getActiveVariants(newValues, key) {
  key = key || "productBaseVariantId";
  const s = new Set();
  for (let item of newValues) {
    if (!item || !item[key]) continue;
    s.add(item[key]);
  }

  return Array.from(s);
}

export function getAttributeState(data) {
  const { activeVariants, simpleChecked, groupChecked, groupItems } = data;
  let newState = {
    activateVariants: [...activeVariants],
    simpleChecked: !arrInvalid(activeVariants),
  };

  if (undefined !== simpleChecked) {
    newState.simpleChecked = simpleChecked;
  }

  if (undefined !== groupChecked) {
    newState.groupChecked = groupChecked;
  }

  if (undefined !== groupItems) {
    newState.groupItems = groupItems;
  }

  return newState;
}

const pt = new RegExp(META_DATA_KEY_MB, "i");
export function getMetaData(item) {
  const metaData = get(item, "metaData");
  if (arrInvalid(metaData)) return [];

  const res = [];
  for (let item of metaData) {
    if (!item || !item.key || !item.key.match(pt)) continue;
    if (!item.value || arrInvalid(item.value)) continue;

    for (let val of item.value) {
      res.push({ name: val.key, value: val.value });
    }
  }
  return res;
}

export function showCarrierSelect(fulfillment) {
  // case: MB fuflillment
  if (!fulfillment) return true;
  return fulfillment.slug === FULFILLMENTS_SLUG.CustomFulfillment;
}
