import React, { useState, useCallback, useEffect, useRef } from "react";
import { Autocomplete, Tag } from "@shopify/polaris";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";
import styled from "styled-components";
import _ from "lodash";

import { getBaseTitle, getUnique, isPME_TEAM, reducerFn } from "../../helper";
import { ComponentLabelPolaris } from "../shared/ComponentLabelPolaris";
import { useAppContext } from "../../context";
import useTimeout from "../../hooks/useTimeout";

const Container = styled.p`
  flex: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const TagContainer = styled.div`
  display: flex;
  padding-top: 0.8rem;
  flex-wrap: wrap;
  > * {
    margin-right: 0.8rem;
    margin-bottom: 0.8rem;
  }
`;

export const PRODUCT_BASES = gql`
  query productBasesForSeller($filter: ProductBaseFilter) {
    productBasesForSeller(filter: $filter) {
      total
      nodes {
        id
        title
      }
    }
  }
`;

export const ProductBaseSelectPolaris = ({
  onChange,
  allowMultiple,
  value,
  labelHidden,
  required,
  error,
  getCoupleValue,
  setBaseSelected,
}) => {
  const [inputValue, setInputValue] = useState(null);
  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [deselectedOptions, setDeselectedOptions] = useState([]);
  const [deselectedOptionsShow, setDeselectedOptionsShow] = useState([]);

  const [filter, setFilter] = React.useReducer(reducerFn, {
    limit: 20,
    offset: 0,
    search: inputValue,
  });

  const { data, loading } = useQuery(PRODUCT_BASES, {
    variables: {
      filter,
    },
  });
  const onChangeTimeout = useRef(null);

  // Context
  const { currentUser } = useAppContext();
  const isPMETeam = isPME_TEAM(currentUser);

  useEffect(() => {
    const newData =
      data?.productBasesForSeller?.nodes?.length > 0
        ? data.productBasesForSeller.nodes.map(genBase(isPMETeam)).map((pb) => {
            return {
              value: pb.id,
              label: <Container>{pb.title}</Container>,
            };
          })
        : [];
    setOptions(newData);
    setDeselectedOptions(newData);
  }, [data]);

  useEffect(() => {
    let newValue = allowMultiple ? value : [value];
    setSelectedOptions(newValue);
    let newData = _.get(data, "productBasesForSeller.nodes", []);
    let couple = mapSelectedBase(newData, value, isPMETeam);

    let result;
    setDeselectedOptionsShow((prevState) => {
      result = getUnique([...prevState, ...couple], "id");
      return result;
    });

    onChangeTimeout.current && clearTimeout(onChangeTimeout.current);
    onChangeTimeout.current = setTimeout(() => {
      if (getCoupleValue && setBaseSelected) {
        setBaseSelected(result);
      }
    }, 100);
  }, [value, allowMultiple, data, setBaseSelected, getCoupleValue]);

  const [delay] = useTimeout();
  const handleInputChange = useCallback(
    (value) => {
      setInputValue(value);
      // if ("" === value) {
      //   setOptions(deselectedOptions);
      //   return;
      // }

      // const filterRegex = new RegExp(value, "i");
      // const resultOptions = deselectedOptions.filter((option) =>
      //   _.get(option, "label.props.children").match(filterRegex)
      // );
      // setOptions(resultOptions);

      delay(() => {
        setFilter({ search: value });
      });
    },
    [deselectedOptions],
  );

  const handleSelectedChange = useCallback(
    (selected) => {
      const selectedValue = selected.map((selectedItem) => {
        const matchedOptions = options.find((option) => {
          return option.value === selectedItem;
        });
        return matchedOptions && _.get(matchedOptions, "label.props.children");
      });
      setSelectedOptions(selected);

      let newData = _.get(data, "productBasesForSeller.nodes", []);
      let couple = mapSelectedBase(newData, selected, isPMETeam);

      let newResult = [];
      setDeselectedOptionsShow((prevState) => {
        let result = getUnique([...prevState, ...couple], "id");
        result = result.filter((i) => selected.includes(i.id));
        newResult = result;
        return result;
      });

      onChangeTimeout.current && clearTimeout(onChangeTimeout.current);
      onChangeTimeout.current = setTimeout(() => {
        if (onChange) {
          if (allowMultiple) {
            onChange(getCoupleValue ? newResult : selected);
            return;
          } else {
            onChange(_.head(selected));
          }
        }
      }, 100);
      setInputValue(_.head(selectedValue));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [options, data],
  );

  const handleRemove = useCallback(
    (value) => {
      let newSelected = selectedOptions.filter((s) => s !== value);
      setSelectedOptions(newSelected);
      let newDeselected = deselectedOptionsShow.filter(
        (item) => item.id !== value,
      );
      setDeselectedOptionsShow(newDeselected);
      if (onChange && allowMultiple) {
        onChange(newSelected);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedOptions, deselectedOptionsShow],
  );

  const textField = (
    <Autocomplete.TextField
      label="Product bases"
      labelHidden={true}
      placeholder="Search for product base"
      value={inputValue}
      onChange={handleInputChange}
      error={error}
    />
  );

  return (
    <>
      {!labelHidden ? (
        <ComponentLabelPolaris label="Product bases" required={required} />
      ) : null}
      <Autocomplete
        options={options}
        selected={selectedOptions}
        onSelect={handleSelectedChange}
        textField={textField}
        loading={loading}
        allowMultiple={allowMultiple}
        emptyState={<span>No items found!</span>}
      />
      {allowMultiple && (
        <TagContainer>
          {deselectedOptionsShow && deselectedOptionsShow.length > 0
            ? deselectedOptionsShow.map((item) => (
                <Tag
                  children={item.title}
                  onRemove={() => handleRemove(item.id)}
                  key={item.id}
                />
              ))
            : null}
        </TagContainer>
      )}
    </>
  );
};

/**
 * Utils
 */
function genBase(isPMETeam) {
  return (item) => {
    if (!item || typeof item !== "object") return item;
    const title = isPMETeam ? item.title : getBaseTitle(item);
    return {
      ...item,
      title,
    };
  };
}

function mapSelectedBase(bases, selected, isPMETeam) {
  if (
    !bases ||
    !Array.isArray(bases) ||
    bases.length === 0 ||
    !selected ||
    !Array.isArray(selected)
  )
    return [];

  return bases.map(genBase(isPMETeam)).filter((b) => selected.includes(b.id));
}
