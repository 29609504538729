import { gql } from "apollo-boost";

export const GET_TIKTOK_PRODUCTS_SYNCED = gql`
  query getTiktokProductsSynced($filter: GetTiktokProductsSyncedFilter!) {
    getTiktokProductsSynced(filter: $filter) {
      total
      nodes {
        originID
        title
        status
        id
        isLock
        inPromotion
      }
    }
  }
`;

export const SYNCED_TIKTOK_PRODUCT_TODAY = gql`
  query syncedTiktokProductToday($storeID: String!) {
    syncedTiktokProductToday(storeID: $storeID)
  }
`;

export const GET_TIKTOK_PROMOTIONS = gql`
  query getTiktokPromotions($filter: TiktokPromotionFilter!) {
    getTiktokPromotions(filter: $filter) {
      total
      nodes {
        id
        createdAt
        title
        activityID
        storeID
        activityType
        productLevel
        autoRenew
        beginTime
        endTime
        status
        timezone
        store {
          id
          title
        }
        author {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

export const GET_TIKTOK_PROMOTION_PRODUCTS = gql`
  query getTiktokPromotionProducts($filter: TiktokPromotionProductFilter!) {
    getTiktokPromotionProducts(filter: $filter) {
      total
      nodes {
        id
        title
        originProductID
        discount
        quantityLimit
        quantityPerUser
        status
        errorMessage
      }
    }
  }
`;

export const GET_TIKTOK_PRODUCT_VIA_ORDER = gql`
  query getTiktokProductViaOrder($orderIDs: [String!]!) {
    getTiktokProductViaOrder(orderIDs: $orderIDs) {
      orderID
      productID
      productTitle
    }
  }
`;

export const GET_TIKTOK_CATEGORIES = gql`
  fragment Cat on TiktokCategory {
    id
    localName
    parentID
    isLeaf
    permissionStatuses
  }

  query getTiktokCategories($storeID: String!) {
    getTiktokCategories(storeID: $storeID) {
      ...Cat
      children {
        ...Cat
        children {
          ...Cat
          children {
            ...Cat
          }
        }
      }
    }
  }
`;

export const GET_TIKTOK_ATTRIBUTE_BY_CATEGORY = gql`
  query getTiktokAttributeByCategory($categoryID: String!, $storeID: String!) {
    getTiktokAttributeByCategory(categoryID: $categoryID, storeID: $storeID) {
      id
      isRequried
      isCustomizable
      isMultipleSelection
      name
      values {
        id
        name
      }
    }
  }
`;

export const GET_TIKTOK_BRANDS = gql`
  query getTiktokBrands($input: GetTiktokBrandFilter!) {
    getTiktokBrands(input: $input) {
      message
      data {
        brands {
          id
          name
          authorizedStatus
          isT1Brand
          brandStatus
        }
        totalCount
        nextPageToken
      }
    }
  }
`;

export const GET_TIKTOK_IMAGE_TEMPLATES = gql`
  query getTiktokImageTemplates($filter: TiktokImageTemplateFilter!) {
    getTiktokImageTemplates(filter: $filter) {
      total
      nodes {
        id
        createdAt
        title
        configs
      }
    }
  }
`;

export const GET_TIKTOK_IMAGE_TEMPLATE = gql`
  query getTiktokImageTemplate($ID: ID!) {
    getTiktokImageTemplate(ID: $ID) {
      id
      createdAt
      title
      configs
    }
  }
`;

export const GET_TIKTOK_IMAGE_TEMPLATES_BY_ID = gql`
  query getTiktokImageTemplatesByID($ids: [ID!]!) {
    getTiktokImageTemplatesByID(ids: $ids) {
      id
      createdAt
      title
      configs
    }
  }
`;

export const GET_TIKTOK_CRAWL_PRODUCT_PUSHED_TO_STORE = gql`
  query getTiktokCrawlProductPushedToStore($productIDs: [ID!]!) {
    getTiktokCrawlProductPushedToStore(productIDs: $productIDs)
  }
`;
