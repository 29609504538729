import { Button, Form, Input, Modal, notification } from "antd";
import React from "react";
import { handleError, reducerFn } from "../../../helper";
import { useMutation } from "@apollo/react-hooks";
import { CREATE_FULFILLMENT_PRODUCT } from "../../../graphql/mutations";

function FulfillmentProductCreate({ fulfillmentId, onChange }) {
  const [state, setState] = React.useReducer(reducerFn, {
    open: false,
    title: "",
    originID: "",
    description: "",
  });

  const [form] = Form.useForm();

  const [createFulfillmentProduct, { loading }] = useMutation(
    CREATE_FULFILLMENT_PRODUCT,
    {
      onCompleted: (res) => {
        handleOpenChange(false)()
        if (res?.createFulfillmentProduct) {
          onChange && onChange(res.createFulfillmentProduct)
        }
        notification.success({ message: "Create fulfillment product success" });
      },
      onError: (err) => {
        notification.error({ message: handleError(err?.toString() || "") });
      },
    },
  );

  const handleOpenChange = React.useCallback(
    (value) => () => {
      setState({ open: value });
    },
    [],
  );

  const handleSubmit = React.useCallback(
    (values) => {
      if (!fulfillmentId) return;
      createFulfillmentProduct({
        variables: {
          input: {
            ...values,
            fulfillmentID: fulfillmentId,
          },
        },
      });
    },
    [fulfillmentId, createFulfillmentProduct],
  );

  return (
    <div style={{ marginTop: '1rem'}}>
      <Button onClick={handleOpenChange(true)}>
        Create Fulfillment Product
      </Button>

      <Modal
        title="Create Fulfillment Product"
        open={!!state.open}
        onCancel={handleOpenChange(false)}
        footer={false}
      >
        <Form
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          form={form}
          initialValues={{ title: "", originID: "", description: "" }}
          onFinish={handleSubmit}
        >
          <Form.Item
            name="title"
            label="Title"
            rules={[{ required: true, message: "Title is required" }]}
          >
            <Input placeholder="Enter fulfillment product title" />
          </Form.Item>
          <Form.Item
            name="originID"
            label="Origin ID"
            rules={[{ required: true, message: "Origin ID is required" }]}
          >
            <Input placeholder="Enter fulfillment product orirign id" />
          </Form.Item>
          <Form.Item name="description" label="Description">
            <Input.TextArea placeholder="Enter fulfillment product description" />
          </Form.Item>

          <Form.Item noStyle>
            <Button htmlType="submit" type="primary" loading={loading}>
              Create
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}

export default FulfillmentProductCreate;
