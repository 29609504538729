import { useMutation } from "@apollo/react-hooks";
import { Modal } from "@shopify/polaris";
import React, { useCallback, useEffect } from "react";
import { ADMIN_RECREATE_ORDER_FULFILLMENT_V2 } from "../../../graphql/mutations";
import { handleError } from "../../../helper";
import { useToastContext } from "../../shared/ToastContext";

export const ReSentOrderPolaris = ({
  open,
  toggleShowModal,
  value,
  refetch,
  changeStatusCount,
  convertOS,
}) => {
  const orderId = value?.id;

  const { setNotify, toggleToast } = useToastContext();

  const timer = React.useRef(null);
  const [adminRecreateOrderFulfillment, { loading }] = useMutation(
    ADMIN_RECREATE_ORDER_FULFILLMENT_V2,
    {
      onError: (err) => {
        setNotify({ msg: handleError(err?.toString()), err: true });
      },
      onCompleted: () => {
        setNotify({ msg: "Re-submit order success.", err: false });
        timer.current && clearTimeout(timer.current);
        timer.current = setTimeout(() => {
          toggleShowModal && toggleShowModal(false);
          changeStatusCount &&
            changeStatusCount(convertOS && [convertOS.Fulfilled]);
          refetch && refetch();
        }, 2100);
      },
    },
  );

  const handleSubmit = useCallback(() => {
    if (orderId) {
      toggleToast && toggleToast(true);
      setNotify && setNotify({ msg: null, err: false });
      adminRecreateOrderFulfillment({
        variables: {
          orderId,
        },
      });
    }
  }, [orderId, toggleToast, setNotify, adminRecreateOrderFulfillment]);

  useEffect(() => {
    return () => timer.current && clearTimeout(timer.current);
  }, []);

  return (
    <>
      <Modal
        open={open}
        onClose={toggleShowModal}
        sectioned
        title={`Resent order: #${orderId ? orderId : ""}`}
        secondaryActions={[{ content: "No", onAction: toggleShowModal }]}
        primaryAction={{
          content: "Yes",
          onAction: handleSubmit,
          loading: loading,
        }}
      >
        <p id="resent_modal">
          Do you want to re-submit this order to Fulfillment?
        </p>
      </Modal>
    </>
  );
};
