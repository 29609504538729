import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import { GET_TOTAL_PRODUCT_PUSHED } from "../../../graphql/mutations";
import { get } from "lodash";
import { arrInvalid, getTeamID } from "../../../helper";
import { useAppContext } from "../../../context";
import { GET_VARIABLES } from "../../../graphql/queries";
import { SETTING_VARIABLES } from "../../../constants";
import React from "react";

export function useTotalProductPushed(storeIDs) {
  const { data, loading, error } = useQuery(GET_TOTAL_PRODUCT_PUSHED, {
    variables: {
      storeIDs,
    },
  });

  const values = formatValues(get(data, "getTotalProductPushed"));

  return { data: values, loading, error };
}

function formatValues(arr) {
  if (arrInvalid(arr)) return {};

  const res = {};
  for (let item of arr) {
    if (!item || !item.storeID || !item.count) continue;

    res[item.storeID] = item.count;
  }

  return res;
}

export function useVariableEnablePushMass() {
  const { currentUser } = useAppContext();
  const teamId = getTeamID(currentUser);
  const userId = get(currentUser, "id");

  const variableKey = `${teamId}${SETTING_VARIABLES.TPM}`;
  const { data } = useQuery(GET_VARIABLES, {
    variables: {
      filter: {
        search: variableKey,
      },
    },
  });

  return checkPushMassVal(data, userId);
}

function checkPushMassVal(res, userId) {
  const value = get(res, "variables[0].value") || "";
  return value.split(",").filter(Boolean).includes(userId);
}
