import React, { useCallback, useReducer } from "react";
import { Button } from "@shopify/polaris";

import { useSelectRowsCtx } from "../../shared/SelectRow";
import { reducerFn } from "../../../helper";
import { BulkActionAcceptDesignPolaris } from "../actions/BulkActionAcceptDesignPolaris";
import BulkRejectDesign from "../actions/BulkRejectDesign";

function TableDataNeedReviewBulkAction() {
  const [state, setState] = useReducer(reducerFn, {
    open: false,
    action: null,
  });

  const { ids, onCompleted } = useSelectRowsCtx() || {};

  const toggleShowModal = useCallback((action) => {
    setState((prev) => ({ ...prev, open: !prev.open, action }));
  }, []);

  const propActions = {
    open: state.open,
    toggleShowModal,
    onCompleted,
    idsChecked: ids,
  };

  const actionsMarkup = {
    acceptDesign: <BulkActionAcceptDesignPolaris {...propActions} />,
    rejectDesign: <BulkRejectDesign {...propActions} />,
  };

  return (
    <>
      <Button
        children="Accept designs"
        onClick={() => toggleShowModal("acceptDesign")}
        disclosure
      />
      <Button
        children="Reject designs"
        onClick={() => toggleShowModal("rejectDesign")}
        disclosure
      />
      {actionsMarkup[state.action]}
    </>
  );
}

export default TableDataNeedReviewBulkAction;
