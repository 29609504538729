import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import { Stack, TextStyle, Heading } from "@shopify/polaris";
import React from "react";
import { REPORT_TIKTOK_DATA_SOURCES } from "../../../graphql/queries";
import { useReportsContext } from "../context";
import { CardBox } from "./CardBox";

const collapseKey = "TiktokDataSourceReport";

const getFlexStyle = (width, justify=null) => {
	let conf = {
		width,
		display: "inline-flex",
		wordWrap: "break-word",
	};
	if (justify) {
		conf["justify-content"] = justify;
	}
	return conf;
}


function TiktokDataSourceReport({ saleChanel }) {
	// Context
	const {
		range,
		isCompare,
		rangeToCompare,
		loading: loadingRoot,
		teamID,
		filter: filterCtx,
	} = useReportsContext();

	// State
	const [filter, setFilter] = React.useState({
		filterByTime: range,
		saleChanel,
	});
	const [state, setState] = React.useReducer((p, s) => ({ ...p, ...s }), {
		items: [],
		total: 0,
		compareTotal: 0,
		loading: true,
	});

	// Queries
	const { data, loading, error } = useQuery(REPORT_TIKTOK_DATA_SOURCES, {
		variables: {
			filter,
		},
	});

	const [lazyReport, { data: d2, loading: l2, error: e2 }] = useLazyQuery(
		REPORT_TIKTOK_DATA_SOURCES,
	);

	React.useEffect(() => {
		const mergedL = loading || l2 || loadingRoot;
		setState({ loading: mergedL });
	}, [loading, l2, loadingRoot]);

	React.useEffect(() => {
		setFilter((p) => ({
			...p,
			filterByTime: range,
			teamId: teamID ?? undefined,
		}));
	}, [range, teamID]);

	React.useEffect(() => {
		if (data?.getTiktokDataSourceReport == null) return;

		const items = data.getTiktokDataSourceReport;
		setState({ items });
	}, [data]);

	React.useEffect(() => {
		if (!isCompare) return;
		let filterByTime = null;
		if (rangeToCompare?.from != null) {
			filterByTime = {
				...filter.filterByTime,
				...rangeToCompare,
			};
		}
		lazyReport({
			variables: {
				filter: {
					...filter,
					filterByTime,
				},
			},
		});
	}, [rangeToCompare, filter, isCompare]);

	// Markup
	const contentMarkup = React.useMemo(() => {
		const sectionDataSources = {
			crawlItems: state.items?.crawlItems ?? null,
			mbProducts: state.items?.mbProducts ?? null,
		}
		const labelMap = {
			crawlItems: "Crawl Items",
			mbProducts: "MB Products",
		};

		return (sectionDataSources.crawlItems || sectionDataSources.mbProducts)
			? (
				<>
					{(sectionDataSources.crawlItems || sectionDataSources.mbProducts) ? (
						<div id="section-data-source">
							<div>
								<Stack wrap={false}>
									<Stack.Item fill>
										<TextStyle variation="strong">Type</TextStyle>
									</Stack.Item>
									<span style={getFlexStyle('8rem')}>
										<TextStyle variation="strong">Total Items</TextStyle>
									</span>
									<span style={getFlexStyle('5rem')}>
										<TextStyle variation="strong">Pushed</TextStyle>
									</span>
									<span style={getFlexStyle('9rem')}>
										<TextStyle variation="strong">API Pushed</TextStyle>
									</span>
									<span style={getFlexStyle('11rem')}>
										<TextStyle variation="strong">Excel Exported</TextStyle>
									</span>
								</Stack>
							</div>
							
							<div style={{marginTop: '8px', display: 'flex', flexDirection: 'column', gap: '15px'}}>
								{Object.entries(sectionDataSources).map(([key, item]) =>
									item ? (
										<Stack
											key={`data-source-${key}`}
											wrap={false}
										>
											<Stack.Item fill>
												<strong>{labelMap[key] || "Unknow"}</strong>
											</Stack.Item>
											<span style={getFlexStyle('8rem')}>
												{item.totalItems}
											</span>
											<span style={getFlexStyle('5rem')}>
												{(item.totalItemsPushed || 0)}
											</span>
											<span style={getFlexStyle('9rem')}>
												{(item.totalItemsPushedViaAPI || 0)}
											</span>
											<span style={getFlexStyle('11rem')}>
												{(item.totalItemsExportedViaExcel || 0)}
											</span>
										</Stack>
									) : null
								)}
							</div>
						</div>
					) : null }
				</>
			)
			: null;
	}, [state.items]);

	return (
		<CardBox
			title="Tiktok Data Sources"
			headMarkup={null}
			contentMarkup={contentMarkup}
			filter={filter}
			loading={state.loading}
			error={error || e2}
			setFilter={setFilter}
			collapseKey={collapseKey}
		/>
	);
}

export { TiktokDataSourceReport };
