import { gql } from "apollo-boost";

import {
  SaleByMarketplaceManagerFragment,
  SaleByProductItemFragment,
  SaleByStoreManagerFragment,
  SaleByStoreFragment,
  KpiByDesignerFragment,
  SaleChanelOverviewResultFragment,
  CreatedProductByUserItemFragment,
  SaleChannelOverviewByProductIDtResultFragment,
  SaleByGroupItemFragment,
} from "../fragments";

export const REPORT_SALE_MARKETPLACE_MANAGER = gql`
  query reportSaleByMarketplaceManagers(
    $filter: SaleByMarketplaceManagerFilter!
  ) {
    reportSaleByMarketplaceManagers(filter: $filter) {
      total
      totalSale
      nodes {
        ...SaleByMarketplaceManagerFragment
      }
    }
  }
  ${SaleByMarketplaceManagerFragment}
`;

export const REPORT_SALE_PRODUCTS = gql`
  query reportSaleByProducts($filter: SaleByProductFilter!) {
    reportSaleByProducts(filter: $filter) {
      total
      totalSale
      totalSystemProducts
      nodes {
        ...SaleByProductItemFragment
      }
    }
  }
  ${SaleByProductItemFragment}
`;

export const REPORT_SALE_STORE_MANAGERS = gql`
  query reportSaleByStoreManagers($filter: SaleByStoreManagerFilter!) {
    reportSaleByStoreManagers(filter: $filter) {
      total
      totalSale
      nodes {
        ...SaleByStoreManagerFragment
      }
    }
  }
  ${SaleByStoreManagerFragment}
`;

export const REPORT_SALE_STORES = gql`
  query reportSaleByStores($filter: SaleByStoreFilter!) {
    reportSaleByStores(filter: $filter) {
      total
      totalSale
      nodes {
        ...SaleByStoreFragment
      }
    }
  }
  ${SaleByStoreFragment}
`;

export const REPORT_SALE_DESIGNERS = gql`
  query reportKpiByDesigners($filter: KpiByDesignerFilter!) {
    reportKpiByDesigners(filter: $filter) {
      total
      totalDoneKpis
      totalDoneTasks
      nodes {
        ...KpiByDesignerFragment
      }
    }
  }
  ${KpiByDesignerFragment}
`;

export const REPORT_SALE_CHANEL_OVERVIEW = gql`
  query reportSaleChanelOverview($filter: SaleChanelOverviewFilter!) {
    reportSaleChanelOverview(filter: $filter) {
      ...SaleChanelOverviewResultFragment
    }
  }
  ${SaleChanelOverviewResultFragment}
`;

export const GET_REPORT_WIDGETS = gql`
  query getReportWidgets($page: String) {
    getReportWidgets(page: $page) {
      col1
      col2
    }
  }
`;

export const REPORT_CREATED_PRODUCTS_BY_USER = gql`
  query reportCreatedProductsByUser($filter: CreatedProductByUserFilter!) {
    reportCreatedProductsByUser(filter: $filter) {
      total
      totalProducts
      nodes {
        ...CreatedProductByUserItemFragment
      }
    }
  }
  ${CreatedProductByUserItemFragment}
`;

export const REPORT_SALE_BY_GROUPS = gql`
  query reportSaleByGroups($filter: SaleByGroupFilter!) {
    reportSaleByGroups(filter: $filter) {
      total
      totalSale
      nodes {
        ...SaleByGroupItemFragment
      }
    }
  }
  ${SaleByGroupItemFragment}
`;

export const REPORT_CREATED_IDEAS_BY_USER = gql`
  query reportCreatedIdeasByUser($filter: CreatedIdeaByUserFilter!) {
    reportCreatedIdeasByUser(filter: $filter) {
      total
      totalIdeas
      nodes {
        createById
        createBy {
          id
          firstName
          lastName
        }
        totalIdeas
        totalKpis
        IdeaByStatusItems {
          status
          totalIdeas
          totalKpis
        }
      }
    }
  }
`;

export const REPORT_ORDER_TRACKING_OVERVIEW = gql`
  query reportOrderTrackingOverview($filter: OrderTrackingOverviewFilter!) {
    reportOrderTrackingOverview(filter: $filter) {
      totalSale
      trackingStatusOverview {
        countRes
        trackStatus
      }
    }
  }
`;

export const REPORT_IDEA_TASKS_BY_DESIGNER = gql`
  query reportIdeaTasksByDesigner($filter: IdeaTasksByDesignerFilter!) {
    reportIdeaTasksByDesigner(filter: $filter) {
      total
      totalTask
      nodes {
        designerId
        designer {
          id
          firstName
          lastName
        }
        totalTasks
        totalKpis
        taskByStatusItems {
          status
          totalIdeas
          totalKpis
        }
      }
    }
  }
`;

export const REPORT_IDEA_TASK_BY_STATUS = gql`
  query reportIdeaTaskByStatus($filter: IdeaTaskByStatusFilter!) {
    reportIdeaTaskByStatus(filter: $filter)
  }
`;

export const REPORT_KPI_TASK_BY_STORE_PLATFORM = gql`
  query reportKpiTaskByStorePlatform(
    $filter: ReportKpiTaskByStorePlatformFilter!
  ) {
    reportKpiTaskByStorePlatform(filter: $filter) {
      platform
      designerOffline
      designerOnline
      designerTeamOnline
      bot
      etsyDesigner
      etsyOnline
      pmeArtist
    }
  }
`;

export const REPORT_FULFILL_COUNTER = gql`
  query reportFulfillCounter($filter: FulfillCounterFilter!) {
    reportFulfillCounter(filter: $filter) {
      totalSale
      nodes {
        fulfillName
        totalSale
        totalBaseCost
      }
    }
  }
`;

export const REPORT_SALE_CHANEL_OVERVIEW_BY_PRODUCT_ID = gql`
  query reportSaleChannelOverviewByProductID(
    $filter: SaleChannelOverviewByProductIDFilter!
  ) {
    reportSaleChannelOverviewByProductID(filter: $filter) {
      ...SaleChannelOverviewByProductIDtResultFragment
    }
  }
  ${SaleChannelOverviewByProductIDtResultFragment}
`;

export const REPORT_SALE_BY_PRODUCT_BASES = gql`
  fragment SaleByProductBaseFrag on SaleByProductBase {
    baseID
    baseTitle
    totalSale
    totalBaseCost
    totalRevenues
  }

  query reportSaleByProductBases($filter: SaleByProductBaseFilter!) {
    reportSaleByProductBases(filter: $filter) {
      etsy {
        ...SaleByProductBaseFrag
      }
      ebay {
        ...SaleByProductBaseFrag
      }
      amazon {
        ...SaleByProductBaseFrag
      }
      tiktok {
        ...SaleByProductBaseFrag
      }
      facebook {
        ...SaleByProductBaseFrag
      }
      onlineStore {
        ...SaleByProductBaseFrag
      }
      aggregations {
        platform
        totalSale
        totalBaseCost
        totalRevenues
      }
    }
  }
`;


export const REPORT_TIKTOK_STATISTIC = gql`
	query getTiktokReport( $filter: TiktokReportFilter! ) {
		getTiktokReport(filter: $filter) {
			crawlItems {
				totalItems
				totalItemsPushed
				totalItemsPushedViaAPI
				totalItemsExportedViaExcel
			}
			mbProducts {
				totalItems
				totalItemsPushed
				totalItemsPushedViaAPI
				totalItemsExportedViaExcel
			}
			stores {
				id
				title
				totalOrders
				totalPushedItems
			}
			groups {
				id
				title
				totalOrders
				totalPushedItems
			}
			managers {
				id
				fullName
				teamRole
				totalStores
				totalOrders
				totalPushedItems
			}
			teams {
				id
				name
				totalStores
				totalPushedItems
				totalOrders
			}
		}
	}
`;

export const REPORT_TIKTOK_DATA_SOURCES = gql`
	query getTiktokDataSourceReport( $filter: TiktokReportFilter! ) {
		getTiktokDataSourceReport(filter: $filter) {
			crawlItems {
				totalItems
				totalItemsPushed
				totalItemsPushedViaAPI
				totalItemsExportedViaExcel
			}
			mbProducts {
				totalItems
				totalItemsPushed
				totalItemsPushedViaAPI
				totalItemsExportedViaExcel
			}
		}
	}
`;

export const REPORT_TIKTOK_GROUPS = gql`
	query getTiktokGroupReport( $filter: TiktokReportFilter! ) {
		getTiktokGroupReport(filter: $filter) {
			total
			nodes {
				id
				title
				totalOrders
				totalPushedItems
			}
		}
	}
`;

export const REPORT_TIKTOK_STORES = gql`
	query getTiktokStoreReport( $filter: TiktokReportFilter! ) {
		getTiktokStoreReport(filter: $filter) {
			total
			nodes {
				id
				title
				totalOrders
				totalPushedItems
			}
		}
	}
`;

export const REPORT_TIKTOK_MANAGERS = gql`
	query getTiktokManagerReport( $filter: TiktokReportFilter! ) {
		getTiktokManagerReport(filter: $filter) {
			total
			nodes {
				id
				fullName
				teamRole
				totalStores
				totalPushedItems
				totalOrders
			}
		}
	}
`;

export const REPORT_TIKTOK_TEAMS = gql`
	query getTiktokTeamReport( $filter: TiktokReportFilter! ) {
		getTiktokTeamReport(filter: $filter) {
			total
			nodes {
				id
				name
				totalStores
				totalPushedItems
				totalOrders
			}
		}
	}
`;