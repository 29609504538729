import { Heading } from "@shopify/polaris";
import React from "react";

export default function RequirementComp({ taskRequirement }) {
  const requirementMarkup =
    taskRequirement != null ? (
      <div className="task-requirement">
        <Heading element="h3">Requirements: </Heading>
        <span>{taskRequirement}</span>
      </div>
    ) : null;

  return requirementMarkup;
}
