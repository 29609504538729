const packageDimensions = "packageDimensions";
const packageWeight = "packageWeight";

const CENTIMETER = "CENTIMETER";
const INCH = "INCH";

const KILOGRAM = "KILOGRAM";
const GRAM = "GRAM";
const OUNCE = "OUNCE";
const POUND = "POUND";

const packageDimensionsUnit = {
  CENTIMETER: KILOGRAM,
  INCH: POUND,
};

const packageWeightUnit = {
  KILOGRAM: CENTIMETER,
  POUND: INCH,
  GRAM: CENTIMETER,
  OUNCE: INCH,
};

export function matchWeightAndDimesion({ state, key, value }) {
  switch (key) {
    case packageDimensions: {
      if (value?.unit) {
        const pWeight = state[packageWeight];
        if (!pWeight) break;


        if (value.unit === INCH && pWeight.unit === OUNCE) break
        if (value.unit === CENTIMETER && pWeight.unit === GRAM) break

        pWeight.unit = packageDimensionsUnit[value.unit] || pWeight.unit;
        state[packageWeight] = pWeight;
      }
      break;
    }

    case packageWeight: {
      if (value?.unit) {
        const pDimensions = state[packageDimensions];
        if (!pDimensions) break;

        pDimensions.unit = packageWeightUnit[value.unit] || pDimensions.unit;
        state[packageDimensions] = pDimensions;
      }
      break;
    }

    default:
      break;
  }

  return state;
}
