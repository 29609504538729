import { Button, Modal } from "@shopify/polaris";
import React from "react";
import { arrInvalid, formatTime, reducerFn } from "../../helper";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { useToastContext } from "./ToastContext";

function DonwloadAllFiles({ files, filename, label }) {
  const [state, setState] = React.useReducer(reducerFn, {
    loading: false,
    open: false,
  });

  const { setNotify, toggleToast } = useToastContext();

  const handleOpenModal = React.useCallback(
    (value) => () => {
      setState({ open: value });
    },
    [],
  );

  const handleDownload = React.useCallback(async () => {
    setState({ loading: true });
    setNotify && setNotify({ msg: null, err: false });
    toggleToast && toggleToast(true);
    const filesPayload = prepareFiles(files, filename);

    if (arrInvalid(filesPayload)) return;

    let newFileName = "";
    if (filename) {
      newFileName = filename + "__";
    }

    newFileName += formatTime(new Date());
    const err = await handleFetchContent(filesPayload, newFileName);
    setState({ loading: false, open: false });

    if (err) {
      if (err instanceof Error) {
        setNotify({
          msg: "Download files has error: " + err.message,
          err: true,
        });
      }
      return;
    }

    setNotify({ msg: "Download files success", err: false });
  }, [filename, files, toggleToast, setNotify]);

  return (
    <>
      <Button
        children={label || "Download all files"}
        plain
        onClick={handleOpenModal(true)}
      />

      <Modal
        title={label || "Download all files"}
        sectioned
        open={state.open}
        onClose={handleOpenModal(false)}
        primaryAction={{
          content: "Download",
          onAction: handleDownload,
          loading: state.loading,
        }}
        secondaryActions={[
          { content: "Cancel", onAction: handleOpenModal(false) },
        ]}
      >
        <p>Are you sure to download all files?</p>
      </Modal>
    </>
  );
}

function prepareFiles(files, filename) {
  let res = [];
  if (!files || !Array.isArray(files) || files.length === 0) return [];

  for (let i = 0; i < files.length; i++) {
    let f = files[i];
    if (!f || !f.url) continue;
    res.push({ name: f.name ?? `${filename}__${i + 1}`, url: f.url });
  }

  return res;
}

async function handleFetchContent(files, filename) {
  try {
    const data = await Promise.allSettled(
      files.map(async (file) => {
        const resp = await fetch(file.url);
        const item = {
          name: file.name,
          blob: await resp.blob(),
        };
        return item;
      }),
    ).then((res) => {
      return (res || [])
        .map((item) => (item.status === "fulfilled" ? item.value : null))
        .filter(Boolean);
    });

    if (arrInvalid(data)) throw new Error("could not get file content");

    const zip = new JSZip();
    const folder = zip.folder();

    //Add images to folder
    for (let item of data) {
      if (!item || !item.name || !item.blob) continue;

      folder.file(item.name, item.blob, { binary: true });
    }

    //Zip folder and download folderZip
    zip.generateAsync({ type: "blob" }).then((blob) => {
      saveAs(blob, filename);
    });
  } catch (err) {
    console.log("error: ", err);
    return err;
  }
}

export default DonwloadAllFiles;
