import { useQuery } from "@apollo/react-hooks";
import { Button, Collapsible, Heading, Spinner } from "@shopify/polaris";
import { gql } from "apollo-boost";
import { get, includes } from "lodash";
import React from "react";
import styled from "styled-components";
import { FieldFragment } from "../../graphql/fragments";
import { arrInvalid, objectInvalid } from "../../helper";
import useToggle from "../../hooks/useToggle";
import { META_DATA } from "../../variable";

const GET_VALUE_FOR_ORDER = gql`
  query getFieldValueForOrder($orderId: ID!) {
    getFieldValueForOrder(orderId: $orderId) {
      id
      createdAt
      field {
        ...FieldFragment
      }
      value
    }
  }
  ${FieldFragment}
`;

// export default function PersonalizedInfo({ orderId, personalized }) {
//   const [open, toggleOpen] = useToggle(false);

//   const { data, loading } = useQuery(GET_VALUE_FOR_ORDER, {
//     variables: {
//       orderId,
//     },
//     skip: !orderId,
//   });

//   if (!orderId) return null;

//   let value = sortFields(data?.getFieldValueForOrder);
//   const fourFirstItem = value?.length > 0 ? value.slice(0, 4) : [];
//   const showMore = (value?.length || 0) > 4;

//   return !!personalized ? (
//     <Wrapper className="personalized-info">
//       <Heading element="h3">Personalized info:</Heading>
//       {loading ? (
//         <Spinner size="small" />
//       ) : value?.length > 0 ? (
//         <div className="personalized-inner">
//           <div className="field-wrap">
//             {fourFirstItem.map((item, index) => (
//               <FieldComp item={item} key={`item-${index}`} />
//             ))}
//           </div>

//           {showMore && (
//             <>
//               <Collapsible
//                 open={open}
//                 id="basic-collapsible"
//                 transition={{
//                   duration: "500ms",
//                   timingFunction: "ease-in-out",
//                 }}
//                 expandOnPrint
//               >
//                 <div className="field-wrap" style={{ marginTop: 10 }}>
//                   {value.slice(4).map((item, index) => (
//                     <FieldComp item={item} key={`item-${index}`} />
//                   ))}
//                 </div>
//               </Collapsible>
//               <Button
//                 plain
//                 children={`Show ${open ? "less" : "more"}`}
//                 onClick={toggleOpen}
//               />
//             </>
//           )}
//         </div>
//       ) : null}
//     </Wrapper>
//   ) : null;
// }

export default function PersonalizedInfo({ orderId, personalized }) {

  const { data, loading } = useQuery(GET_VALUE_FOR_ORDER, {
    variables: {
      orderId,
    },
    skip: !orderId,
  });

  if (!orderId) return null;

  return <PersonalizedInfoInner personalized={personalized} loading={loading} fieldValues={data?.getFieldValueForOrder} />
}

export function PersonalizedInfoInner({ personalized, loading, fieldValues }) {
  const [open, toggleOpen] = useToggle(false);

  let value = sortFields(fieldValues);
  const fourFirstItem = value?.length > 0 ? value.slice(0, 4) : [];
  const showMore = (value?.length || 0) > 4;

  return !!personalized ? (
    <Wrapper className="personalized-info">
      <Heading element="h3">Personalized info:</Heading>
      {loading ? (
        <Spinner size="small" />
      ) : value?.length > 0 ? (
        <div className="personalized-inner">
          <div className="field-wrap">
            {fourFirstItem.map((item, index) => (
              <FieldComp item={item} key={`item-${index}`} />
            ))}
          </div>

          {showMore && (
            <>
              <Collapsible
                open={open}
                id="basic-collapsible"
                transition={{
                  duration: "500ms",
                  timingFunction: "ease-in-out",
                }}
                expandOnPrint
              >
                <div className="field-wrap" style={{ marginTop: 10 }}>
                  {value.slice(4).map((item, index) => (
                    <FieldComp item={item} key={`item-${index}`} />
                  ))}
                </div>
              </Collapsible>
              <Button
                plain
                children={`Show ${open ? "less" : "more"}`}
                onClick={toggleOpen}
              />
            </>
          )}
        </div>
      ) : null}
    </Wrapper>
  ) : null;
}

function FieldComp({ item }) {
  let label = item?.field?.title || item?.field?.name || "";
  let value = item.value;
  let hasImg = includes(value.length && value[0], META_DATA);
  value = value && Array.isArray(value) && value.join(", ");

  return (
    <div>
      {label ? <label className="label">{label}: </label> : null}
      {hasImg ? (
        <div className="img-wrap">
          <a href={value} target="_blanke">
            <img src={value} alt="" />
          </a>
        </div>
      ) : (
        <span
          style={{ wordBreak: "break-all" }}
          title={!label ? "no name" : " "}
        >
          {value}
        </span>
      )}
    </div>
  );
}

function sortFields(values) {
  if (arrInvalid(values)) return [];
  const photoPT = /photo/gi;
  const backupPT = /backup/gi;

  const textArr = [];
  const photoArr = [];
  const backupArrr = [];
  const unLabel = [];
  for (let v of values) {
    if (objectInvalid(v)) continue;

    const { field } = v;
    // if (objectInvalid(field)) continue;

    const title = get(field, "title") || "";
    if (!title) {
      unLabel.push(v);
      continue;
    }

    if (!!title.match(backupPT)) {
      backupArrr.push(v);
    } else if (!!title.match(photoPT)) {
      photoArr.push(v);
    } else {
      textArr.push(v);
    }
  }

  return [
    ...textArr,
    ...photoArr.sort(sortFunc),
    ...backupArrr.sort(sortFunc),
    ...unLabel,
  ];
}

const sortFunc = (a, b) =>
  get(a, "field.title") > get(b, "field.title") ? 1 : -1;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  position: relative;

  .img-wrap {
    img {
      width: 100px;
      height: 100px;
      object-fit: cover;
    }
  }

  .personalized-info {
    margin-right: 15rem;
  }

  .personalized-info {
    h4 {
      font-weight: 500;
      font-size: 1.2em;
      display: inline-block;
    }
    .personalized-inner {
      display: flex;
      flex-direction: row;
      column-gap: 1.6rem;
      row-gap: 1.6rem;
      flex-wrap: wrap;
      justify-content: flex-start;
      label.label {
        font-weight: 500;
        color: rgba(0, 0, 0, 0.85);
        font-size: 1em;
        margin-right: 1rem;
      }
    }
  }

  .field-wrap {
    display: flex;
    gap: 10px;
    flex-direction: row;
    flex-wrap: wrap;

    .label {
      white-space: nowrap;
      font-weight: 500;
    }
  }
`;
