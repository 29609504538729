import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import { Stack, TextStyle, Heading } from "@shopify/polaris";
import React from "react";
import { REPORT_TIKTOK_TEAMS } from "../../../graphql/queries";
import { useReportsContext } from "../context";
import { CardBox } from "./CardBox";

const collapseKey = "TiktokTeamReport";

const getFlexStyle = (width, justify=null) => {
	let conf = {
		width,
		display: "inline-flex",
		wordWrap: "break-word",
	};
	if (justify) {
		conf["justify-content"] = justify;
	}
	return conf;
}


function TiktokTeamReport({ saleChanel }) {
	// Context
	const {
		range,
		isCompare,
		rangeToCompare,
		loading: loadingRoot,
		teamID,
		filter: filterCtx,
	} = useReportsContext();

	// State
	const [filter, setFilter] = React.useState({
		limit: 10,
		offset: 0,
		filterByTime: range,
		teamId: teamID,
	});
	const [state, setState] = React.useReducer((p, s) => ({ ...p, ...s }), {
		items: [],
		total: 0,
		compareTotal: 0,
		loading: true,
	});

	// Queries
	const { data, loading, error } = useQuery(REPORT_TIKTOK_TEAMS, {
		variables: {
			filter,
		},
	});

	const [lazyReport, { data: d2, loading: l2, error: e2 }] = useLazyQuery(
		REPORT_TIKTOK_TEAMS,
	);

	React.useEffect(() => {
		const mergedL = loading || l2 || loadingRoot;
		setState({ loading: mergedL });
	}, [loading, l2, loadingRoot]);

	React.useEffect(() => {
		setFilter((p) => ({
			...p,
			filterByTime: range,
			teamId: teamID ?? undefined,
		}));
	}, [range, teamID]);

	React.useEffect(() => {
		if (data?.getTiktokTeamReport == null) return;

		const items = data.getTiktokTeamReport?.nodes ?? [];
		const total = data.getTiktokTeamReport?.total ?? 0;
		setState({ items, total });
	}, [data]);

	React.useEffect(() => {
		if (!isCompare) return;
		let filterByTime = null;
		if (rangeToCompare?.from != null) {
			filterByTime = {
				...filter.filterByTime,
				...rangeToCompare,
			};
		}
		lazyReport({
			variables: {
				filter: {
					...filter,
					filterByTime,
				},
			},
		});
	}, [rangeToCompare, filter, isCompare]);

	// Markup
	const contentMarkup = React.useMemo(() => {
		const teams = state.items;

		return teams && teams.length > 0 ? (
				<>
					<div style={{marginTop: '10px'}}>
						<Stack wrap={false}>
							<span style={getFlexStyle('10rem')}>
								<TextStyle variation="strong"># ID</TextStyle>
							</span>
							<Stack.Item fill>
								<TextStyle variation="strong">Name</TextStyle>
							</Stack.Item>
							<span style={getFlexStyle('5rem')}>
								<TextStyle variation="strong">Stores</TextStyle>
							</span>
							<span style={getFlexStyle('5rem')}>
								<TextStyle variation="strong">Pushed</TextStyle>
							</span>
							<span style={getFlexStyle('5rem')}>
								<TextStyle variation="strong">Orders</TextStyle>
							</span>
						</Stack>
					</div>

					<div style={{marginTop: '8px', display: 'flex', flexDirection: 'column', gap: '15px'}}>
						{teams.map((item, key) => {
							return (
								<Stack
									key={`data-teams-${key}`}
									wrap={false}
								>
									<span style={getFlexStyle('10rem')}>
										{item?.id}
									</span>
									<Stack.Item fill>
										<strong>{item?.name}</strong>
									</Stack.Item>
									<span style={getFlexStyle('5rem')}>
										{item.totalStores}
									</span>
									<span style={getFlexStyle('5rem')}>
										{item.totalPushedItems}
									</span>
									<span style={getFlexStyle('5rem')}>
										{item.totalOrders}
									</span>
								</Stack>
							)
						})}
					</div>
				</>
			)
			: null;
	}, [state.items]);

	return (
		<CardBox
			title="Tiktok stats in Teams"
			headMarkup={null}
			contentMarkup={contentMarkup}
			filter={filter}
			loading={state.loading}
			error={error || e2}
			setFilter={setFilter}
			collapseKey={collapseKey}
			total={state.total}
			state={state}
		/>
	);
}

export { TiktokTeamReport };
