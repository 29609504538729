import { Button, Card, Stack, TextContainer } from "@shopify/polaris";
import { HideMinor, ViewMinor } from "@shopify/polaris-icons";
import _, { get } from "lodash";
import React from "react";
import styled from "styled-components";
import { FULFILLMENTS_SLUG } from "../../constants";
import { useAppContext } from "../../context";
import { getTeamID, handleError } from "../../helper";
import useToggle from "../../hooks/useToggle";
import CopyBox from "../shared/CopyBox";
import SuspenseComp from "../shared/SuspenseComp";
import { ToastContextProvider } from "../shared/ToastContext";
import { CustomcatFulfillmentConfigurePolaris } from "./CustomcatFulfillmentConfigurePolaris";
import { DreamshipFulfillmentConfigurePolaris } from "./DreamshipFulfillmentConfigurePolaris";
import { GearmentFulfillmentConfigurePolaris } from "./GearmentFulfillmentConfigurePolaris";
import { MerchizeFulfillmentConfigure } from "./MerchizeFulfillmentConfigure";
import { PrintifyFulfillmentConfigurePolaris } from "./PrintifyFulfillmentConfigurePolaris";
import { PrintwayFulfillmentConfigurePolaris } from "./PrintwayFulfillmentConfigurePolaris";
import { ScalablepressFulfillmentConfigure } from "./ScalablepressFulfillmentConfigure";
import { TeezilyFulfillmentConfigurePolaris } from "./TeezilyFulfillmentConfigurePolaris";
import { FulfillmentProvider } from "./context";

const BurgerPrints = React.lazy(() => import("./components/BurgerPrints"));
const MangoTeePrints = React.lazy(() => import("./components/MangoTeePrints"));
const FulfillmentCard = React.lazy(() =>
  import("./components/FulfillmentCard"),
);
const Flashship = React.lazy(() => import("./components/Flashship"));
const PGPrints = React.lazy(() => import("./components/pgprints"));

export const SPContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;

  > a {
    margin: 0 0.5rem;
  }

  strong {
    margin-right: 0.5rem;
  }

  .input-wrap {
    min-width: 30rem;
    margin-left: 1rem;
  }
`;

const TeezilyContainer = styled.div`
  > a {
    margin: 0 0.5rem;
  }

  strong {
    margin-right: 0.5rem;
  }

  .input-wrap {
    min-width: 30rem;
  }

  ul {
    padding: 0;
    list-style-type: none;
    display: flex;
    flex-direction: row;
    column-gap: 2rem;
    margin: 1rem 0;

    li {
      display: flex;
      align-items: center;
      column-gap: 1rem;
    }
  }
`;

export const FulfillmentPolaris = ({ data, error, refetch }) => {
  const { currentUser } = useAppContext();
  const teamId = getTeamID(currentUser);

  const apps = ignoreTrello(data);

  if (error) return <div>Error: {handleError(error.toString())}</div>;

  return (
    <ToastContextProvider>
      {apps && apps.length > 0
        ? apps.map((app, idx) => (
            <AppWrap
              key={`app-${idx}`}
              app={app}
              teamId={teamId}
              refetch={refetch}
            />
          ))
        : null}
    </ToastContextProvider>
  );
};

function ScalablepressWrap({ installed, refetch }) {
  return (
    <>
      <SPContainer>
        <strong>Note:</strong> Go to your
        <a
          href="https://scalablepress.com/manager/v2/settings/account"
          target="_blank"
          className="Polaris-Link"
          rel="noopener noreferrer"
        >
          ScalablePress account settings
        </a>
        and set API webhook URL with value:
        <div className="input-wrap">
          <CopyBox text="https://api.merchbridge.com/scalablepress-wh" />
        </div>
      </SPContainer>
      <ScalablepressFulfillmentConfigure
        installed={installed}
        refetch={refetch}
      />
    </>
  );
}
function TeezilyWrap({ installed, refetch, value, teamId }) {
  return (
    <>
      <TeezilyContainer>
        <strong>Note:</strong> Go to your
        <a
          href="https://plus.teezily.com/account/shops"
          target="_blank"
          className="Polaris-Link"
          rel="noopener noreferrer"
        >
          Teezily account settings
        </a>
        and set:
        <div>
          <ul>
            <li>
              <span>API Webhook URL with value:</span>
              <div className="input-wrap">
                <CopyBox text="https://api.merchbridge.com/teezily-wh" />
              </div>
            </li>
            <li>
              <span>and Webhook Secret Key with value:</span>
              <div className="input-wrap">
                <CopyBox text={teamId} />
              </div>
            </li>
          </ul>
        </div>
      </TeezilyContainer>
      <TeezilyFulfillmentConfigurePolaris
        installed={installed}
        refetch={refetch}
        value={value}
      />
    </>
  );
}

const FulfillmentComp = {
  [FULFILLMENTS_SLUG.Customcat]: CustomcatFulfillmentConfigurePolaris,
  [FULFILLMENTS_SLUG.Gearment]: GearmentFulfillmentConfigurePolaris,
  [FULFILLMENTS_SLUG.Dreamship]: DreamshipFulfillmentConfigurePolaris,
  [FULFILLMENTS_SLUG.Merchize]: MerchizeFulfillmentConfigure,
  [FULFILLMENTS_SLUG.Scalablepress]: ScalablepressWrap,
  [FULFILLMENTS_SLUG.Printify]: PrintifyFulfillmentConfigurePolaris,
  [FULFILLMENTS_SLUG.Printway]: PrintwayFulfillmentConfigurePolaris,
  [FULFILLMENTS_SLUG.Teezily]: TeezilyWrap,
  [FULFILLMENTS_SLUG.BurgerPrints]: BurgerPrints,
  [FULFILLMENTS_SLUG.MangoTeePrints]: MangoTeePrints,
  [FULFILLMENTS_SLUG.Flashship]: Flashship,
  [FULFILLMENTS_SLUG.PGPrints]: PGPrints,
};

function AppWrap({ app, teamId, refetch }) {
  const name = toNameLower(app);

  const metadataMarkup = React.useMemo(() => {
    if (name === "merchize") {
      return <Metadata label="Store URL" text={_.get(app, "settings.url")} />;
    }

    if (name === "flashship") {
      return (
        <Stack vertical>
          <Metadata label="Username" text={_.get(app, "settings.username")} />
          <Metadata label="Password" text={_.get(app, "settings.password")} />
        </Stack>
      );
    }

    if (name === FULFILLMENTS_SLUG.PGPrints) {
      return (
        <Stack vertical>
          <Metadata label="Shop ID" text={_.get(app, "settings.shop_id")} />
          <Metadata label="Secret key" text={_.get(app, "settings.secret_key")} />
        </Stack>
      );
    }

    return (
      <div
        style={{
          display: "inline-block",
          wordBreak: "break-all",
        }}
      >
        <Metadata label="API key" text={_.get(app, "settings.api_key")} />
      </div>
    );
  }, [name, app]);

  const props = {
    app,
    refetch,
    teamId,
    value: app,
    installed: app.installed,
  };

  const Comp = FulfillmentComp[name] || FulfillmentCard;

  return (
    <FulfillmentProvider refetch={refetch}>
      <Card>
        <Card.Header title={app.name} />
        <Card.Section>
          <TextContainer spacing="tight">
            <div>{app.description}</div>
            {metadataMarkup}
            <SuspenseComp>
              <div>{!!Comp ? <Comp {...props} /> : null}</div>
            </SuspenseComp>
          </TextContainer>
        </Card.Section>
      </Card>
    </FulfillmentProvider>
  );
}

export function Metadata({ label, text }) {
  // State
  const [open, toggleOpen] = useToggle(false);

  return !!text ? (
    <APIKeyWrapper>
      {label}:
      <CopyBox text={text} type={open ? "text" : "password"} />
      <Button plain icon={open ? ViewMinor : HideMinor} onClick={toggleOpen} />
    </APIKeyWrapper>
  ) : null;
}

function ignoreTrello(data) {
  const apps = get(data, "apps") || [];
  return apps.filter((i) => toNameLower(i) !== "trello");
}

function toNameLower(app) {
  const name = get(app, "name") || "";
  return name.toLowerCase();
}

const APIKeyWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 1rem;
  margin-bottom: 1rem;

  .copy-box {
    padding: 0.5rem 1rem;
    border-radius: var(--p-border-radius-base, 3px);
    margin-top: 0.5rem;
    border-color: rgb(183, 193, 202);
    gap: 0.5rem;
  }
`;
