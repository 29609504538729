import React, { useState, useCallback, useEffect, useRef } from "react";
import { Filters } from "@shopify/polaris";
import { arrInvalid, checkRole, isEmpty, reducerFn } from "../../../helper";
import { FilterListRadioPolaris } from "../../filters/FilterListRadioPolaris";
import { FilterHasSearchPolaris } from "../../filters/FilterHasSearchPolaris";
import styled from "styled-components";
import { useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { useTeamMember } from "../../team-member/hooks";
import { TEAM_ROLE } from "../../../variable";
import { genOptions } from "../../team-member/components/team-member-select";
import { useAppContext } from "../../../context";
import { FilterNoSearchPolaris } from "../../filters/FilterNoSearchPolaris";
import { FilterCreatedTimePolaris } from "../../product/FilterCreatedTimePolaris";

const ALL_ORDER_OPTIONS = [
	{ value: "DESC", label: "DESC" },
	{ value: "ASC", label: "ASC" },
];

const ALL_ORDER_BY_OPTIONS = [
	{ value: "title", label: "Title" },
	{ value: "created_at", label: "Created At" },
];

const LIST_TIKTOK_STORES = gql`
	query stores($filter: StoreFilter) {
		stores(filter: $filter) {
			total
			nodes {
				id
				title
			}
		}
	}
`;

const All = "All";
const Yes = "Yes";
const No = "No";

const BOOL_OPTIONS = [
  { value: All, label: All },
  { value: Yes, label: Yes },
  { value: No, label: No },
];

const STATUS_OPTIONS = [
	{ value: All, label: All },
	{value: "Pending", label: "Pending"},
	{value: "Running", label: "Running"},
	{value: "Reach daily limit", label: "Reach daily limit"},
	{value: "Done", label: "Done"},
	{value: "Error", label: "Error"},
	{value: "Paused", label: "Paused"},
]

const LabelContainer = styled.div`
	display: flex;
	flex-direction: column;
	word-break: break-all;
	.domain {
		font-size: 0.9em;
		padding-right: 5px;
		color: var(--p-interactive, #006fbb);
	}
`;

export default function PushProductsFilter({ onFilterChange }) {
	const [filterOrdering, setFilterOrdering] = useState("DESC");
	const [filterOrderBy, setFilterOrderBy] = useState("created_at");
	const [queryValue, setQueryValue] = useState("");

	const [stores, setStores] = useState([]);
	const [store, setStore] = useState({
		value: null,
		label: null,
		search: null,
	});

	const [state, setState] = React.useReducer(reducerFn, {
		members: [],
		createdByID: "All",
		createdByName: "All",

		pushValue: null,
		pushLabel: null,

		personalizedValue: null,
		personalizedLabel: null,

		pushMassValue: null,
		pushMassLabel: null,

		rangeValue: null,
		rangeLabel: null,

		statusLabel: null,
		statusValue: null,
	})

	// ctx
	const { currentUser } = useAppContext();
	const { isSeller } = checkRole(currentUser);

	const showCreatedBy = !!isSeller;

	const {
    data,
  } = useTeamMember({ roles: [TEAM_ROLE.Admin,TEAM_ROLE.MarketplaceManager, TEAM_ROLE.StoreManager]}, false, "id role user {id firstName lastName}");

	const typingTimeoutRef = useRef(null);

	const {
		data: dataStore,
		loading: loadingStore,
		error: errorStore,
	} = useQuery(LIST_TIKTOK_STORES, {
		variables: {
			filter: {
				limit: store.search ? 1000 : 20,
				offset: 0,
				search: store.search,
				platform: "tiktok"
			},
		},
		fetchPolicy: "cache-and-network",
	});


	useEffect(() => {
		const nodes = genOptions(data);

		const newState = {
			members: arrInvalid(nodes) ? [] : nodes, // [{ value: "All", label: 'All'}, ...nodes],
		}

		if (state?.createdByID && !arrInvalid(nodes)) {
			const cur = nodes.find((i) => i?.value === state.createdByID);
			if (cur) {
				newState.createdByID = cur.value;
				newState.createdByName = cur.label;
			}
		}

		setState({...newState})

	}, [JSON.stringify(data), state?.createdByID])

	useEffect(() => {
		if (dataStore) {
			let newData =
				dataStore.stores?.nodes?.length > 0
					? dataStore.stores.nodes.map((i) => {
							let subTitle = i.domain;
							return {
								value: i.id,
								// label: i.title,
								label: (
									<LabelContainer>
										<span className="title">{i.title}</span>
										<span className="domain">
											{subTitle}
										</span>
									</LabelContainer>
								),
							};
					  })
					: [];
			setStores(newData);
		}
	}, [dataStore]);

	useEffect(() => {
		if (typingTimeoutRef.current) {
			clearTimeout(typingTimeoutRef.current);
		}
		typingTimeoutRef.current = setTimeout(() => {
			if (onFilterChange) {
				onFilterChange({
					search: queryValue ? queryValue.trim() : queryValue,
					storeID: store?.value || null,
					orderBy: filterOrderBy,
					order: filterOrdering,
					createdByID: state.createdByID === "All" ? null : state.createdByID,
					range: state.rangeValue === "All" ? null : state.rangeValue,
					status: state.statusValue === "All" ? null : state.statusValue,
					isPersonalized: state.personalizedValue === Yes ? true : state.personalizedValue === No ? false : null,
					isPushMass: state.pushMassValue === Yes ? true : state.pushMassValue === No? false : null,
				});
			}
		}, 300);
	}, [queryValue, filterOrderBy, state.createdByID, state.personalizedValue, state.pushMassValue, filterOrdering, store, state.rangeValue, state.statusValue]);


	// Handle actions
	const handleQueryValueChange = useCallback(
		(value) => setQueryValue(value),
		[]
	);

	const handleStateChange = useCallback((key) => ({ value, label }) => {
    setState({ [`${key}Value`]: value, [`${key}Label`]: label})
  }, []);

	const handleStateRemove = useCallback((key, isSingle) =>
    () =>setState({ [`${key}Value`]: isSingle? null: [], [`${key}Label`]: isSingle ? null: []}),
    []
  );

	const handleQueryValueRemove = useCallback(() => setQueryValue(null), []);

	const handleCreatedByRemove = useCallback(() => setState({ createdByID: "All" }), []);

	const handleFilterClearAll = useCallback(() => {
		handleQueryValueRemove();
		handleRemoveFiltering("origin_source");
		handleRemoveFiltering("ordering");
		handleRemoveFiltering("order_by");
		handleRemoveFiltering("created_by");

		for (let key of ['personalized', 'pushMass', 'range', 'status']) {
			handleStateRemove(key, true)()
		}
	}, [handleQueryValueRemove]);

	const handleRemoveFiltering = (key) => {
		switch (key) {
			case "store_id":
				setStore({
					value: null,
					label: null,
					search: null,
				});
				break;
			case "ordering":
				setFilterOrdering("");
				break;
			case "order_by":
				setFilterOrderBy("");
				break;
			case "keyword":
				handleQueryValueRemove();
				break;
			case 'created_by':
				handleCreatedByRemove();
				break;
			default:
				break;
		}
	};

	const filters = [
		{
			key: "store",
			label: "Stores",
			filter: (
				<FilterHasSearchPolaris
					options={stores}
					value={store}
					loading={loadingStore}
					error={errorStore}
					onChangeSearch={(search) =>
						setStore((prevState) => ({ ...prevState, search }))
					}
					onChange={({ value, label }) =>
						setStore((prevState) => ({
							...prevState,
							value,
							label,
						}))
					}
				/>
			),
		},
		{
      key: "personalized",
      label: "Personalized",
      filter: (
        <FilterListRadioPolaris
          value={state.personalizedValue}
          options={BOOL_OPTIONS}
          onChange={handleStateChange('personalized')}
        />
      ),
    },
		{
      key: "push-mass",
      label: "Push Mass",
      filter: (
        <FilterListRadioPolaris
          value={state.pushMassValue}
          options={BOOL_OPTIONS}
          onChange={handleStateChange('pushMass')}
        />
      ),
    },
		{
      key: "status",
      label: "Status",
      filter: (
        <FilterListRadioPolaris
          value={state.statusValue}
          options={STATUS_OPTIONS}
          onChange={handleStateChange('status')}
        />
      ),
    },
		showCreatedBy && {
			key: "created_by",
			label: "Created By",
			filter: (
				<FilterNoSearchPolaris
					data={state.members}
					value={{ value: state.createdByID, label: state.createdByName }}
					hasAll
					onChange={({ value, label }) => {
						setState({ createdByID: value, createdByName: label });
					}}
				/>
			),
		},
		{
      key: "created-time",
      label: "Created Time",
      filter: (
        <FilterCreatedTimePolaris
          filterTime={{ range: state.rangeValue, rangeLabel: state.rangeLabel,}}
          onChangeRange={(value, label) => {
            handleStateChange('range')({value, label })
          }}
        />
      ),
    },
		{
			key: "ordering",
			label: "Order",
			filter: (
				<FilterListRadioPolaris
					options={ALL_ORDER_OPTIONS}
					value={filterOrdering}
					onChange={({ value, label }) => setFilterOrdering(value)}
				/>
			),
		},
		{
			key: "order_by",
			label: "Order by",
			filter: (
				<FilterListRadioPolaris
					options={ALL_ORDER_BY_OPTIONS}
					value={filterOrderBy}
					onChange={({ value, label }) => setFilterOrderBy(value)}
				/>
			),
		},
	].filter(Boolean);

	const appliedFilters = [];

	if (!isEmpty(filterOrdering)) {
		const key = "ordering";
		appliedFilters.push({
			key,
			label: disambiguateLabel(key, filterOrdering),
			onRemove: () => handleRemoveFiltering(key),
		});
	}
	if (!isEmpty(filterOrderBy)) {
		const key = "order_by";
		appliedFilters.push({
			key,
			label: disambiguateLabel(key, filterOrderBy),
			onRemove: () => handleRemoveFiltering(key),
		});
	}
	if (!isEmpty(queryValue)) {
		const key = "keyword";
		appliedFilters.push({
			key,
			label: disambiguateLabel(key, queryValue),
			onRemove: () => handleRemoveFiltering(key),
		});
	}
	if (!isEmpty(store.label)) {
		const key = "store_id";
		appliedFilters.push({
			key,
			label: disambiguateLabel(key, store.label),
			onRemove: () => handleRemoveFiltering(key),
		});
	}

	if (showCreatedBy && !isEmpty(state.createdByID) && state.createdByID !== "All" && state.createdByName !== "All") {
		const key = "created_by";
		appliedFilters.push({
			key,
			label: disambiguateLabel(key, state.createdByName),
			onRemove: () => handleRemoveFiltering(key),
		});
	}

	if (!isEmpty(state.personalizedLabel)) {
    const key = "personalized";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, state.personalizedLabel),
      onRemove: handleStateRemove('personalized', true),
    });
  }

	if (!isEmpty(state.pushMassLabel)) {
    const key = "push-mass";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, state.pushMassLabel),
      onRemove: handleStateRemove('pushMass', true),
    });
  }

	if (!isEmpty(state.rangeLabel)) {
    const key = "created-time";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, state.rangeLabel),
      onRemove: handleStateRemove('range', true),
    });
  }

	if (!isEmpty(state.statusLabel)) {
    const key = "status";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, state.statusLabel),
      onRemove: handleStateRemove('status', true),
    });
  }

	return (
		<Filters
			queryPlaceholder={`Search crawl item`}
			queryValue={queryValue}
			onQueryChange={handleQueryValueChange}
			onQueryClear={handleQueryValueRemove}
			onClearAll={handleFilterClearAll}
			filters={filters}
			appliedFilters={appliedFilters}
		/>
	);
	function disambiguateLabel(key, value) {
		switch (key) {
			case "store_id":
				if (value) {
					return `Store: ${value}`;
				}
				break;
			case "keyword":
				return `Keyword: ${value}`;
			case "origin_source":
				return `Origin source: ${value}`;
			case "ordering":
				return `Order: ${value}`;
			case "order_by":
				if (ALL_ORDER_BY_OPTIONS) {
					let orderBy = ALL_ORDER_BY_OPTIONS.find(
						(item) => item.value === value
					);
					if (orderBy && orderBy.label) {
						return `Order by: ${orderBy.label}`;
					}
				}
				return `Order by: ${value}`;
			case 'created_by':
				return `Created by: ${value}`;
			case "personalized":
				return `Personalized: ${value}`;
			case "push-mass":
				return `Push Mass: ${value}`;
			case "created-time":
				return `Created time: ${value}`;
			case "status":
				return `Status: ${value}`;
			default:
				return value;
		}
	}
}
