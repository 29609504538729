import { Mutation } from "@apollo/react-components";
import { Button, notification } from "antd";
import { gql } from "apollo-boost";
import React, { Component } from "react";
import { AppContext } from "../../../context";
import { productBaseFragment } from "../../../fragments/index";
import { getParamByRole, handleError } from "../../../helper";
import history from "../../../history";
import AddProductFormV2 from "../../seller/AddProductFormV2";
import ProductbaseSelectV2 from "../../seller/ProductBaseSelectV2";
import { getProductBaseVariants } from "../../seller/utils";
import PageTitle from "../../shared/PageTitle";

const CREATE_PRODUCT_TEMPLATE = gql`
  mutation createProductTemplate($input: NewProductTemplate!) {
    createProductTemplate(input: $input) {
      id
      name
      slug
      grouping
      isProductTemplate
      defaultMockupIds
      productBases {
        id
        mockupTemplates {
          id
          originTemplateID
          isDefault
        }
      }
      data {
        personalized
        productBases {
          ...ProductBasse
        }
        ProductBaseIds
        fields {
          name
          title
          type
          extraFee
          configure
          sorting
        }
        products {
          title
          description
          productbaseId
          shortDescription
          tagIds
          tags {
            id
            name
          }
          variants {
            salePrice
            regularPrice
            productBaseVariantID
            disabled
          }
          collectionIds
          collections {
            id
            name
          }
        }
      }
    }
  }
  ${productBaseFragment}
`;

export class AddForProduct extends Component {
  static contextType = AppContext;
  state = {
    selectedProductBase: null,
    step: 1,
    loading: false,
    fulfillmentId: null,
  };

  componentDidUpdate(_, prevState) {
    let { loading } = this.state;
    let { handleLoading } = this.props;
    if (loading !== prevState.loading) {
      if (handleLoading) {
        handleLoading(loading);
      }
    }
  }

  preLoadDesignPositionByBase = async (base) => {
    if (!base) return base;
    const { __apolloClient__: client } = window || {};
    if (!client) return base;
    const { id } = base;

    const values = await getProductBaseVariants([id]);
    let newBase = { ...base };
    if (values && values[id]) {
      newBase = { ...newBase, ...values[id] };
    }
    return newBase;
  };

  render() {
    let { step } = this.state;

    const { currentUser } = this.context;
    let currentParam = getParamByRole(currentUser);

    return (
      <>
        {step === 1 && (
          <div>
            <PageTitle
              title={"Add Template for Product"}
              subTitle={"All Templates"}
              link={`/${currentParam}/templates`}
              action={
                <Button
                  type="primary"
                  onClick={async () => {
                    if (this.state.step === 1) {
                      if (!this.state.selectedProductBase) {
                        notification.error({
                          message: "Please select product base!",
                        });
                        return;
                      }
                      // this.setState({
                      //   step: 2,
                      // });
                      const newBase = await this.preLoadDesignPositionByBase(
                        this.state.selectedProductBase,
                      );
                      this.setState({
                        step: 2,
                        selectedProductBase: newBase,
                      });
                    }
                  }}
                >
                  Continue
                </Button>
              }
            />
            <ProductbaseSelectV2
              onFulfillmentChange={(v) => {
                this.setState({ fulfillmentId: v });
              }}
              fulfillmentId={this.state.fulfillmentId}
              selectedProductBases={this.state.selectedProductBase}
              onChange={(selected) => {
                this.setState({
                  selectedProductBase: selected ? selected[0] : null,
                });
              }}
            />
          </div>
        )}
        {step === 2 && (
          <React.Fragment>
            <Mutation
              onError={(e) => {
                notification.error({
                  message: handleError(e.toString()),
                });
                this.setState({
                  loading: false,
                });
              }}
              onCompleted={(res) => {
                notification.success({
                  message: "Template has been saved!",
                });
                history.push(`/${currentParam}/templates`);
              }}
              mutation={CREATE_PRODUCT_TEMPLATE}
            >
              {(createTemplate, { client }) => (
                <AddProductFormV2
                  apolloClient={client}
                  loading={this.state.loading}
                  currentParam={currentParam}
                  onSubmit={(values) => {
                    this.setState(
                      {
                        loading: true,
                      },
                      () => {
                        createTemplate({
                          variables: {
                            input: values,
                          },
                        });
                      },
                    );
                  }}
                  productBase={this.state.selectedProductBase}
                  fulfillmentId={this.state.fulfillmentId}
                  onChangeProductBase={(p) =>
                    this.setState({
                      selectedProductBase: p,
                    })
                  }
                />
              )}
            </Mutation>
          </React.Fragment>
        )}
      </>
    );
  }
}
