import {
  Button,
  ButtonGroup,
  Card,
  DataTable,
  Icon,
  TextField,
} from "@shopify/polaris";
import {
  CirclePlusMinor,
  DeleteMinor,
  SearchMinor,
} from "@shopify/polaris-icons";
import get from "lodash/get";
import PropTypes from "prop-types";
import React, {
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import { ShippingOptions } from "./ShippingOptions";
import SwitchFulfillmentBaseVariant from "./SwitchFulfillmentBaseVariants";
// import SwitchFulfillmentMapToVariant from "./SwitchFulfillmentMapToVariant";
import SwitchFulfillmentMapToVariant from "./switch-fulfillment-map-to-variant-v2";
import { useSwitchFulfillment } from "./context";

const Container = styled.div`
  .empty-state {
    width: 100%;
    padding: 1.6rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const SwitchFulfillmentForm = (props) => {
  // const variants = props.productBase.variants ? props.productBase.variants : [];
  const [items, setItems] = useState(props.value ? props.value : []);
  const [inputValue, setInputValue] = useState(null);
  const [options, setOptions] = useState([]);
  const [deselectedOptions, setDeselectedOptions] = useState([]);
  const [extraState, setExtraState] = useReducer(
    (prev, state) => ({ ...prev, ...state }),
    {
      isAllOrderSource: false,
      countByOS: new Map(),
      rows: [],
      mapOSByVariant: new Map(),
    },
  );
  const typingTimeoutRef = useRef(null);

  // Context
  const ctx = useSwitchFulfillment();

  useEffect(() => {
    const isAllOrderSource = allOrderSource(ctx?.orderSource);
    setExtraState({ isAllOrderSource });
  }, [ctx?.orderSource]);

  useEffect(() => {
    if (!extraState.isAllOrderSource && items.length > 0) {
      const mapOSByVariant = {};
      const count = items.reduce((acc, { variantId, orderSource }) => {
        acc[variantId] = (acc[variantId] || 0) + (orderSource?.length || 1);

        mapOSByVariant[variantId] = mapOSByVariant[variantId] || [];
        if (orderSource?.length > 0) {
          mapOSByVariant[variantId].push(...orderSource);
          mapOSByVariant[variantId] = mapOSByVariant[variantId].filter(
            (el, i, arr) => arr.lastIndexOf(el) === i,
          );
        }

        return acc;
      }, {});

      setExtraState({
        countByOS: new Map(Object.entries(count)),
        mapOSByVariant: new Map(Object.entries(mapOSByVariant)),
      });
    }
  }, [items, extraState.isAllOrderSource]);

  useEffect(() => {
    const orderSourceLength = ctx?.orderSource?.length || 0;
    const { countByOS, mapOSByVariant, isAllOrderSource } = extraState;
    const rows = items.map((item, index) => {
      const { variantId: varId } = item;
      const count = countByOS.get(varId) || 0;
      const mapOSByVar = mapOSByVariant.get(varId) || [];
      const disabled = count >= orderSourceLength;

      return [
        <div>{getVariantName(varId)}</div>,
        <SwitchFulfillmentMapToVariant
          onChange={(v) => {
            let newItems = items.map((s, i) => {
              if (i === index) {
                return v;
              }
              return s;
            });
            setItems(newItems);
            props.onChange(newItems);
          }}
          item={item}
          allFulfillment={props.allFulfillment}
          originFulfillmentID={originFulfillmentID}
          isAllOrderSource={isAllOrderSource}
          mapOSByVariant={mapOSByVar}
        />,
        <div className="action-wrap">
          <ButtonGroup>
            {!extraState.isAllOrderSource && (
              <Button
                size="slim"
                onClick={handleDuplicate(item, index)}
                icon={<Icon source={CirclePlusMinor} />}
                disabled={disabled}
              />
            )}
            <Button
              onClick={() => {
                const newItems = items.filter((v, i) => i !== index);
                setItems(newItems);
                props.onChange(newItems);
              }}
              size={"slim"}
              icon={<Icon source={DeleteMinor} />}
            />
          </ButtonGroup>
        </div>,
      ];
    });

    setExtraState({ rows });
  }, [
    items,
    extraState.countByOS,
    extraState.mapOSByVariant,
    extraState.isAllOrderSource,
    ctx?.orderSource,
  ]);

  // change variable variants => state options
  useEffect(() => {
    if (
      props &&
      props.productBase &&
      props.productBase.variants &&
      props.productBase.variants.length
    ) {
      setOptions(props.productBase.variants);
      setDeselectedOptions(props.productBase.variants);
    }
  }, [props]);

  const originFulfillmentID = get(props, "fulfillment.id");

  let map = {};
  let variantMapping = {};
  for (let i = 0; i < items.length; i++) {
    const key = items[i].variantId;
    map[key] = true;
  }
  for (let i = 0; i < deselectedOptions.length; i++) {
    variantMapping[deselectedOptions[i].id] = deselectedOptions[i];
  }
  const getVariantName = (vid) => {
    return variantMapping[vid] ? variantMapping[vid].name : "";
  };

  // Actions
  const handleDuplicate = useCallback(
    ({ orderSource, targetVariantId, ...rest }, index) =>
      () => {
        setItems((prev) => {
          const realIndex = prev.findIndex((_, curIndex) => curIndex === index);
          if (realIndex !== -1) {
            prev.splice(realIndex + 1, 0, { ...rest });
          }

          return [...prev];
        });
      },
    [],
  );

  const handleInputChange = useCallback(
    (value) => {
      if (typingTimeoutRef.current) {
        clearTimeout(typingTimeoutRef.current);
      }
      setInputValue(value);
      if ("" === value) {
        setOptions(deselectedOptions);
        return;
      }

      const filterRegex = new RegExp(value, "i");
      const resultOptions = deselectedOptions.filter((option) =>
        option.name.match(filterRegex),
      );
      typingTimeoutRef.current = setTimeout(() => {
        setOptions(resultOptions);
      }, 500);
    },
    [deselectedOptions],
  );

  return (
    <Container>
      <Card title={"Switch items"} sectioned>
        <DataTable
          columnContentTypes={["text", "text", "text"]}
          headings={["Origin Variant", "Map to Variant", "Action"]}
          rows={extraState.rows}
        />
      </Card>
      {items?.length > 0 ? <ShippingOptions items={items} /> : null}
      <Card title={"Disabled Variants"} sectioned>
        <TextField
          label="Search variants"
          labelHidden
          placeholder="Search variants"
          value={inputValue}
          onChange={handleInputChange}
          prefix={<Icon source={SearchMinor} color="inkLighter" />}
        />
        {options.length > 0 ? (
          <SwitchFulfillmentBaseVariant
            onAdd={(variant) => {
              setItems((prevState) => {
                return [
                  ...prevState,
                  {
                    variantId: variant.id,
                    targetFulfillmentId: null,
                    targetProductBaseId: null,
                    targetVariantId: null,
                  },
                ];
              });
            }}
            variants={options.filter((v) => !map[v.id])}
          />
        ) : (
          <div className="empty-state">
            <span>No items found!</span>
          </div>
        )}
      </Card>
    </Container>
  );
};

/**
 * {
    "variantId": "Jird9MZhza",
    "targetFulfillmentId": null,
    "targetProductBaseId": "0LGLMqmrko",
    "targetVariantId": "Jird9MZhza",
    "maxVariant": 2
}
 */
function genKeyLimit(item) {
  if (!item || typeof item !== "object") return;

  const { variantId, targetProductBaseId } = item;
  return [variantId, targetProductBaseId].filter(Boolean).join("-");
}

function mapOriginVariant(data, originVariantId) {
  if (!data || typeof data !== "object") return [];
  const res = {};
  for (let [key, value] of data.entries()) {
    const [newKey] = (key || "").split(/\-/);
    res[newKey] = (res[newKey] || []).concat(value);
  }

  return (res[originVariantId] || []).filter(
    (el, i, arr) => arr.lastIndexOf(el) === i,
  );
}

function allOrderSource(orderSource) {
  if (!orderSource || orderSource.length !== 1) return false;
  return orderSource[0] === "nil";
}

SwitchFulfillmentForm.propTypes = {
  productBase: PropTypes.any,
  fulfillment: PropTypes.any,
  title: PropTypes.string,
  allFulfillment: PropTypes.array,
  onChange: PropTypes.func,
  value: PropTypes.array,
};

export default SwitchFulfillmentForm;
