import { Badge, DataTable, Link } from "@shopify/polaris";
import _, { get } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useAppContext } from "../../context";
import { elementContains, throttle } from "../../helper";
import CopyBox from "../shared/CopyBox";
import { PaginationPolaris } from "../shared/PaginationPolaris";
import { ColActionPolaris } from "./ColActionPolaris";
import { ColOwnerPolaris } from "./ColOwnerPolaris";
import { ColStoreInfoPolaris } from "./ColStoreInfoPolaris";
import { ColStorePolaris } from "./ColStorePolaris";
import { SelectRowsProvider } from "./SelectRowsProvider";
import TotalProductPushed from "../tiktok/total-product-pushed";

const PaginationContainer = styled.div`
  padding: 1.6rem;
  overflow-x: scroll;
  background: var(--p-surface-subdued, #f4f6f8);
`;

export const TableStoresPolaris = ({
  data,
  filters,
  setFilters,
  isStoreManager,
  userId,
  fixedColumn,
  refetch,
}) => {
  // State
  const [coordinates, setCoordinates] = useState({
    scrollLeft: null,
    scrollWidth: null,
    offsetWidth: null,
  });
  const wrapRef = useRef(null);

  // Context
  const { isMobile } = useAppContext();

  useEffect(() => {
    if (isMobile) return;
    // Box-shadow
    let { scrollLeft, scrollWidth, offsetWidth } = coordinates;
    let addClass = offsetWidth + scrollLeft < scrollWidth;

    let domChilds = document.querySelectorAll(".actions_wrap");
    let domParents = document.querySelectorAll(".Polaris-DataTable__Cell");

    for (let domParent of domParents) {
      for (let domChild of domChilds) {
        if (elementContains(domParent, domChild)) {
          if (fixedColumn) {
            if (addClass) {
              domParent.classList.add("custom-cell");
            } else {
              domParent.classList.remove("custom-cell");
            }
          } else {
            domParent.classList.remove("custom-cell");
          }
        }
      }
    }
  }, [coordinates, fixedColumn, isMobile]);

  // Handle scroll
  useEffect(() => {
    const handler = () => {
      throttle(() => {
        if (wrapRef.current) {
          setCoordinates({
            scrollLeft: wrapRef.current.scrollLeft,
            scrollWidth: wrapRef.current.scrollWidth,
            offsetWidth: wrapRef.current.offsetWidth,
          });
        }
      });
    };

    if (wrapRef.current) {
      wrapRef.current.addEventListener("scroll", handler, {
        capture: false,
        passive: true,
      });
    }
    return () => {
      if (wrapRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        wrapRef.current.removeEventListener("scroll", handler);
      }
    };
  }, [wrapRef]);

  const getRows = (checkItemMarkup) => {
    let newRows = [];
    if (data?.stores?.nodes?.length > 0) {
      data.stores.nodes.forEach((i) => {
        let owners = i.owners;
        if (isStoreManager) {
          owners = owners.filter(
            (owner) => owner.user && owner.user.id === userId,
          );
        }

        // Group
        const groupMarkup = i?.group?.name;
        const suspended = i?.suspended;
        let statusProps = {
          children: i?.status,
          status: "",
        };
        if (i?.status === "Connected") {
          statusProps.children = "Connected";
          statusProps.status = "success";
        } else if (i?.status === "Suspended" || suspended) {
          statusProps.children = "Suspended";
          statusProps.status = "critical";
        } else if (i?.status === "Deactivated") {
          statusProps.children = "Deactivated";
          statusProps.status = "attention";
        }

        const tiktokStore = isTiktok(i);

        let rows = [
          checkItemMarkup(i.id),
          <div className="id-wrap">
            <span>{i.id}</span>
          </div>,
          <ColStorePolaris store={i} />,
          <div className="stores-wrap">
            <ColOwnerPolaris owners={owners} />
          </div>,
          <div className="group-wrap">
            <span>{groupMarkup}</span>
          </div>,
          <div className="token-wrap">
            {/* <span>{i?.apiKey}</span> */}
            {i?.apiKey && <CopyBox text={i?.apiKey || ""} />}
          </div>,
          <div className="domain">
            {tiktokStore ? null : <Link url={i.domain}>{i.domain}</Link>}
          </div>,
          <ColStoreInfoPolaris storeId={i.id} />,
          <Badge {...statusProps} />,
          <div className="count-pushed">
            <TotalProductPushed node={i}/>
          </div>,
          <div className="actions_wrap">
            <ColActionPolaris
              store={i}
              filters={filters}
              isStoreManager={isStoreManager}
              refetch={refetch}
            />
          </div>,
        ];
        newRows.push(rows);
      });
    }

    return newRows;
  };

  let { limit, offset } = filters;
  const total = _.get(data, "stores.total", 0);
  const totalPage = Math.ceil(total / limit);
  const page = offset / limit + 1;
  const aggregation = {
    page,
    totalPage,
    offset,
    limit,
    onChange: setFilters,
    total,
  };

  let newCols = Array.from({ length: 10 }).map(() => "text");
  newCols.push("numeric");
  return (
    <SelectRowsProvider data={data?.stores?.nodes || []} refetch={refetch}>
      {({ checkAllMarkup, checkItemMarkup }) => {
        return (
          <>
            <Container ref={wrapRef}>
              <DataTable
                columnContentTypes={newCols}
                headings={[
                  checkAllMarkup,
                  "ID",
                  "Store",
                  "Owners",
                  "Group",
                  "API Key",
                  "Domain",
                  "",
                  "Status",
                  "Total Pushed",
                  <div className="actions_wrap">Actions</div>,
                ]}
                rows={getRows(checkItemMarkup) || []}
                verticalAlign={"middle"}
                hideScrollIndicator
              />
            </Container>
            <PaginationContainer>
              <PaginationPolaris aggregation={aggregation} showTotal />
            </PaginationContainer>
          </>
        );
      }}
    </SelectRowsProvider>
  );
};

function isTiktok(store) {
  const platform = get(store, "platform");

  return platform === "tiktok";
}

const Container = styled.div`
  overflow-x: auto;
  overflow-y: hidden;
  .domain {
    white-space: normal;
    max-width: 250px;
  }

  .Polaris-DataTable__ScrollContainer {
    overflow-x: initial;
  }

  .Polaris-DataTable__Cell {
    &.custom-cell {
      position: sticky;
      right: 0;
      z-index: 10;
      background-color: #fff;
      &:after {
        box-shadow: rgba(0, 0, 0, 0.15) -10px 0px 8px -8px inset;
        position: absolute;
        top: 0px;
        bottom: -1px;
        width: 30px;
        transition: box-shadow 0.3s ease 0s, -webkit-box-shadow 0.3s ease 0s;
        content: "";
        pointer-events: none;
        left: 0px;
        transform: translateX(-100%);
      }
    }
  }
  .Polaris-DataTable__TableRow {
    &:hover {
      .Polaris-DataTable__Cell {
        &.custom-cell {
          background-color: var(--p-surface-hovered, #f9fafb);
        }
      }
    }
  }
  .Polaris-DataTable__TableRow {
    &:last-child {
      .stores-wrap {
        padding-bottom: 2rem;
      }
    }
  }

  .group-wrap,
  .id-wrap {
    white-space: normal;
    width: 20rem;
  }

  .token-wrap {
    white-space: break-spaces;
    width: calc(28rem - 3.2rem);
  }
`;
