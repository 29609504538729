import { Mutation } from "@apollo/react-components";
import { useMutation } from "@apollo/react-hooks";
import {
  Button,
  DropZone,
  Icon,
  InlineError,
  Spinner,
  Stack,
  TextStyle,
  Toast,
} from "@shopify/polaris";
import { TickMinor } from "@shopify/polaris-icons";
import { gql } from "apollo-boost";
import axios from "axios";
import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useAppContext } from "../../context";
import { DELETE_FILE } from "../../graphql/mutations";
import { checkRole, formatBytes } from "../../helper";
import { FILE_SIZE_50MB, TUMBLER_BASE_IDS } from "../../variable";
import {
  CREATE_FILE_MUTATION,
  REQUEST_MUTATION,
} from "../file/UploadFilePolaris";
import { LOAD_MEDIA_QUERY, MediaPolaris } from "./MediaPolaris";
import { PreviewFilePolaris } from "./PreviewFilePolaris";

const IMPORT_FILE_URLS_MUTATION = gql`
  mutation importFileUrl($urls: [String!]!, $prefix: String) {
    importFileUrl(urls: $urls, prefix: $prefix) {
      id
      name
      alt
      caption
      thumbnailUrl
      url
      mimeType
      size
      createdAt
    }
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  width: 100%;
  .list-file_wrap {
    display: flex;
    flex-direction: column;
    row-gap: 1.6rem;
    height: 28rem;
    &.multiple {
      height: auto;
      flex-direction: row;
      column-gap: 2rem;
      justify-content: flex-start;
      flex-wrap: wrap;
      .file_wrap {
        height: 100%;
      }
      .file-item {
        width: 100px;
        height: 100px;
        overflow: hidden;
        object-fit: cover;
        position: relative;
        .Polaris-Choice {
          position: absolute;
          top: 1px;
          left: 1px;
          z-index: 10;
          padding: 0;
          .Polaris-Choice__Control {
            margin: 0;
          }
        }
      }
    }
  }
  .dropzone_wrap {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    .dropzone_inner {
      display: flex;
      flex-direction: column;
      text-align: center;
    }
  }
  .uploaded-wrap {
    display: block;
    margin-top: 2rem;
  }
  .image-wrap {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .image-title-wrap {
      display: flex;
      flex-direction: row;
      column-gap: 0.5rem;
    }
  }
  .design-position-name {
    margin-bottom: 0.5rem;
    font-weight: 500;
  }
  .dropzone_container {
    .Polaris-DropZone {
      min-height: ${(props) => (props.sizeSmall ? "10rem" : "")};
    }
  }
`;

const MIME_TYPE_SPECs = ["application/pdf", "application/postscript"];

export const MediaSelectorButtonPolaris = React.forwardRef((props, ref) => {
  const {
    multiple,
    showViewOrigin,
    isUpdateDesign,
    dPName,
    accept,
    type,
    folder,
    isPrintFile,
    typeSpecial,
    placeholderImage,

    isCompareDesign,
    designPosition,
    sizeSmall,
    mainImageId,
    setMainImage: setMainImageProp,
    hasMainImg,
    productBaseId,
    maxSize,
    isUniqueFolder,
    isArtistTask,
    softDeleteFile = false
  } = props;
  // Context
  const { currentUser } = useAppContext();
  const { isTeamRoot } = checkRole(currentUser);
  let maxSizeReal = maxSize;
  // case falsy
  if (!maxSize && !isTeamRoot) {
    maxSizeReal = FILE_SIZE_50MB;
  }

  // State
  const [value, setValue] = useState([]);
  const [importUrls, setImportUrls] = useState("");
  const [showLibrary, setShowLibrary] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [timeoutId, setTimeoutId] = useState(null);
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState({});
  const [isRemoved, setIsRemoved] = useState(false);

  const [, setMainImage] = useState({});
  const [dimensions, setDimensions] = useState({});
  const [toastActive, setToastActive] = useState(false);
  const [notify, setNotify] = useState({
    msg: null,
  });

  const [errMaxSize, setErrMaxSize] = useState(false);

  // Mutation
  const [deleteFile] = useMutation(DELETE_FILE, {
    onError: () => {},
  });

  useEffect(() => {
    if (props.onChange) {
      props.onChange(fileList);
    }
    if (!fileList.length) {
      if (showViewOrigin) {
        showViewOrigin();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileList]);

  const valueRef = useRef(false);
  useEffect(() => {
    let propsValue = props.value;
    if (propsValue?.length > 0 && !valueRef.current) {
      setFileList(propsValue);
      valueRef.current = true;

      return () => clearTimeout(timeoutId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value]);

  // Handle actions
  const toggleToastActive = useCallback(
    () => setToastActive((prev) => !prev),
    [],
  );
  const toggleShowLibrary = useCallback(
    () => setShowLibrary((prev) => !prev),
    [],
  );

  const setCache = useCallback(
    (client, values) => {
      let { userId } = props;
      const filter = {
        search: null,
        mimeType: [],
        dateFilter: {
          from: null,
          to: null,
        },
        limit: 20,
        offset: 0,
        prefix: props.prefix,
        ...(userId ? { userId } : null),
      };
      try {
        const cache = client.readQuery({
          query: LOAD_MEDIA_QUERY,
          variables: {
            filter,
          },
        });
        client.writeQuery({
          query: LOAD_MEDIA_QUERY,
          variables: {
            filter,
          },
          data: {
            ...cache,
            mediaLibrary: {
              ...cache.mediaLibrary,
              total: cache.mediaLibrary.total + values.length,
              nodes: [...values, ...cache.mediaLibrary.nodes],
            },
          },
        });
      } catch (e) {}
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.userId, props.prefix],
  );

  const handleSetFile = useCallback(
    (client) => {
      let { prefix, multiple } = props;
      let propsValue = props.value ? props.value : [];
      let files = value;
      let v = [];
      for (let i = 0; i < files.length; i++) {
        if (!propsValue.find((f) => f.id === files[i].id)) {
          v.push(files[i]);
        }
      }

      const newValue = multiple ? [...propsValue, ...v] : [...v];
      let newImportUrls = [];
      let mapUrl = {};
      importUrls.split("\n").forEach((line) => {
        const url = line && line.trim();
        if (url) {
          if (!mapUrl[url]) {
            newImportUrls.push(url);
            mapUrl[url] = true;
          }
        }
      });

      if (newImportUrls.length) {
        client
          .mutate({
            mutation: IMPORT_FILE_URLS_MUTATION,
            variables: {
              urls: newImportUrls,
              prefix: prefix,
            },
          })
          .then((res) => {
            setCache(client, res.data.importFileUrl);
            const values = [...res.data.importFileUrl, ...newValue];
            setImportUrls("");

            const id = setTimeout(() => {
              setFileList(props.multiple ? values : [values[0]]);
              setValue([]);
              toggleShowLibrary();
              if (props.onChange) {
                props.onChange(props.multiple ? values : [values[0]]);
              }
            }, 1000);
            setTimeoutId(id);
          })
          .catch(() => {});
      } else {
        setFileList(newValue);
        setValue([]);
        toggleShowLibrary();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props, value, importUrls],
  );

  const handleRemove = useCallback((id) => {
    setIsRemoved(true);
    setFileList((prevState) => prevState.filter((i) => i.id !== id));

    if (softDeleteFile) {
      deleteFile({
        variables: {
          id,
          deleteAmz: true,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [softDeleteFile]);

  // Handle drop
  const handleDrop = useCallback(
    (_droppedFiles, acceptedFiles, _rejectedFiles, createUploadUrl, client) => {
      setFiles((files) => {
        if (multiple) {
          return [...files, ...acceptedFiles];
        } else {
          return [...acceptedFiles];
        }
      });
      setIsRemoved(false);

      let newFiles = acceptedFiles;
      for (let i = 0; i < newFiles.length; i++) {
        customRequest(newFiles[i], i, createUploadUrl, client);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [productBaseId],
  );

  const customRequest = useCallback(
    (file, index, createUploadUrl, client) => {
      setLoading((prevState) => ({ ...prevState, [index]: true }));
      // setLoadingCtx(true);
      toggleToastActive(true);
      checkMimeType(file, async (mime) => {
        let fileType = file.type;
        let fileName = file.name;
        if (mime) {
          fileType = mime;
          switch (mime) {
            case "image/webp":
              fileName = fileName.replace(/\.[^/.]+$/, ".webp");
              break;
            case "image/gif":
              fileName = fileName.replace(/\.[^/.]+$/, ".gif");
              break;
            case "image/jpeg":
              fileName = fileName.replace(/\.[^/.]+$/, ".jpg");
              break;
            case "image/png":
              fileName = fileName.replace(/\.[^/.]+$/, ".png");
              break;
            default:
              break;
          }
        }
        try {
          let isTumbler = TUMBLER_BASE_IDS.includes(productBaseId);
          let canCreateFile = false;
          if (isTumbler) {
            if ("image/png" === fileType) {
              canCreateFile = true;
            }
          } else {
            canCreateFile = true;
          }
          if (canCreateFile) {
            setNotify(() => ({ msg: null }));
            const res = await createUploadUrl({
              variables: {
                input: {
                  name: fileName,
                  mimeType: fileType,
                  size: file.size,
                  folder: folder ? folder : null,
                },
                uniqueFolder: isUniqueFolder ? true : undefined,
              },
            });
            const { key, url } = res.data.createUploadUrl;
            await axios.put(url, file, {
              headers: { "Content-Type": fileType },
              timeout: 1000 * 60 * 30,
            });
            const fileResponse = await client.mutate({
              mutation: CREATE_FILE_MUTATION,
              variables: {
                input: {
                  name: fileName,
                  key: key,
                  size: file.size,
                  mimeType: fileType,
                  isPrintFile: isPrintFile,
                },
              },
            });
            let respon = [];

            if (fileResponse) {
              respon.push(fileResponse.data.createFile);
              setLoading((prevState) => ({ ...prevState, [index]: false }));
            }
            const id = setTimeout(() => {
              setFileList((prevState) => [...prevState, ...respon]);
            }, 1000);
            setTimeoutId(id);
          } else {
            setLoading(false);
            setNotify(() => ({ msg: "Allow file type is .png" }));
            setFiles(() => []);
          }
        } catch (e) {}
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [productBaseId, props, value],
  );

  const checkMimeType = (file, cb) => {
    const mimes = [
      {
        mime: "image/png",
        pattern: [0x89, 0x50, 0x4e, 0x47],
      },
      {
        mime: "image/jpeg",
        pattern: [0xff, 0xd8, 0xff],
      },
      {
        mime: "image/gif",
        pattern: [0x47, 0x49, 0x46, 0x38],
      },
      {
        mime: "image/webp",
        pattern: [
          0x52,
          0x49,
          0x46,
          0x46,
          undefined,
          undefined,
          undefined,
          undefined,
          0x57,
          0x45,
          0x42,
          0x50,
          0x56,
          0x50,
        ],
      },
    ];
    const isMime = (bytes, mime) => {
      return mime.pattern.every((p, i) => !p || bytes[i] === p);
    };
    const numBytesNeeded = Math.max(...mimes.map((m) => m.pattern.length));
    const blob = file.slice(0, numBytesNeeded);
    const reader = new FileReader();
    reader.onloadend = (e) => {
      if (!e || !reader.result) return cb(null);
      const bytes = new Uint8Array(reader.result);
      const mime = mimes.find((m) => isMime(bytes, m));
      return cb(mime ? mime.mime : null);
    };
    reader.readAsArrayBuffer(blob);
  };

  // Check dimension image
  useEffect(() => {
    for (let i = 0; i < fileList.length; i++) {
      let file = fileList[i];
      if (file) {
        imageLoadHandler(
          isCompareDesign,
          file.url,
          file.id,
          designPosition,
          file.mimeType,
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileList, isCompareDesign, designPosition]);

  const imageLoadHandler = useCallback(
    (isCompareDesign, originUrl, imgIndex, designPosition, mimeType) => {
      const { onCheckDimensions, onCheckingDimensions } = props;
      if (MIME_TYPE_SPECs.includes(mimeType)) {
        // Disable check dimension.
        if (onCheckingDimensions) {
          onCheckingDimensions(false);
        }
        if (onCheckDimensions) {
          onCheckDimensions(true);
        }
      } else {
        if (isCompareDesign) {
          let img = new Image();
          img.src = originUrl;
          if (onCheckingDimensions) {
            onCheckingDimensions(true);
          }
          img.onload = () => {
            let newDimensions = dimensions;
            if (!newDimensions[imgIndex]) {
              let height = img?.height;
              let width = img.width;

              newDimensions[imgIndex] = {
                id: imgIndex,
                height,
                width,
                isRealDimensionOk: checkRealDimension(
                  designPosition,
                  width,
                  height,
                ),
              };
              setDimensions(() => {
                if (onCheckDimensions) {
                  onCheckDimensions(newDimensions, imgIndex);
                }
                return { ...newDimensions };
              });
            }
            if (img?.complete && img?.naturalHeight !== 0) {
              if (onCheckingDimensions) {
                onCheckingDimensions(false);
              }
            }
          };
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dimensions],
  );

  const checkRealDimension = useCallback(
    (designPosition, realWidth, realHeight) => {
      let realDmOk = false;
      if (
        designPosition &&
        designPosition.description &&
        designPosition.description.length > 0 &&
        realWidth &&
        realHeight
      ) {
        let originDesc = designPosition.description;

        realWidth = parseInt(realWidth);
        realHeight = parseInt(realHeight);

        if (originDesc) {
          let splitDesc = originDesc.split("|");
          if (
            Array.isArray(splitDesc) &&
            splitDesc[0] &&
            splitDesc[0].length > 0
          ) {
            let dimensionDesc = splitDesc[0];
            const isInchUnit = /in/i.test(dimensionDesc); // Inches

            // Case: no dimension
            const dimensionValid = /px|cm|in/i;
            if (!dimensionValid.test(dimensionDesc)) {
              return true;
            }
            dimensionDesc = dimensionDesc.replace(/,/g, "");
            dimensionDesc = dimensionDesc.replace(/,/g, "");
            dimensionDesc = dimensionDesc.replace(/px/g, "");
            dimensionDesc = dimensionDesc.replace(/cm/g, "");
            dimensionDesc = dimensionDesc.replace(/\s+/g, "");
            if (dimensionDesc.length > 0) {
              const likeX = "×";
              let dmDesc = dimensionDesc.split("x");
              if (Array.isArray(dmDesc) && 2 !== dmDesc.length) {
                dmDesc = dimensionDesc.split(likeX);
              }
              if (Array.isArray(dmDesc) && 2 === dmDesc.length) {
                let splitWidth = dmDesc && dmDesc[0] ? parseInt(dmDesc[0]) : 0;
                let splitHeight = dmDesc && dmDesc[1] ? parseInt(dmDesc[1]) : 0;
                if (isInchUnit) {
                  splitWidth = splitWidth * parseInt(splitDesc[2]);
                  splitHeight = splitHeight * parseInt(splitDesc[2]);
                }
                if (
                  splitWidth > 0 &&
                  splitHeight > 0 &&
                  splitWidth === realWidth &&
                  splitHeight === realHeight
                ) {
                  realDmOk = true;
                }
              }
            }
          }
        }
      }
      return realDmOk;
    },
    [],
  );

  // Drop markup
  const uploadedFiles =
    files.length > 0 ? (
      <Stack vertical>
        {files.map((file, index) => (
          <div className="image-wrap" key={`image-${index}`}>
            <div className="image-title-wrap">
              {loading[index] ? (
                <Spinner size="small" />
              ) : (
                <Icon source={TickMinor} />
              )}
              <span>{file.name}</span>
            </div>
          </div>
        ))}
      </Stack>
    ) : null;

  // Markup
  const toastMarkup = toastActive && notify.msg && (
    <Toast
      content={notify.msg}
      error={true}
      duration={2000}
      onDismiss={toggleToastActive}
    />
  );

  const handleCustomValidator = (file) => {
    const isFile = file instanceof File;
    if (!isFile || maxSizeReal == null || typeof maxSizeReal !== "number") {
      setErrMaxSize(false);
      return true;
    }

    if (!file.size || file.size < 1 || file.size > maxSizeReal) {
      setErrMaxSize(true);
      return false;
    }

    setErrMaxSize(false);
    return true;
  };

  React.useImperativeHandle(ref, () => ({
    getFileList: () => fileList,
  }));

  return (
    <Container sizeSmall={sizeSmall}>
      {toastMarkup}
      <Mutation mutation={REQUEST_MUTATION}>
        {(createUploadUrl, { client }) => (
          <>
            {multiple || typeSpecial || (!multiple && !fileList.length > 0) ? (
              // <div className="dropzone_wrap">
              //   <div className="dropzone_inner">
              //     <Button
              //       children={`Media Library`}
              //       plain
              //       onClick={toggleShowLibrary}
              //     />
              //     <TextStyle>Or drop image to upload</TextStyle>
              //   </div>
              //   <MediaPolaris
              //     {...props}
              //     value={value}
              //     open={showLibrary}
              //     toggleShowModal={() => {
              //       toggleShowLibrary();
              //       setImportUrls(() => "");
              //     }}
              //     onChange={(files) => setValue(() => files)}
              //     onImportUrlsChange={(v) => setImportUrls(() => v)}
              //     handleSetFile={handleSetFile}
              //   />
              // </div>
              <div className="dropzone_container">
                <div className="design-position-name">{dPName}</div>
                <DropZone
                  accept={accept}
                  type={type}
                  allowMultiple={multiple}
                  onClick={() => {}}
                  customValidator={handleCustomValidator}
                  onDrop={(droppedFiles, acceptedFiles, rejectedFiles) =>
                    handleDrop(
                      droppedFiles,
                      acceptedFiles,
                      rejectedFiles,
                      createUploadUrl,
                      client,
                    )
                  }
                >
                  <div
                    className="dropzone_wrap"
                    style={{
                      backgroundImage: `url("${
                        placeholderImage?.thumbnailUrl || placeholderImage?.url
                      }")`,
                      opacity: placeholderImage ? 0.7 : 1,
                    }}
                  >
                    <div className="dropzone_inner">
                      <Button
                        children={`Media Library`}
                        plain
                        onClick={toggleShowLibrary}
                      />
                      <TextStyle>Or drop image to upload</TextStyle>
                      {!isRemoved ? (
                        <div className="uploaded-wrap">{uploadedFiles}</div>
                      ) : null}
                    </div>
                  </div>
                </DropZone>
                <MediaPolaris
                  {...props}
                  maxSize={maxSizeReal}
                  value={value}
                  open={showLibrary}
                  toggleShowModal={() => {
                    toggleShowLibrary();
                    setImportUrls(() => "");
                  }}
                  onChange={(files) => setValue(() => files)}
                  onImportUrlsChange={(v) => setImportUrls(() => v)}
                  handleSetFile={handleSetFile}
                  uniqueFolder={isUniqueFolder ? true : undefined}
                />
              </div>
            ) : null}
            {errMaxSize ? (
              <InlineError
                message={`File is too large (max ${formatBytes(maxSizeReal)})`}
              />
            ) : null}
            {fileList && fileList.length > 0 ? (
              <div
                className={`list-file_wrap ${
                  multiple || typeSpecial ? "multiple" : ""
                }`}
              >
                {fileList.map((file, index) => {
                  let isMainImg = mainImageId === file.id;
                  return (
                    <PreviewFilePolaris
                      file={file}
                      index={index}
                      key={index}
                      handleRemove={handleRemove}
                      isUpdateDesign={isUpdateDesign}
                      dPName={dPName}
                      isMainImg={isMainImg}
                      hasMainImg={hasMainImg}
                      mainImage={{ [file.id]: isMainImg }}
                      hideBtnRemove={!!isArtistTask}
                      setMainImage={(value) => {
                        setMainImage({ [file.id]: value });
                        if (setMainImageProp) {
                          setMainImageProp(file.id);
                        }
                      }}
                    />
                  );
                })}
              </div>
            ) : null}
          </>
        )}
      </Mutation>
    </Container>
  );
});
