import { get } from "lodash";
import React from "react";
import styled from "styled-components";

import { sortDesignPosition } from "../../../helper";
import { CompareDesignTabsPolaris } from "../actions/CompareDesignTabsPolaris";

function TableDataNeedReviewDesign({ node }) {
  const designs = getDesigns(node);

  const { orderId, requirement } = node || {};
  return (
    <Wrapper>
      <CompareDesignTabsPolaris
        designs={designs}
        orderId={orderId}
        taskRequirement={requirement}
      />
    </Wrapper>
  );
}

function getDesigns(node) {
  let designs = get(node, "taskSubmission.designs") || [];
  return sortDesignPosition(designs);
}

const Wrapper = styled.div`
  width: 600px;

  .modal-image-item {
    flex-direction: column;
    align-items: start !important;

    .modal-image-info {
      padding-left: 1rem;
    }
  }
`;

export default TableDataNeedReviewDesign;
