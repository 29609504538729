import { useMutation } from "@apollo/react-hooks";
import { Modal } from "@shopify/polaris";
import React, { useCallback, useRef } from "react";
import { BULK_ASSIGN_TO_MARKETPLACE_MANAGER } from "../../../graphql/mutations";
import { arrInvalid, handleError } from "../../../helper";
import { TEAM_ROLE } from "../../../variable";
import { useToastContext } from "../../shared/ToastContext";
import MarketplaceManagerSelect from "../../marketplace-manager/marketplace-manager-select";
import { useSelectRowsCtx } from "../SelectRowsProvider";

function BulkUpdateMarketplaceManager({ open, onClose }) {
	const { toggleToast, setNotify } = useToastContext();
	const { ids, onCompleted } = useSelectRowsCtx();

	const [bulkAssignToMarketplaceManager, { loading }] = useMutation(
		BULK_ASSIGN_TO_MARKETPLACE_MANAGER,
		{
			onCompleted: () => {
				setNotify({
					msg: "Update marketplace manager success",
					err: false,
				});
				onCompleted && onCompleted();
			},
			onError: (err) => {
				setNotify({ msg: handleError(err?.toString()), err: true });
			},
		}
	);

	const smRef = useRef(null);

	const handleSubmit = useCallback(() => {
		if (arrInvalid(ids)) return;
		const curRef = smRef.current;

		let marketplaceManagerID = '';
		if (curRef) {
			const userIDs = curRef.getSelected();
			if (Array.isArray(userIDs) && userIDs.length > 0) {
				marketplaceManagerID = userIDs[0];
			}
		}
		if (marketplaceManagerID.length == 0) {
			return;
		}

		setNotify({ msg: null, err: false });
		toggleToast(true);

		bulkAssignToMarketplaceManager({
			variables: {
				marketplaceManagerID,
				storeIds: ids,
			},
		});
	}, [bulkAssignToMarketplaceManager, ids, setNotify, toggleToast]);

	return (
		<Modal
			title="Update marketplace manager"
			sectioned
			open={open}
			onClose={onClose}
			primaryAction={{
				content: "Submit",
				onAction: handleSubmit,
				loading: loading,
			}}
			secondaryActions={[{ content: "Cancel", onAction: onClose }]}
		>
			<MarketplaceManagerSelect
				roles={TEAM_ROLE.MarketplaceManager}
				label="Choose marketplace manager"
				required
				ref={smRef}
			/>
		</Modal>
	);
}

export default BulkUpdateMarketplaceManager;
