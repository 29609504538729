import React from "react";
import { getMainImages } from "../../../helper";
import { get } from "lodash";
import MockupsPreview from "./MockupsPreview";

function TableDataNeedReviewMockup({ order }) {
  const productInfo = getProductInfo(order) || {};
  const mainImages = getMainImages({
    ...productInfo,
    pBaseId: getProductBaseID(order),
  });

  return (
    <MockupsPreview
      width="auto"
      maxWidth="100%"
      height="auto"
      files={{ files: mainImages }}
      originImages={productInfo?.images || []}
      hasShadow
      isCompare
    />
  );
}

function getProductBaseID(order) {
  return order?.productVariant?.productBaseVariant?.productBase
    ? order.productVariant.productBaseVariant.productBase.id
    : order?.product?.productBases[0]?.id;
}

function getProductInfo(order) {
  const p = get(order, "product", null);

  const mainImageId = p?.mainImageId;
  const images = p?.images;
  const isCampaign = p?.productBases?.length > 1;

  return { mainImageId, images, isCampaign };
}

export default TableDataNeedReviewMockup;
