import { useMutation } from "@apollo/react-hooks";
import { Modal } from "@shopify/polaris";
import React, { useCallback } from "react";
import { useSelectRowsCtx } from "../../../shared/SelectRow";
import { BULK_DELETE_TIKTOK_CRAWL_PRODUCTS } from "../../../../graphql/mutations";
import { useToastContext } from "../../../shared/ToastContext";
import { arrInvalid, handleError } from "../../../../helper";

function BulkDeleteCrawlItem({ open, onClose }) {
  const { ids, onCompleted } = useSelectRowsCtx();
  const { setNotify, toggleToast } = useToastContext();

  const [bulkDelete, { loading }] = useMutation(
    BULK_DELETE_TIKTOK_CRAWL_PRODUCTS,
    {
      onCompleted: () => {
        setNotify({
          msg: "The product crawl items deleted success",
          err: false,
        });
        onCompleted && onCompleted();
      },
      onError: (err) => {
        setNotify({ msg: handleError(err?.toString()), err: true });
      },
    },
  );

  const handleSubmit = useCallback(() => {
    if (arrInvalid(ids)) return;

    toggleToast(true);
    setNotify({ msg: null, err: false });

    bulkDelete({
      variables: {
        ids,
      },
    });
  }, [toggleToast, setNotify, bulkDelete, ids]);

  return (
    <Modal
      title="Bulk Delete Product Crawl Item"
      open={open}
      onClose={onClose}
      sectioned
      primaryAction={{
        content: "Submit",
        onAction: handleSubmit,
        loading: loading,
      }}
      secondaryActions={[{ content: "Cancel", onAction: onClose }]}
    >
      <p>Are you sure to delete Product crawl items?</p>
    </Modal>
  );
}

export default BulkDeleteCrawlItem;
