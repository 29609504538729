import React, { useState, useEffect, useCallback, useReducer } from "react";
import {
	Card,
	TextField,
	Button,
	Stack,
	Tag,
	Heading,
	TextContainer,
	Toast,
	Icon,
	Checkbox,
	Modal,
	Collapsible,
	TextStyle,
	ButtonGroup,
} from "@shopify/polaris";
import { TagContainer } from "../../shared/TagContainer";
import { DeleteMinor } from "@shopify/polaris-icons";
import styled from "styled-components";
import debounce from "lodash/debounce";
import { render } from "react-dom";
import { reducerFn, sleep } from "../../../helper";
import { useToastContext } from "../../shared/ToastContext";

const Container = styled.div`
	margin-bottom: 20px;
	
	.variant-items:first-child {
		.Polaris-Choice {
			margin-top: 25px;
		}
		.Polaris-Button {
			margin-top: 24px;
		}
	}
	.variant-items {
		padding: 15px 0;
		border-bottom: 1px solid #eee;

		.Polaris-Stack__Item:last-child {
			flex-grow: 1;
			text-align: right;
		}
	}
	.variant-items:last-child {
		border-bottom: 0;
	}

	.bulk-actions-wrap {
		.Polaris-Stack__Item:last-child {
			text-align: right;
		}
	}

	.divider {
		margin-bottom: 20px;
	}
`;

const TiktokVariantsGenerator = ({ initialData, onDataChange, error }) => {
	const [attributeInput, setAttributeInput] = useState("");
	const [attributes, setAttributes] = useState([]);
	const [variants, setVariants] = useState([]);
	const [attributeValue, setAttributeValue] = useState({});
	const [toastActive, setToastActive] = useState(false);
	const [selectedVariants, setSelectedVariants] = useState([]);
	const [bulkPrice, setBulkPrice] = useState("");
	const [bulkQuantity, setBulkQuantity] = useState("");
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [runGenerateVariants, setRunGenerateVariants] = useState(false);

	const [state, setState] = React.useReducer(reducerFn, {
		tmpDelete: new Set(),
	})

	const { setNotify, toggleToast } = useToastContext()
	const [bulkActionstate, setBulkActionState] = useReducer(reducerFn, {
		loading: false,
	})

	useEffect(() => {
		if (initialData.attributes && initialData.attributes.length > 0) {
			setAttributes(
				initialData.attributes.map((attr) => ({
					name: attr.name,
					values: attr.value,
				}))
			);
		}
		if (initialData.variants && initialData.variants.length > 0) {
			setVariants(
				initialData.variants.map(
					({ warehouse_id, ...variant }) => variant
				)
			);
		}
	}, [initialData]);

	// Notify parent component of data changes
	const sendDataToParent = () => {
		if (onDataChange) {
			onDataChange({
				attributes: attributes.map((attr) => ({
					name: attr.name,
					value: attr.values,
				})),
				variants,
			});
		}
	};

	useEffect(() => {
		sendDataToParent();
	}, [JSON.stringify(attributes), JSON.stringify(variants)]); // Send data whenever attributes or variants change

	// Handle adding new attributes
	const handleAttributeInputChange = (value) => {
		if (value.includes(",")) {
			const newName = value.replace(",", "").trim();
			if (newName && !attributes.some((attr) => attr.name === newName)) {
				setAttributes([...attributes, { name: newName, values: [] }]);
				setRunGenerateVariants(true);
			}
			setAttributeInput("");
		} else {
			setAttributeInput(value);
		}
	};

	// Handle adding values to attributes
	const handleAddValue = (name, value) => {
		setAttributes((prev) =>
			prev.map((attr) =>
				attr.name === name && value && !attr.values.includes(value)
					? { ...attr, values: [...attr.values, value] }
					: attr
			)
		);
		setRunGenerateVariants(true);
	};

	// Handle removing an attribute
	const handleRemoveAttribute = (name) => {
		setAttributes((prev) => prev.filter((attr) => attr.name !== name));
		setRunGenerateVariants(true);
	};

	// Handle removing an attribute value
	const handleRemoveValue = (attrName, value) => {
		setAttributes((prev) =>
			prev.map((attr) =>
				attr.name === attrName
					? {
							...attr,
							values: attr.values.filter((val) => val !== value),
					  }
					: attr
			)
		);

		const newVars = removeVariantByAttr(attrName, value, variants);
		setVariants([...newVars])
		setRunGenerateVariants(true);
	};

	// Handle removing a variant
	const handleRemoveVariant = (index) => {
		var conf = window.confirm("Are you sure to delete this variant");
		if (conf) {
			let varToDelete = (variants || []).find((_, i ) => i=== index);
			setVariants((prev) => {
				const updatedVariants = prev.filter((_, i) => i !== index);
				if (updatedVariants.length === 0) {
					setAttributes([]); // Reset attributes when variants are deleted
					setRunGenerateVariants(true);
				}
				return updatedVariants;
			});

			if (varToDelete) {
				const genKey = generateKeyV2(varToDelete.attributes || [])
				setState(p => ({...p, tmpDelete: p.tmpDelete.add(genKey)}))
			}
			setRunGenerateVariants(false);
		}
	};

	const handleRemoveVariants = (selectToRemove) => () => {
		var conf = window.confirm("Are you sure to delete this variant");
		if (conf) {
			setVariants((prev) => {
				const updatedVariants = prev.filter((_, i) => !selectToRemove.includes(i));
				if (updatedVariants.length === 0) {
					setAttributes([]); // Reset attributes when variants are deleted
					setRunGenerateVariants(true);
				}
				return updatedVariants;
			});

			setSelectedVariants([])
		}
	};

	useEffect(() => {
		if (runGenerateVariants) {
			if (attributes && attributes.length > 0) {
				generateVariants();
			} else {
				setVariants([]);
			}
		}
	}, [attributes]);

	// Generate variants based on attribute values
	const generateVariants_old = () => {
		const attributeCombinations = (attrs) => {
			if (attrs.length === 0) return [[]];
			const [first, ...rest] = attrs;
			const combinations = attributeCombinations(rest);
			return first.values.flatMap((value) =>
				combinations.map((combo) => [
					{ attr_name: first.name, attr_value: value },
					...combo,
				])
			);
		};

		const newVariants = attributeCombinations(attributes).map(
			(combination, index) => ({
				price: 0,
				quantity: 100,
				attributes: combination,
			})
		);

		setVariants(newVariants);
	};

	const generateVariants = () => {
		// Helper function to generate combinations of attributes
		const attributeCombinations = (attrs) => {
			if (attrs.length === 0) return [[]];
			const [first, ...rest] = attrs;
			const combinations = attributeCombinations(rest);
			return first.values.flatMap((value) =>
				combinations.map((combo) => [
					{ attr_name: first.name, attr_value: value },
					...combo,
				])
			);
		};

		// Generate a key for a combination of attributes
		const generateKey = (attributes) => {
			return attributes
				.sort((a, b) => a.attr_name.localeCompare(b.attr_name)) // Sort by attribute name
				.map((attr) => `${attr.attr_name}:${attr.attr_value}`) // Create "name:value"
				.join("|"); // Join with |
		};

		// Get existing keys from current variants
		const existingKeys = new Set(
			variants.map((variant) => generateKey(variant.attributes))
		);

		// ignore variant deleted
		if (state.tmpDelete.size > 0) {
			for (let item of state.tmpDelete) {
				existingKeys.add(item)
			}
		}

		// Generate new combinations
		const newVariants = attributeCombinations(attributes)
			.map((combination) => ({
				attributes: combination,
				key: generateKey(combination),
			}))
			.filter(({ key }) => !existingKeys.has(key)) // Filter out existing variants
			.map(({ attributes }) => ({
				price: 0,
				quantity: 100,
				attributes,
			}));

		// Update the variants state
		setVariants([...variants, ...newVariants]);
	};

	// Update variant price or quantity
	const handleVariantChange = (index, field, value) => {
		setVariants((prev) => {
			const updated = [...prev];
			updated[index][field] = value;
			return updated;
		});
	};

	// Handle toast notification
	const handleToast = () => {
		setToastActive(true);
		setTimeout(() => setToastActive(false), 3000);
	};

	// Handle select/deselect variants for bulk actions
	const toggleSelectVariant = (index) => {
		setSelectedVariants((prev) => {
			if (prev.includes(index)) {
				return prev.filter((i) => i !== index);
			} else {
				return [...prev, index];
			}
		});
	};

	const toggleSelectAll = () => {
		if (selectedVariants.length === variants.length) {
			setSelectedVariants([]);
		} else {
			setSelectedVariants(variants.map((_, index) => index));
		}
	};

	const attrRef = React.useRef({});
	const handleBulkAction = async () => {
		toggleToast(true);
		setNotify({ msg: null, err: false })
		setBulkActionState({ loading: true})

		// Check if bulkPrice and bulkQuantity have valid values
		if (bulkPrice === "" && bulkQuantity === "") {
			return;
		}

		let newSelectVariants = [...selectedVariants]
		if (newSelectVariants?.length === 0 ) {
			const attrSelect = {};
			for (let [key, fn] of Object.entries(attrRef.current)) {
				if ('getState' in fn && typeof fn.getState === 'function') {
					attrSelect[key] = fn.getState();
				}
			}

			newSelectVariants = mapVariantFromAttr_And(attrSelect, variants);
		}

		// Apply the bulk price and quantity to selected variants
		setVariants((prevVariants) => {
			const updatedVariants = prevVariants.map((variant, index) => {
				// if (selectedVariants.includes(index)) {
				if (newSelectVariants.includes(index)) {
					return {
						...variant,
						price:
							bulkPrice !== ""
								? parseFloat(bulkPrice)
								: variant.price,
						quantity:
							bulkQuantity !== ""
								? parseInt(bulkQuantity)
								: variant.quantity,
					};
				}
				return variant;
			});

			return updatedVariants;
		});


		await sleep(250);
		setNotify({ msg: "Apply Price/Quantity success", err: false });
		setBulkActionState({ loading: false})
		// setIsModalOpen(false); // Close the modal after applying the bulk action
	};


	const placholderForAttrValue = (attr_name) => {
		let lowerAttrName = attr_name.toLowerCase();
		let placeholder = '';

		if (lowerAttrName.includes('color')) {
			placeholder = `e.g., Red, Blue for ${attr_name}`
		} else if (lowerAttrName.includes('size')) {
			placeholder = `e.g., X, S for ${attr_name}`
		} else if (lowerAttrName.includes('type')) {
			placeholder = `e.g., Hoodie, T-Shirt for ${attr_name}`
		}

		return placeholder;
	}

	return (
		<Container>
			<Card sectioned>
				<Heading element="h3">Product Attributes:</Heading>
				{attributes && attributes.length && attributes.length >= 3 ? null : (
					<TextField
						label="Enter attribute names (comma to confirm). Max 3 attribute names."
						value={attributeInput}
						onChange={handleAttributeInputChange}
						placeholder="e.g., Color, Size, Type"
						disabled={attributes && attributes.length && attributes.length >= 3 ? true : false }
					/>
				) }
				
				<div className="divider" style={{ marginTop: "20px" }}></div>
				{attributes.map((attr) => (
					<Card
						key={attr.name}
						title={`Values for: ${attr.name}`}
						sectioned
						actions={[
							{
								content: "Remove",
								destructive: true,
								onAction: () =>
									handleRemoveAttribute(attr.name),
							},
						]}
					>
						<div>
							<TextField
								label={`Enter attribute values (comma to confirm)`}
								placeholder={placholderForAttrValue(attr.name)}
								value={attributeValue[attr.name] ?? null}
								onChange={(value) => {
									setAttributeValue((prev) => ({
										...prev,
										[attr.name]: value,
									}));

									if (value.includes(",")) {
										let cleanValue = value.replaceAll(
											",",
											""
										);
										if (cleanValue.length === 0) return;
										setAttributeValue((prev) => ({
											...prev,
											[attr.name]: null,
										}));
										handleAddValue(attr.name, cleanValue);
									}
								}}
							/>
							<TagContainer>
								{attr.values.map((val, index) => (
									<Tag
										key={index}
										onRemove={() => {
											if (attr.values.length > 1) {
												handleRemoveValue(
													attr.name,
													val
												);
											} else {
												handleToast(); // Show warning toast if only one value
											}
										}}
									>
										{val}
									</Tag>
								))}
							</TagContainer>
						</div>
					</Card>
				))}

				{error && error.length > 0 ? (
					<div className="Polaris-Labelled__Error"><div className="Polaris-InlineError"><div className="Polaris-InlineError__Icon"><span className="Polaris-Icon"><svg viewBox="0 0 20 20" className="Polaris-Icon__Svg" focusable="false" aria-hidden="true"><path d="M10 18a8 8 0 1 1 0-16 8 8 0 0 1 0 16zm-1-8h2V6H9v4zm0 4h2v-2H9v2z" fillRule="evenodd"></path></svg></span></div>{error}</div></div>
				) : null}
			</Card>
			{variants.length > 0 && (
				<Card title={`Product Variants (${variants.length})`} sectioned>
					<Stack vertical>
						<div className="bulk-actions-wrap">
							<Stack distribution="equalSpacing">
								<Button onClick={toggleSelectAll}>
									{selectedVariants.length === variants.length
										? "Deselect All"
										: "Select All"}
								</Button>
								<ButtonGroup>
									<Button
										onClick={() => setIsModalOpen(true)}
										// disabled={selectedVariants.length === 0}
									>
										Apply Bulk Action
									</Button>
									{selectedVariants?.length > 0 ? <Button destructive children="Remove selected item" onClick={handleRemoveVariants(selectedVariants)} />: null}
								</ButtonGroup>
							</Stack>
						</div>

						<Card sectioned>
						{variants.map((variant, index) => (
							
								<div className="variant-items"  key={index}>
									<Stack>
										<Checkbox
											checked={selectedVariants.includes(
												index
											)}
											onChange={() =>
												toggleSelectVariant(index)
											}
										/>
										<TextField
											label={index == 0 ? "Name" : null}
											type="text"
											value={variant.attributes
												.map((attr) => attr.attr_value)
												.join(" / ")}
											disabled
										/>
										<TextField
											label={index == 0 ? "Price" : null}
											type="number"
											min={0.01}
											step={0.01}
											value={
												variant.price
													? `${variant.price}`
													: ""
											}
											onChange={(value) =>
												handleVariantChange(
													index,
													"price",
													value
												)
											}
										/>
										<TextField
											label={index == 0 ? "Quantity" : null}
											type="number"
											min={1}
											step={1}
											value={
												variant.quantity
													? `${variant.quantity}`
													: ""
											}
											onChange={(value) =>
												handleVariantChange(
													index,
													"quantity",
													value
												)
											}
										/>
										<Button
											icon={<Icon source={DeleteMinor} />}
											onClick={() =>
												handleRemoveVariant(index)
											}
										></Button>
									</Stack>
								</div>
							
						))}
						</Card>
					</Stack>
				</Card>
			)}
			<Modal
				open={isModalOpen}
				onClose={() => setIsModalOpen(false)}
				title="Apply Bulk Action"
				primaryAction={{
					content: "Apply",
					onAction: handleBulkAction,
					disabled: bulkPrice.length == 0 && bulkQuantity.length == 0,
					loading: bulkActionstate.loading,
				}}
				secondaryActions={[
					{
						content: "Cancel",
						onAction: () => setIsModalOpen(false),
					},
				]}
			>
				<Modal.Section>
					<GroupByAttrs attributes={attributes} selectedVariants={selectedVariants} ref={attrRef}/>					
					<TextField
						label="Price"
						value={bulkPrice}
						onChange={(value) => setBulkPrice(value)}
						type="number"
					/>
					<div style={{marginBottom: '15px'}}></div>
					<TextField
						label="Quantity"
						value={bulkQuantity}
						onChange={(value) => setBulkQuantity(value)}
						type="number"
					/>
				</Modal.Section>
			</Modal>

			{toastActive && (
				<Toast
					content="Must keep at least one value"
					onDismiss={() => setToastActive(false)}
				/>
			)}
		</Container>
	);
};

// logic
// 	-- Apply Bulk Action
// 		case: unselect variant
// 			choose by Attribute
const sizePt = /sizes?/i;
const typePt = /types?/i;
const GroupByAttrs = React.forwardRef(function ({ selectedVariants, attributes }, attrRef) {
	if (selectedVariants?.length > 0) return null;

	if (!attributes || !Array.isArray(attributes) || attributes.length === 0)
    return null;

  const newAttrs = [...attributes]


  return (
		<div>
			<div className="heading">
				<TextStyle>Bulk edit by attribute</TextStyle><br/>
				<TextStyle variation="subdued">Variants that match at least one value of selected attribute will be update</TextStyle>
			</div>
    <GroupAttrWrap>
      {newAttrs.map((attr, index) => (
        <GroupByAttr attribute={attr} key={`attribute-${index}`} ref={node => attrRef.current[attr.name] = node} />
      ))}
    </GroupAttrWrap>
		</div>
  );
})

const GroupByAttr = React.forwardRef(function GroupByAttr({ attribute }, ref) {
  const { name, values } = attribute;

  const [open, setOpen] = useState(true);
  const [all, setAll] = useState(false);
  const [state, setState] = useReducer(reducerFn, {});

  const handleChangeAll = useCallback(
    (newChecked) => {
      setAll(newChecked);
      setOpen((p) => !p);

      if (newChecked) {
        const newState = (values || []).reduce((acc, i) => {
          acc[i] = true;
          return acc;
        }, {});

				setState(newState)
      }
    },
    [values],
  );

  const handleValChange = useCallback((value, id) => {
    setState({[id]: value})
  }, []);

	React.useImperativeHandle(ref, () => ({
		getState: () => state
	}))

  if (!name || !values || values.length === 0) return null;

  return (
    <div>
      <div>
        <Checkbox checked={all} onChange={handleChangeAll} label={name} />
      </div>
      <Collapsible
        open={open}
        id="basic-collapsible"
        transition={{ duration: "500ms", timingFunction: "ease-in-out" }}
        expandOnPrint
      >
				<div className="attr-inner">
        {values.map((val, index) => (
          <Checkbox
            label={val}
            id={val}
            key={`values-${index}`}
            onChange={handleValChange}
            checked={state[val] || false}
          />
        ))}
				</div>
      </Collapsible>
    </div>
  );
})

// attr: [
	// {attr_name: 'Color', attr_value: 'Red'}
	// {attr_name: 'Size', attr_value: 'X'}
	// {attr_name: 'Type', attr_value: 'H'}
// ]
function mapVariantFromAttr_OR(attrSelect, variants) {
	if (Object.keys(attrSelect).length === 0) return [];

	let obj = {}
	for (let [key, values] of Object.entries(attrSelect)) {
		if (Object.values(values).every(i => !i)) continue
		for (let [key2, val] of Object.entries(values)) {
			if (!val) continue

			let newKey =`${key}__${key2}`.toLowerCase()
			obj[newKey] = true;
		}
	}


	const varObj = {};
	for (let i= 0; i < variants.length; i ++) {
		let v = variants[i]
		if (!v || !v.attributes || v.attributes.length === 0) continue;

		for (let att of v.attributes) {
			const {attr_name, attr_value} = att || {}

			let key = `${attr_name}__${attr_value}`.toLowerCase()
			let curVal = (varObj[key] || [])
			varObj[key] = [...curVal, i]
		}
	}


	let arr = [];
	for (let key of Object.keys(obj)) {
		if (varObj[key] == null) continue;
		arr.push(...varObj[key])
	}

	return Array.from(new Set(arr))
}

function mapVariantFromAttr_And(attrSelect, variants) {
	if (Object.keys(attrSelect).length === 0) return [];

	let obj = {}
	for (let [key, values] of Object.entries(attrSelect)) {
		if (Object.values(values).every(i => !i)) continue
		for (let [key2, val] of Object.entries(values)) {
			if (!val) continue

			let newKey =`${key}__${key2}`.toLowerCase()
			obj[newKey] = true;
		}
	}

	const varObj = {};
	for (let i= 0; i < variants.length; i ++) {
		let v = variants[i]
		if (!v || !v.attributes || v.attributes.length === 0) continue;

		let mKey = []
		for (let att of v.attributes) {
			const {attr_name, attr_value} = att || {}

			let key = `${attr_name}__${attr_value}`.toLowerCase()
			mKey.push(key)
		}

		mKey = mKey.join('|');
		varObj[mKey] = i
	}

	let arr = [];
	for (let [key, val] of Object.entries(varObj)) {
		let flag = true;
		for (let k of key.split('|')) {
			if (!obj[k]) {
				flag = false;
				break
			}
		}

		if (!flag) continue;
		arr.push(val)
	}
	return Array.from(new Set(arr))
}

function removeVariantByAttr(attrName, value, variants) {
	let arr = [];
	for (let va of variants) {
		if (!va || !va.attributes || va.attributes.length === 0 ) continue;

		let ignore = false;
		for (let att of va.attributes) {
			if (att.attr_name === attrName && att.attr_value === value) {
				ignore = true;
				break;
			}
		}

		if (ignore) continue;
		arr.push(va);
	}

	return arr;
}

const generateKeyV2 = (attributes) => {
	return attributes
		.sort((a, b) => a.attr_name.localeCompare(b.attr_name)) // Sort by attribute name
		.map((attr) => `${attr.attr_name}:${attr.attr_value}`) // Create "name:value"
		.join("|"); // Join with |
};


const GroupAttrWrap = styled.div`
  margin-bottom: 1.6rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  column-gap: 1rem;

  > * {
    width: calc(50% - 1rem);
  }

  .attr-inner {
    display: flex;
    flex-direction: column;
    margin-left: 1rem;
  }
`;

export default TiktokVariantsGenerator;
