import { useMutation } from "@apollo/react-hooks";
import { Modal, Stack, TextField } from "@shopify/polaris";
import React, { useCallback } from "react";
import { BULK_REPLACE_TIKTOK_CRAWL_PRODUCT_TITLE } from "../../../../graphql/mutations";
import { arrInvalid, handleError, reducerFn } from "../../../../helper";
import { ComponentLabelPolaris } from "../../../shared/ComponentLabelPolaris";
import { useSelectRowsCtx } from "../../../shared/SelectRow";
import { useToastContext } from "../../../shared/ToastContext";

function BulkReplaceTitle({ open, onClose }) {
  const { ids, onCompleted } = useSelectRowsCtx();
  const { setNotify, toggleToast } = useToastContext();

  const [state, setState] = React.useReducer(reducerFn, {
    pattern: "",
    replacement: "",
    error: {},
  });

  const [replaceTitle, { loading }] = useMutation(
    BULK_REPLACE_TIKTOK_CRAWL_PRODUCT_TITLE,
    {
      onCompleted: () => {
        setNotify({
          msg: "The product crawl items replace title success",
          err: false,
        });
        onCompleted && onCompleted();
      },
      onError: (err) => {
        setNotify({ msg: handleError(err?.toString()), err: true });
      },
    },
  );

  const handleValuesChange = React.useCallback((value, id) => {
    setState({ [id]: value });
    if (id === "pattern") {
      let err = "";
      if (!value) {
        err = "Pattern is required";
      }
      setState((p) => ({ ...p, error: { ...state.error, [id]: err } }));
    }
  }, []);

  const handleSubmit = useCallback(() => {
    if (arrInvalid(ids)) return;
    if (!state.pattern) {
      setState((p) => ({
        ...p,
        error: { ...p, pattern: "Pattern is required" },
      }));
      return;
    }

    toggleToast(true);
    setNotify({ msg: null, err: false });

    replaceTitle({
      variables: {
        ids,
        pattern: state.pattern,
        replacement: state.replacement,
      },
    });
  }, [toggleToast, setNotify, replaceTitle, ids, state]);

  return (
    <Modal
      title="Bulk Replace Product Crawl Item"
      open={open}
      onClose={onClose}
      sectioned
      primaryAction={{
        content: "Submit",
        onAction: handleSubmit,
        loading: loading,
      }}
      secondaryActions={[{ content: "Cancel", onAction: onClose }]}
    >
      <Stack vertical>
        <div>
          <ComponentLabelPolaris required label="Pattern" />
          <TextField
            helpText="If this string includes a semicolon (;), the system will split it into multiple parts and use them as the basis for replacing the product title accordingly."
            value={state.pattern}
            placeholder="Text1; text2"
            id="pattern"
            onChange={handleValuesChange}
            error={state.error["pattern"]}
          />
        </div>

        <div>
          <ComponentLabelPolaris label="Replacement" />
          <TextField
            helpText="This is the value used to replace the product title."
            value={state.replacement}
            placeholder="Target"
            id="replacement"
            onChange={handleValuesChange}
          />
        </div>
      </Stack>
    </Modal>
  );
}

export default BulkReplaceTitle;
