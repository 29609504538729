import React from "react";
import BulkActions from "./BulkActions";
import { calcuItemCheck, getCheckAllItems, reducerFn } from "../../helper";
import { Checkbox } from "@shopify/polaris";

const initialValues = { checkAll: false, itemsChecked: [] };
const SelectRowsContext = React.createContext({
  ...initialValues,
  checkAllMarkup: null,
  checkItemMarkup: () => {},
});

function SelectRow({ children, data, refetch, isChildNode, actionMarkup }) {
  const [state, setState] = React.useReducer(reducerFn, initialValues);

  const handleCheckAll = (value) => {
    setState({ itemsChecked: getCheckAllItems(data, value), checkAll: value });
  };

  const handleItemCheck = (newChecked, id) => {
    setState((p) => {
      return {
        ...p,
        ...calcuItemCheck(p.itemsChecked, id, newChecked),
      };
    });
  };

  const handleCompleted = async () => {
    setState(initialValues);

    // fake time to close collapse
    await new Promise((res) => setTimeout(res, 1000));
    refetch && refetch();
  };

  const checkAllMarkup = (
    <Checkbox checked={state.checkAll} onChange={handleCheckAll} />
  );

  const checkItemMarkup = (value) => (
    <div className="check-box-wrap">
      <Checkbox
        id={value}
        checked={state.itemsChecked.includes(value)}
        onChange={handleItemCheck}
      />
    </div>
  );

  const bag = {
    checkAllMarkup,
    ids: state.itemsChecked,
    checkItemMarkup,
    onCompleted: handleCompleted,
    refetch,
  };

  return (
    <SelectRowsContext.Provider value={bag}>
      <BulkActions
        idsChecked={state.itemsChecked}
        onClose={() => handleCheckAll(false)}
      >
        {actionMarkup}
      </BulkActions>
      {isChildNode ? children : children(bag)}
    </SelectRowsContext.Provider>
  );
}

export const useSelectRowsCtx = () => React.useContext(SelectRowsContext);
export default SelectRow;
