import React, { useState, useCallback, useEffect, useRef } from "react";
import { Filters } from "@shopify/polaris";
import { useQuery } from "@apollo/react-hooks";
import styled from "styled-components";
import _, { get } from "lodash";

import { LIST_COLLECTION } from "../collections/CollectionSelectPolaris";
import { TAGS } from "../tags/TagsSelectPolaris";
import { FilterHasSearchPolaris } from "../../filters/FilterHasSearchPolaris";
import { formatDataTree, genLabelTree, isEmpty } from "../../../helper";
import { FilterNoSearchPolaris } from "../../filters/FilterNoSearchPolaris";
import { QUERY_STORES } from "./AccountsSelectPolaris";
import { FilterPDByStore } from "./FilterPDByStore";
import { FilterBoolValuePolaris } from "../../filters/FilterBoolValuePolaris";
import { gql } from "apollo-boost";

const LabelContainer = styled.p`
  flex: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const ONLY_RECREATE_MOCKUP = [
  { value: false, label: "False" },
  { value: true, label: "True" },
];

const PRODUCT_BASES = gql`
  query productBasesForSeller($filter: ProductBaseFilter) {
    productBasesForSeller(filter: $filter) {
      total
      nodes {
        id
        title
      }
    }
  }
`;

export const FilterProductDivisionsPolaris = (props) => {
  const { onChange, filter } = props;
  const [queryValue, setQueryValue] = useState(filter.search);
  const [productBases, setProductBases] = useState([]);
  const [pBase, setPBase] = useState({
    value: filter.productBaseId,
    label: null,
    search: null,
  });
  const [collection, setCollection] = useState({
    value: filter.collectionId,
    label: null,
    search: null,
  });
  const [tag, setTag] = useState({
    value: filter.tagId,
    label: null,
    search: null,
  });
  const [store, setStore] = useState({
    value: filter.storeId,
    label: null,
    search: null,
  });
  const [dataStore, setDataStore] = useState([]);
  const [dataCollection, setDataCollection] = useState([]);
  const [dataTag, setDataTag] = useState([]);
  const typingTimeoutRef = useRef(null);
  const [isOnlyRM, setIsOnlyRM] = useState({
    value: [false],
    label: "False",
  });

  // Queries
  const {
    data: dataPB,
    loading: loadingPB,
    error: errorPB,
  } = useQuery(PRODUCT_BASES, {
    variables: {
      filter: {
        limit: 20,
        offset: 0,
        search: pBase.search,
      },
    },
  });
  const {
    data: initialCollections,
    loading: loadingCollections,
    error: errorCollections,
  } = useQuery(LIST_COLLECTION, {
    variables: {
      filter: {
        search: collection.search,
        limit: 20,
        offset: 0,
      },
    },
  });

  const {
    data: initialTags,
    loading: loadingTags,
    error: errorTags,
  } = useQuery(TAGS, {
    variables: {
      filter: {
        search: tag.search,
        limit: 20,
        offset: 0,
      },
    },
  });
  const filterStore = {
    limit: 1000,
    offset: 0,
  };
  const { data: dataA } = useQuery(QUERY_STORES, {
    variables: {
      filter: {
        ...filterStore,
        platformSelected: ["amazon"],
      },
    },
  });
  // const { data: dataE } = useQuery(QUERY_STORES, {
  //   variables: {
  //     filter: {
  //       ...filterStore,
  //       platformSelected: ["ebay"],
  //     },
  //   },
  // });
  // const { data: dataOS } = useQuery(QUERY_STORES, {
  //   variables: {
  //     filter: {
  //       ...filterStore,
  //       platformSelected: ["woocommerce", "shopify", "shopbase"],
  //     },
  //   },
  // });

  // Get data
  useEffect(() => {
    let newData = getBases(dataPB);
    setProductBases(newData);
  }, [dataPB]);

  useEffect(() => {
    let newData = initialCollections;
    const nodes = newData?.collections?.nodes || [];
    if (nodes.length > 0) {
      let result = formatDataTree(nodes);
      setDataCollection(result);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialCollections]);

  useEffect(() => {
    let newData = initialTags;
    const nodes = newData?.tags?.nodes || [];
    if (nodes.length > 0) {
      let result = formatDataTree(nodes);
      setDataTag(result);
    }
  }, [initialTags]);

  useEffect(() => {
    let originOptions = [
      // {
      //   value: "amazon",
      //   label: <LabelContainer>--- Amazon ---</LabelContainer>,
      //   disabled: true,
      // },
    ];
    let newAmazon =
      dataA?.stores?.nodes?.length > 0
        ? dataA.stores.nodes.map((s) => ({
            value: s.id,
            label: (
              <LabelContainer>
                {s.title} {s.email ? `(${s.email})` : ""}
              </LabelContainer>
            ),
          }))
        : [];

    // Get data ebay store
    // let labelEbay = [
    //   {
    //     value: "ebay",
    //     label: <LabelContainer>--- Ebay ---</LabelContainer>,
    //     disabled: true,
    //   },
    // ];
    // let newEbay =
    //   dataE &&
    //   dataE.stores &&
    //   dataE.stores.nodes &&
    //   dataE.stores.nodes.length > 0
    //     ? dataE.stores.nodes.map((s) => {
    //         if (!s.suspended) {
    //           return {
    //             value: s.id,
    //             label: <LabelContainer>{s.title}</LabelContainer>,
    //           };
    //         }
    //         return null;
    //       })
    //     : [];
    // newEbay = newEbay.filter(Boolean);

    // // Get data online store
    // let labelOS = [
    //   {
    //     value: "onlineStore",
    //     label: <LabelContainer>--- Online store ---</LabelContainer>,
    //     disabled: true,
    //   },
    // ];
    // let newOS =
    //   dataOS &&
    //   dataOS.stores &&
    //   dataOS.stores.nodes &&
    //   dataOS.stores.nodes.length > 0
    //     ? dataOS.stores.nodes.map((s) => ({
    //         value: s.id,
    //         label: (
    //           <LabelContainer>
    //             {s.title} {s.domain ? `(${s.domain})` : ""}
    //           </LabelContainer>
    //         ),
    //       }))
    //     : [];

    // set Otpions list.
    originOptions = [
      ...originOptions,
      ...newAmazon,
      // ...labelEbay,
      // ...newEbay,
      // ...labelOS,
      // ...newOS,
    ];
    setDataStore(originOptions);
  }, [dataA]); //, dataE, dataOS

  useEffect(() => {
    const { productBaseId, collectionId, tagId, storeId } = filter;
    if (productBaseId) {
      let currentPB =
        productBases && productBases.length > 0
          ? productBases.find((i) => i.value === productBaseId)
          : null;
      if (currentPB) {
        setPBase((prevState) => ({
          ...prevState,
          value: currentPB.value,
          label: currentPB.label,
        }));
      }
    }
    if (collectionId) {
      let currentC =
        dataCollection && dataCollection.length > 0
          ? dataCollection.find((i) => i.value === collectionId)
          : null;
      if (currentC) {
        setCollection((prevState) => ({
          ...prevState,
          value: currentC.value,
          label: currentC.label,
        }));
      }
    }
    if (tagId) {
      let currentT =
        dataTag && dataTag.length > 0
          ? dataTag.find((i) => i.value === tagId)
          : null;
      if (currentT) {
        setTag((prevState) => ({
          ...prevState,
          value: currentT.value,
          label: currentT.label,
        }));
      }
    }
    if (storeId) {
      let currentS =
        dataStore && dataStore.length > 0
          ? dataStore.find((i) => i.value === storeId)
          : null;
      let label = currentS
        ? _.get(currentS, "label.props.children", null)
        : null;
      if (_.isArray(label)) {
        label = label.join("");
      }

      if (currentS) {
        setStore((prevState) => ({
          ...prevState,
          value: currentS.value,
          label: label,
        }));
      }
    }
  }, [filter, productBases, dataCollection, dataTag, dataStore]);

  // onChange
  useEffect(() => {
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }
    if (onChange) {
      let onlyRM = Array.isArray(isOnlyRM.value)
        ? isOnlyRM.value[0]
        : isOnlyRM.value;
      typingTimeoutRef.current = setTimeout(() => {
        onChange({
          search: queryValue ? queryValue.trim() : queryValue,
          productBaseId: pBase.value,
          collectionId: collection.value,
          tagId: tag.value,
          storeId: store.value,
          onlyRecreateMockup: onlyRM,
        });
      }, 300);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pBase.value,
    collection.value,
    tag.value,
    store.value,
    queryValue,
    isOnlyRM,
  ]);

  // Handle data
  const buildColeltionData = useCallback((input) => {
    let result = [];
    const buildData = (data, prefix = "") => {
      data.forEach((input) => {
        let build = {
          label:
            prefix && "" !== prefix ? prefix + " " + input.name : input.name,
          value: input.id,
        };
        result.push(build);
        if (input && input.children && input.children.length > 0) {
          buildData(input.children, prefix + "--");
        }
      });
    };
    buildData(input, "");
    return result;
  }, []);

  const handleQueryChange = useCallback((value) => setQueryValue(value), []);
  const handleQueryRemove = useCallback(() => setQueryValue(""), []);
  const handlePBRemove = useCallback(
    () => setPBase({ value: null, label: null }),
    [],
  );
  const handleCollectionRemove = useCallback(
    () => setCollection({ value: null, label: null }),
    [],
  );
  const handleTagRemove = useCallback(
    () => setTag({ value: null, label: null }),
    [],
  );
  const handleStoreRemove = useCallback(
    () => setStore({ value: null, label: null }),
    [],
  );
  const handleIsOnlyRMRemove = useCallback(
    () =>
      setIsOnlyRM({
        value: [false],
        label: "False",
      }),
    [],
  );

  const handleFiltersClearAllRemove = useCallback(() => {
    handleQueryRemove();
    handlePBRemove();
    handleCollectionRemove();
    handleTagRemove();
    handleStoreRemove();
    handleIsOnlyRMRemove();
  }, [
    handleQueryRemove,
    handlePBRemove,
    handleCollectionRemove,
    handleTagRemove,
    handleStoreRemove,
    handleIsOnlyRMRemove,
  ]);

  const filters = [
    {
      key: "productBase",
      label: "Product bases",
      filter: (
        // <FilterNoSearchPolaris
        <FilterHasSearchPolaris
          value={pBase}
          options={productBases && productBases.length > 0 ? productBases : []}
          error={errorPB}
          loading={loadingPB}
          onChangeSearch={(search) =>
            setPBase((prevState) => ({ ...prevState, search }))
          }
          onChange={({ value, label }) =>
            setPBase((prevState) => ({ ...prevState, value, label }))
          }
        />
      ),
    },
    {
      key: "stores",
      label: "Stores",
      filter: (
        <FilterPDByStore
          data={dataStore && dataStore.length > 0 ? dataStore : []}
          value={store}
          onChange={({ value, label }) =>
            setStore((prevState) => ({ ...prevState, value, label }))
          }
        />
      ),
    },
    {
      key: "collections",
      label: "Collections",
      filter: (
        <FilterHasSearchPolaris
          options={dataCollection}
          value={collection}
          loading={loadingCollections}
          error={errorCollections}
          onChangeSearch={(search) =>
            setCollection((prevState) => ({ ...prevState, search }))
          }
          onChange={({ value, label }) =>
            setCollection((prevState) => ({ ...prevState, value, label }))
          }
        />
      ),
    },
    {
      key: "tags",
      label: "Tags",
      filter: (
        <FilterHasSearchPolaris
          options={dataTag}
          value={tag}
          loading={loadingTags}
          error={errorTags}
          onChangeSearch={(search) =>
            setTag((prevState) => ({ ...prevState, search }))
          }
          onChange={({ value, label }) =>
            setTag((prevState) => ({ ...prevState, value, label }))
          }
        />
      ),
    },
    {
      key: "onlyRM",
      label: "Only Recreate Mockup",
      filter: (
        <FilterBoolValuePolaris
          options={ONLY_RECREATE_MOCKUP}
          value={isOnlyRM}
          onChange={({ value, label }) => {
            setIsOnlyRM((prev) => ({ ...prev, value: [value], label }));
          }}
        />
      ),
    },
  ];

  const appliedFilters = [];
  if (!isEmpty(pBase.label)) {
    const key = "productBase";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, pBase.label),
      onRemove: handlePBRemove,
    });
  }
  if (!isEmpty(store.label)) {
    const key = "stores";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, store.label),
      onRemove: handleStoreRemove,
    });
  }
  if (!isEmpty(collection.label)) {
    const key = "collections";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, collection.label),
      onRemove: handleCollectionRemove,
    });
  }
  if (!isEmpty(tag.label)) {
    const key = "tags";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, tag.label),
      onRemove: handleTagRemove,
    });
  }
  if (isOnlyRM != null) {
    const key = "onlyRM";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, isOnlyRM.label),
      onRemove: handleIsOnlyRMRemove,
    });
  }

  return (
    <Filters
      filters={filters}
      appliedFilters={appliedFilters}
      queryValue={queryValue}
      queryPlaceholder="Filter product division"
      onQueryChange={handleQueryChange}
      onQueryClear={handleQueryRemove}
      onClearAll={handleFiltersClearAllRemove}
    />
  );

  function disambiguateLabel(key, value) {
    switch (key) {
      case "productBase":
        return `Product base: ${value}`;
      case "stores":
        return `Store: ${value}`;
      case "collections":
        const newCols = value ? genLabelTree([value]) : "";
        return `Collection: ${newCols}`;
      case "tags":
        const newTags = value ? genLabelTree([value]) : "";
        return `Tag: ${newTags}`;
      case "onlyRM":
        return `Only Recreate Mockup: ${value}`;
      default:
        return value;
    }
  }
};

function getBases(data) {
  const nodes = get(data, "productBasesForSeller.nodes") || [];
  return nodes
    .map((i) => (i ? { value: i.id, label: i.title } : null))
    .filter(Boolean);
}
