import { gql } from "apollo-boost";
import React from "react";
import isEqual from "lodash/isEqual";

import { arrInvalid, objectInvalid, reducerFn } from "../../../helper";
import { useCustomQuery } from "../../../hooks/useCustomQuery";
import { useCallbackRef } from "../../../hooks/useCallbackRef";

const useTeamMember = (filter = {}, skip, fragment) => {
  const filterStr = JSON.stringify(filter);
  const queryOptions = React.useMemo(() => {
    const filter = JSON.parse(filterStr);
    return {
      variables: filter,
      skip,
      query: gql`
        query listUserTeamByRoles($roles: [String!]!) {
          listUserTeamByRoles(roles: $roles) {
            ${fragment}
          }
        } 
      `,
    };
  }, [filterStr, skip, fragment]);

  return useCustomQuery({
    queryOptions,
    path: "data.listUserTeamByRoles",
  });
};

const getGroupsMissing = async (groupIds, currentIds, fragment) => {
  if (arrInvalid(groupIds) || arrInvalid(currentIds)) return [];

  let idsMiss = [];
  for (const id of currentIds) {
    if (groupIds.includes(id)) continue;
    idsMiss.push(id);
  }

  if (arrInvalid(idsMiss)) return [];

  async function getGroups(ids) {
    const { __apolloClient__: client } = window;
    if (!client) return [];

    try {
      const args = [];
      const queries = [];
      const variables = {};
      for (let i = 0; i < ids.length; i++) {
        const id = ids[i];
        args.push(`$id__${i}: ID!`);
        queries.push(`
          group__${i}: group(id: $id__${i}) {
            ...group
          }
        `);
        variables[`id__${i}`] = id;
      }

      const q = `
        query group(${args.join(",")}) {
          ${queries.join("\n")}
        }
      `;

      const query = gql`
        fragment group on Group {
          ${fragment}
        }

        ${q}
      `;

      const res = await client.query({
        query,
        variables,
      });

      if (!objectInvalid(res?.data)) {
        return Object.values(res.data);
      }
    } catch (err) {}
    return [];
  }

  return await getGroups(idsMiss);
};

const useGroupsFilter = ({ value, skip, fragment = "id name" }) => {
  const [filter, setFilter] = React.useReducer(reducerFn, {
    limit: 20,
    offset: 0,
    search: "",
  });

  const [state, setState] = React.useReducer(reducerFn, {
    options: [],
    selectedOptions: [],
  });

  const { data, loading, error } = useTeamMember(
    filter,
    skip,
    `nodes {${fragment}}`
  );

  React.useEffect(() => {
    if (objectInvalid(data)) return;

    const options = getOptions(data.nodes);
    setState({ options });
  }, [data]);

  const optionsStr = JSON.stringify(state.options);
  const prevValue = React.useRef();
  React.useEffect(() => {
    let options = JSON.parse(optionsStr);
    if (
      arrInvalid(options) ||
      arrInvalid(value) ||
      isEqual(prevValue.current, value)
    )
      return;

    (async function () {
      const ids = getOptions(options, ({ value }) => value);
      const optionsOther = await getGroupsMissing(ids, value, fragment);
      const optionsOtherFormat = getOptions(optionsOther);
      options = [...options, ...optionsOtherFormat];
      const selectedOptions = options.filter(({ value: val }) =>
        value.includes(val)
      );

      setState({ options, selectedOptions });
      prevValue.current = value;
    })();
  }, [optionsStr, value, fragment]);

  return [
    React.useMemo(
      () => ({
        loading,
        error,
        options: state.options,
        selectedOptions: state.selectedOptions,
      }),
      [loading, error, state.options, state.selectedOptions]
    ),
    useCallbackRef(setFilter),
  ];
};

/** ---- */
const getOptions = (
  data,
  fn = ({ id: value, name: label }) => ({ value, label })
) => {
  if (arrInvalid(data)) return [];

  return data.map(fn);
};

export { useTeamMember, useGroupsFilter, getGroupsMissing };
