import { useMutation } from "@apollo/react-hooks";
import { Modal } from "@shopify/polaris";

import React from "react";
import { DEACTIVE_TIKTOK_PROMOTION_BY_STORE } from "../../../graphql/mutations";
import { handleError } from "../../../helper";
import { useToastContext } from "../../shared/ToastContext";

export default function DeactivatePromotions({ open, onClose, store }) {
  const { id } = store;

  const [deactivatePromotions, { loading }] = useMutation(
    DEACTIVE_TIKTOK_PROMOTION_BY_STORE,
  );

  const { toggleToast, setNotify } = useToastContext();

  const handleSubmit = React.useCallback(() => {
    if (!id) return;

    deactivatePromotions({
      variables: {
        storeIDs: [id],
      },
    })
      .then(() => {
        setNotify({ err: false, msg: "Deactive all promotion success" });
      })
      .catch((err) => {
        setNotify({ err: true, msg: handleError(err.toString()) });
      })
      .finally(() => {
        onClose && onClose();
      });
  }, [deactivatePromotions, id, onClose, toggleToast, setNotify]);

  return (
    <Modal
      title="Update auto map"
      sectioned
      open={open}
      onClose={onClose}
      primaryAction={{ content: "Submit", onAction: handleSubmit, loading }}
      secondaryActions={[{ content: "Cancel", onAction: onClose }]}
    >
      <p>Are you sure to Deactivate all Promotions of this Store?</p>
    </Modal>
  );
}
