import React from "react";
import { TeamStatistics } from "../../team/components";
import { WidgetCompProvider } from "../../widget/context";
import { ELEMENTS_DATA, LayoutSection, TITLE_PAGE } from "./LayoutSection";

export const TEAM_STATISTIC_ELEMENT = {
  ...ELEMENTS_DATA.teamStatistic,
  default: 1,
};

const ELEMENTS = [
  TEAM_STATISTIC_ELEMENT,
  {
    ...ELEMENTS_DATA.overviews,
    default: 1,
  },
  {
	...ELEMENTS_DATA.tiktokDataSourceReport,
	default: 1,
  },
  {
	...ELEMENTS_DATA.tiktokGroupReport,
	default: 1,
  },
  {
	...ELEMENTS_DATA.tiktokStoreReport,
	default: 1,
  },
  {
	...ELEMENTS_DATA.tiktokManagerReport,
	default: 1,
  },
  {
	...ELEMENTS_DATA.tiktokTeamReport,
	default: 1,
  },
  {
    ...ELEMENTS_DATA.marketplaceManager,
    default: 1,
  },
  {
    ...ELEMENTS_DATA.storeManagers,
    default: 1,
  },
  {
    ...ELEMENTS_DATA.productBase,
    default: 1,
  },
  {
    ...ELEMENTS_DATA.designers,
    default: 1,
  },
  {
    ...ELEMENTS_DATA.fulfillCounter,
    default: 1,
  },
  {
    ...ELEMENTS_DATA.products,
    default: 2,
  },
  {
    ...ELEMENTS_DATA.stores,
    default: 2,
  },
  {
    ...ELEMENTS_DATA.tasks,
    default: 2,
  },
  {
    ...ELEMENTS_DATA.createdProduct,
    default: 1,
  },
  {
    ...ELEMENTS_DATA.groups,
    default: 1,
  },
  {
    ...ELEMENTS_DATA.ideaTasks,
    default: 2,
  },
  {
    ...ELEMENTS_DATA.trackingStatus,
    default: 2,
  },
  {
    ...ELEMENTS_DATA.ideaTasksByDesigner,
    default: 2,
  },
];

export function OverviewsAdmin() {
  return (
    <WidgetCompProvider title={TITLE_PAGE.Overviews} elements={ELEMENTS}>
      <LayoutSection title={TITLE_PAGE.Overviews} inWrapper={false} />
    </WidgetCompProvider>
  );
}
