import { gql } from 'apollo-boost';

export const ADMIN_UNFROZEN_A_TEAM_VIA_INVOICE = gql`
  mutation adminUnfrozenATeamViaInvoice($id: ID!) {
    adminUnfrozenATeamViaInvoice(id: $id) {
      id
      status
    }
  }
`;

export const ADMIN_FROZEN_A_TEAM_VIA_INVOICE = gql`
  mutation adminFrozenATeamViaInvoice($id: ID!) {
    adminFrozenATeamViaInvoice(id: $id) {
      id
      status
    }
  }
`;
