import { useQuery } from "@apollo/react-hooks";
import {
  Button,
  ButtonGroup,
  Checkbox,
  ChoiceList,
  Filters,
  Layout,
  Modal,
  Page,
  Popover,
  Stack,
} from "@shopify/polaris";
import { CalendarMinor } from "@shopify/polaris-icons";
import { gql } from "apollo-boost";
import { get, isEmpty, isEqual } from "lodash";
import moment from "moment";
import React, {
  createContext,
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import { AppContext, useAppContext } from "../../../context";
import { GET_FULFILLMENTS, GET_TEAMS } from "../../../graphql/queries";
import {
  arrInvalid,
  canViewWholeTeamReport,
  checkRole,
  genStrToBtoA,
  getCookie,
  objectInvalid,
  setCookie,
} from "../../../helper";
import {
  COLLECTIONS_QUERY,
  LIST_PRODUCT_BASE,
  TAGS_QUERY,
} from "../../../pages/seller/ProductFiltersPolaris";
import { TEAM_ROLE } from "../../../variable";
import { FilterHasSearchMVPolaris } from "../../filters/FilterHasSearchMVPolaris";
import { FilterHasSearchPolaris } from "../../filters/FilterHasSearchPolaris";
import { FilterNoSearchPolaris } from "../../filters/FilterNoSearchPolaris";
import { FilterCustomTimePolaris } from "../../tasks/FilterCustomTimePolaris";
import { ConfigWidgets } from "../../widget/components";
import { useWidgetContext } from "../../widget/context";
import { ReportsContextProvider, useReportsContext } from "../context";
import { CompareToDates } from "./CompareToDates";
import { CreatedProductByUser } from "./CreateProductByUser";
import { Designers } from "./Designers";
import { FulfillCounter } from "./FulfillCounter";
import { GroupManagers } from "./GroupManagers";
import { IdeaTasksByDesigner } from "./IdeaTaskByDesigner";
import { IdeaTasks } from "./IdeaTasks";
import { KPIOfTask } from "./KPIOfTask";
import { TiktokStatistic } from "./TiktokStatistic";
import { MarketplaceManager } from "./MarketplaceManagers";
import { Products } from "./Products";
import { SaleChannelsOverviews } from "./SaleChannelsOverviews";
import { StoreManagers } from "./StoreManagers";
import { Stores } from "./Stores";
import StoresV2 from "./StoresV2";
import { Tasks } from "./Tasks";
import { TrackingStatus } from "./TrackingStatus";
import { TrackTaskSubmission } from "./TrackTaskSubmission";
import ProductBase from "./ProductBase";
import { TeamStatistics } from "../../team/components";

import { TiktokDataSourceReport } from "./TiktokDataSourceReport";
import { TiktokGroupReport } from "./TiktokGroupReport";
import { TiktokStoreReport } from "./TiktokStoreReport";
import { TiktokManagerReport } from "./TiktokManagerReport";
import { TiktokTeamReport } from "./TiktokTeamReport";

const Custom = "Custom";
const OPTIONS = [
  { value: "Today", label: "Today" },
  { value: "Yesterday", label: "Yesterday" },
  { value: "Last 7 days", label: "Last 7 days" },
  { value: "This month", label: "This month" },
  { value: "Last month", label: "Last month" },
  { value: Custom, label: Custom },
  { value: "All time", label: "All time" },
];

const Etsy = "Etsy";
const Ebay = "Ebay";
const Amazon = "Amazon";
const Tiktok = "Tiktok";
const FacebookMK = "Facebook MK";
const OnlineStores = "Online Stores";

const PRODUCT_BASES = gql`
  query productBasesForSeller($filter: ProductBaseFilter) {
    productBasesForSeller(filter: $filter) {
      total
      nodes {
        id
        title
      }
    }
  }
`;

export const TITLE_PAGE = {
  Overviews: "Dashboard",
  Etsy,
  Ebay,
  Amazon,
  Tiktok,
  FacebookMK,
  OnlineStores,
};

export const ELEMENTS_DATA = {
  overviews: {
    label: "Sale Channels Overviews",
    value: "sale-channels-overviews",
    component: SaleChannelsOverviews,
  },
  marketplaceManager: {
    label: "Marketplace Manager",
    value: "marketplace-manager",
    component: MarketplaceManager,
  },
  storeManagers: {
    label: "Store Managers",
    value: "store-managers",
    component: StoreManagers,
  },
  designers: {
    label: "Designers",
    value: "designers",
    component: Designers,
  },
  products: {
    label: "Products",
    value: "products",
    component: Products,
  },
  stores: {
    label: "Stores",
    value: "stores",
    component: StoresV2,
  },
  tasks: {
    label: "Tasks",
    value: "tasks",
    component: Tasks,
  },
  createdProduct: {
    label: "Created Product",
    value: "created-product",
    component: CreatedProductByUser,
  },
  groups: {
    label: "Groups",
    value: "groups",
    component: GroupManagers,
  },
  ideaTasks: {
    label: "Idea Tasks",
    value: "idea-tasks",
    component: IdeaTasks,
  },
  trackingStatus: {
    label: "Tracking Status",
    value: "tracking-status",
    component: TrackingStatus,
  },
  ideaTasksByDesigner: {
    label: "Idea Tasks By Designer",
    value: "idea-tasks-by-designer",
    component: IdeaTasksByDesigner,
  },
  kpiOfTask: {
    label: "KPI of Task",
    value: "kpi-of-task",
    component: KPIOfTask,
  },
  trackTaskSubmission: {
    label: "Track Task Submission",
    value: "track-task-submission",
    component: TrackTaskSubmission,
  },
  fulfillCounter: {
    label: "Fulfillment Counter",
    value: "fulfillment-counter",
    component: FulfillCounter,
  },
  productBase: {
    label: "Product Base",
    value: "product-base",
    component: ProductBase,
  },
  teamStatistic: {
    label: "Team Statistic",
    value: "team-statistic",
    component: TeamStatistics,
  },
  tiktokStatistic: {
    label: "Tiktok Statistic",
    value: "tiktok-statistic",
    component: TiktokStatistic,
  },
  tiktokDataSourceReport: {
    label: "Tiktok Data Sources",
    value: "tiktok-data-sources",
    component: TiktokDataSourceReport,
  },
  tiktokGroupReport: {
    label: "Tiktok Groups",
    value: "tiktok-groups",
    component: TiktokGroupReport,
  },
  tiktokStoreReport: {
    label: "Tiktok Stores",
    value: "tiktok-stores",
    component: TiktokStoreReport,
  },
  tiktokManagerReport: {
    label: "Tiktok Managers",
    value: "tiktok-managers",
    component: TiktokManagerReport,
  },
  tiktokTeamReport: {
    label: "Tiktok Teams",
    value: "tiktok-teams",
    component: TiktokTeamReport,
  },
};

const SALES_CHANNEL = {
  [Etsy]: "etsy",
  [Ebay]: "ebay",
  [Amazon]: "amazon",
  [Tiktok]: "tiktok",
  [FacebookMK]: "facebook",
  [OnlineStores]: "online store",
};

const initCollapseActive = Object.keys(ELEMENTS_DATA).reduce((acc, key) => {
  return { ...acc, [key]: true };
}, {});

export function LayoutSection({ title, inWrapper }) {
  // Props
  const isOverviews = [TITLE_PAGE.Overviews].includes(title);
  // const isAmazon = [TITLE_PAGE.Amazon].includes(title);
  const saleChanel = title ? SALES_CHANNEL[title] : undefined;
  const [collapseActive, setCollapseActive] = useState(initCollapseActive);

  // Context
  const { currentUser } = useContext(AppContext);
  const [state] = useWidgetContext();
  const firstCache = useRef(false);

  let collapseActiveCache = getCookie("report-context");
  collapseActiveCache = collapseActiveCache
    ? JSON.parse(window.atob(collapseActiveCache))
    : null;

  useEffect(() => {
    if (firstCache.current) return;
    const cache = Object.values(collapseActive).every((v) => v)
      ? collapseActiveCache || initCollapseActive
      : collapseActive;
    setCollapseActive(cache);
    firstCache.current = true;
  }, [!isEqual(collapseActive, collapseActiveCache)]);

  const typingRef = useRef(null);
  useEffect(() => {
    typingRef.current && clearTimeout(typingRef.current);
    typingRef.current = setTimeout(() => {
      const val = genStrToBtoA(JSON.stringify(collapseActive));
      setCookie("report-context", val, 100);
    }, 1000);
  }, [collapseActive]);

  // Check user role
  // let currentParam;
  const propCommon = {
    "sale-channels-overviews": {
      isPie: isOverviews,
      saleChanel,
    },
    "store-managers": {
      saleChanel,
    },
    stores: {
      saleChanel,
    },
    products: {
      saleChanel,
    },
    groups: {
      saleChanel,
    },
    "fulfillment-counter": {
      saleChanel,
    },
    "product-base": {
      saleChanel,
    },
  };
  let propsByRole = {};
  const { isAdministrator } = checkRole(currentUser);
  const userRole = get(currentUser, "teamUser.role", null);
  const userId = get(currentUser, "id", null);
  switch (userRole) {
    case TEAM_ROLE.MarketplaceManager: {
      // currentParam = TEAM_ROLE_PARAMS.MarketplaceManager;
      propsByRole = {
        ...propCommon,
      };
      break;
    }
    case TEAM_ROLE.Designer: {
      propsByRole = {
        tasks: {
          assigneeIds: userId ? [userId] : null,
        },
      };
      break;
    }
    default: {
      // currentParam = TEAM_ROLE_PARAMS.Seller;
      propsByRole = {
        "marketplace-manager": {
          saleChanel,
        },
        ...propCommon,
      };
      break;
    }
  }

  if (isAdministrator) {
    propsByRole = {
      "marketplace-manager": {
        saleChanel,
      },
      "team-statistic": {
        saleChannel: saleChanel,
      },
      ...propCommon,
    };
  }

  // Markup
  const breadcrumbs = [{ content: "Dashboard", url: `/` }];
  const propsCollapse = {
    collapseActive,
    setCollapseActive,
  };

  return (
    <ReportsContextProvider>
      <Page
        fullWidth
        title={title}
        primaryAction={<PrimaryAction title={title} userRole={userRole} />}
        {...(isOverviews ? {} : { breadcrumbs })}
      >
        <Wrapper>
          <CollapseContextProvider {...propsCollapse}>
            <Layout>
              <Layout.Section oneHalf>
                <ColumnsMarkup
                  isFirst
                  state={state}
                  propsByRole={propsByRole}
                  inWrapper={inWrapper}
                />
              </Layout.Section>
              <Layout.Section oneHalf>
                <ColumnsMarkup state={state} propsByRole={propsByRole} />
              </Layout.Section>
            </Layout>
          </CollapseContextProvider>
        </Wrapper>
      </Page>
    </ReportsContextProvider>
  );
}

const STORE_MANAGER_QUERY = gql`
  query teamMembers($filter: TeamUserFilter) {
    teamMembers(filter: $filter) {
      hits {
        id
        user {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

const STORE_QUERY = gql`
  query stores($filter: StoreFilter) {
    stores(filter: $filter) {
      nodes {
        id
        title
      }
    }
  }
`;

function PrimaryAction({ title, userRole }) {
  const { currentUser } = useAppContext();
  const { isAdministrator, isSeller, isMarketplaceManager } =
    checkRole(currentUser);
  const canView = canViewWholeTeamReport(currentUser);

  const isLeader =
    [
      TEAM_ROLE.Admin,
      TEAM_ROLE.StoreManager,
      TEAM_ROLE.MarketplaceManager,
      TEAM_ROLE.DesignLeader,
      TEAM_ROLE.Accountant,
    ].includes(userRole) || isAdministrator;
  // State
  const [active, setActive] = useState(false);
  const [activatorLabel, setActivatorLabel] = useState(["Today"]);
  const [open, setOpen] = useState(false);
  const [isCompare, setIsCompare] = useState(null);
  const [customTime] = useState({
    range: null,
    rangeLabel: null,
  });
  const compareRef = useRef(null);
  const timeoutRef = useRef(null);

  // Filter
  const [productBases, setProductBases] = useState([]);
  const [productBase, setProductBase] = useState({
    value: null,
    label: null,
    search: null,
  });

  const [collections, setCollections] = useState([]);
  const [collection, setCollection] = useState({
    value: [],
    label: [],
    search: null,
  });
  const [tags, setTags] = useState([]);
  const [tag, setTag] = useState({
    value: [],
    label: [],
    search: null,
  });

  const [teams, setTeams] = useState([]);
  const [team, setTeam] = useState({
    value: [],
    label: [],
    search: null,
  });

  const [fulfillments, setFulfillments] = useState([]);
  const [fulfillment, setFulfillment] = useState({
    value: null,
    label: null,
    search: null,
  });
  const [viewWholeTeamReport, setViewWholeTeamReport] = useState(false);

  const [storeManagers, setStoreManagers] = useState([]);
  const [storeManager, setStoreManager] = useState({
    value: null,
    label: null,
    search: null,
  });

  const [stores, setStores] = useState([]);
  const [store, setStore] = useState({
    value: null,
    label: null,
    search: null,
  });

  // Context
  const { setRange, setFilter, setTeamID } = useReportsContext();

  // Queries
  const {
    data: dataPB,
    loading: loadingPB,
    errorPB,
  } = useQuery(PRODUCT_BASES, {
    skip: !isLeader,
    variables: {
      filter: {
        limit: 20,
        offset: 0,
        search: productBase.search,
      },
    },
  });

  const filterCommon = {
    limit: 20,
    offset: 0,
  };
  const {
    loading: loadingC,
    error: errorC,
    data: dataC,
  } = useQuery(COLLECTIONS_QUERY, {
    fetchPolicy: "network-only",
    variables: {
      filter: {
        ...filterCommon,
        search: collection.search,
      },
    },
    skip: !isLeader,
  });

  const {
    data: dataTag,
    loading: loadingTag,
    error: errorTag,
  } = useQuery(TAGS_QUERY, {
    variables: {
      filter: {
        ...filterCommon,
        search: tag.search,
      },
    },
    skip: !isLeader,
  });

  const {
    data: dataT,
    loading: loadingT,
    error: errorT,
  } = useQuery(GET_TEAMS, {
    variables: {
      filter: {
        ...filterCommon,
        search: team.search,
      },
    },
    skip: !isAdministrator,
  });

  const {
    data: dataFF,
    loading: loadingFF,
    error: errorFF,
  } = useQuery(GET_FULFILLMENTS, {
    skip: !isLeader,
  });

  const {
    data: dataSM,
    loading: loadingSM,
    error: errorSM,
  } = useQuery(STORE_MANAGER_QUERY, {
    variables: {
      filter: {
        ...filterCommon,
        status: true,
        role: TEAM_ROLE.StoreManager,
        search: storeManager.search,
      },
    },
    skip: !isSeller && !isMarketplaceManager,
  });

  const {
    data: dataS,
    loading: loadingS,
    error: errorS,
  } = useQuery(STORE_QUERY, {
    variables: {
      filter: {
        ...filterCommon,
        search: store.search,
      },
    },
    skip: !isSeller && !isMarketplaceManager,
  });

  // Get data
  useEffect(() => {
    const newBases = getBases(dataPB);
    setProductBases(newBases);
  }, [dataPB]);

  useEffect(() => {
    if (!isLeader) return;

    if (dataFF) {
      let newData =
        dataFF?.fulfillments?.length > 0
          ? dataFF.fulfillments.map((i) => ({ value: i.id, label: i.name }))
          : [];
      newData.unshift({ value: "merchbridge", label: "MerchBridge" });
      setFulfillments(newData);
    }
  }, [dataFF, isLeader]);

  useEffect(() => {
    if ((!isSeller, isMarketplaceManager)) return;

    if (dataSM) {
      let newData = getStoreManagers(dataSM?.teamMembers?.hits);
      setStoreManagers(newData);
    }
  }, [dataSM, isSeller, isMarketplaceManager]);

  useEffect(() => {
    if ((!isSeller, isMarketplaceManager)) return;

    if (dataS) {
      let newData = getStores(dataS?.stores?.nodes);
      setStores(newData);
    }
  }, [dataS, isSeller, isMarketplaceManager]);

  useEffect(() => {
    const nodes =
      dataC?.collections?.nodes?.length > 0 ? dataC.collections.nodes : [];
    const newCollections = formatValue(nodes);
    setCollections(newCollections);
  }, [dataC]);

  useEffect(() => {
    const nodes = dataTag?.tags?.nodes?.length > 0 ? dataTag.tags.nodes : [];
    const newTags = formatValue(nodes);
    setTags(newTags);
  }, [dataTag]);

  useEffect(() => {
    const nodes = dataT?.teams?.nodes || [];
    const newTeams = nodes.map(({ id: value, name: label }) => ({
      value,
      label,
    }));
    setTeams(newTeams);
  }, [dataT]);

  // Handle actions
  const toggleActive = useCallback(() => setActive((prev) => !prev), []);
  const handleFormatTime = useCallback(
    (selected) => {
      let range = {
        from: null,
        to: null,
      };
      if (selected[0]) {
        switch (selected[0]) {
          case "Today":
            range.from = moment().startOf("day");
            range.to = moment().endOf("day");
            break;
          case "Yesterday":
            range.from = moment().subtract(1, "days").startOf("day");
            range.to = moment().subtract(1, "days").endOf("day");
            break;
          case "Last 7 days":
            range.from = moment().subtract(7, "days").startOf("day");
            range.to = moment();
            break;
          case "This month":
            range.from = moment().startOf("month");
            range.to = moment();
            break;
          case "Last month":
            range.from = moment().subtract(1, "months").startOf("months");
            range.to = moment().subtract(1, "months").endOf("months");
            break;
          default:
            range = null;
            break;
        }
      }
      setRange(range);
    },
    [setRange],
  );

  const handleChoiceValue = useCallback(
    (selected) => {
      setActivatorLabel(selected);
      handleFormatTime(selected);
      setOpen(() => selected.includes(Custom));
      timeoutRef.current = setTimeout(() => {
        compareRef.current && compareRef.current.onCompare();
      });
    },
    [handleFormatTime],
  );

  const handleCustomTime = useCallback(
    ({ from, to, fromLabel, toLabel }) => {
      const label = [fromLabel, toLabel].filter(Boolean).join(" - ");
      const range = { from, to };

      setActivatorLabel(label);
      setRange(range);
      timeoutRef.current = setTimeout(() => {
        compareRef.current && compareRef.current.onCompare();
      });
    },
    [setRange],
  );

  useEffect(
    () => () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    },
    [],
  );

  useEffect(() => {
    const productBaseIds = productBase.value ? [productBase.value] : null;
    const collectionIds =
      collection.value?.length > 0 ? collection.value : null;
    const tagIds = tag.value?.length > 0 ? tag.value : null;

    const fulfillmentID =
      fulfillment.value?.length > 0 ? fulfillment.value : undefined;

    const storeManagerID =
      storeManager.value?.length > 0 ? storeManager.value : null;

    const storeID = store.value?.length > 0 ? store.value : null;
    setTimeout(() => {
      setFilter((prev) => ({
        ...prev,
        productBaseIds,
        collectionIds,
        tagIds,
        fulfillmentID,
        viewWholeTeamReport,
        storeManagerID,
        storeID,
      }));
    }, 500);
  }, [
    productBase.value,
    collection.value,
    tag.value,
    fulfillment.value,
    viewWholeTeamReport,
    setFilter,
    storeManager.value,
    store.value,
  ]);

  const handleBaseRemove = useCallback(() => {
    setProductBase({ value: null, label: null, search: null });
  }, []);
  const handleBaseChange = useCallback(({ value, label }) => {
    setProductBase((prev) => ({ ...prev, value, label }));
  }, []);
  const handleBaseSearch = useCallback(
    (search ) => setProductBase((prev) => ({ ...prev, search })),
    [],
  );

  const handleColChange = useCallback(
    ({ value, label }) => setCollection((prev) => ({ ...prev, value, label })),
    [],
  );
  const handleColSearch = useCallback(
    (search) => setCollection((prev) => ({ ...prev, search })),
    [],
  );
  const handleColRemove = useCallback(() => {
    setCollection({ value: [], label: [], search: null });
  }, []);

  const handleTagChange = useCallback(
    ({ value, label }) => setTag((prev) => ({ ...prev, value, label })),
    [],
  );
  const handleTagSearch = useCallback(
    (search) => setTag((prev) => ({ ...prev, search })),
    [],
  );
  const handleTagRemove = useCallback(() => {
    setTag({ value: [], label: [], search: null });
  }, []);

  const handleTeamRemove = useCallback(() => {
    setTeam({ value: null, label: null, search: null });
  }, []);

  const handleTeamChange = useCallback(({ value, label }) => {
    setTeam((prev) => ({ ...prev, value, label }));
    setTeamID(value);
  }, []);

  const handleTeamSearch = useCallback((search) => {
    setTeam((p) => ({ ...p, search }));
  }, []);

  const handleFulfillmentRemove = useCallback(
    () => setFulfillment(() => ({ value: null, label: null, search: null })),
    [],
  );

  const handleViewWholeTeamReport = useCallback((val) => {
    setViewWholeTeamReport(val);
  }, []);

  const handleViewWholeTeamReportRemove = useCallback(() => {
    setViewWholeTeamReport(false);
  }, []);

  const handleSMRemove = useCallback(() => {
    setStoreManager({ value: null, label: null, search: null });
  }, []);

  const handleSMChange = useCallback(({ value, label }) => {
    setStoreManager((p) => ({ ...p, value, label }));
  }, []);

  const handleSMSearch = useCallback((search) => {
    setStoreManager((p) => ({ ...p, search }));
  }, []);

  const handleStoreRemove = useCallback(() => {
    setStore({ value: null, label: null, search: null });
  }, []);

  const handleStoreChange = useCallback(({ value, label }) => {
    setStore((p) => ({ ...p, value, label }));
  }, []);

  const handleStoreSearch = useCallback((search) => {
    setStore((p) => ({ ...p, search }));
  }, []);

  const handleClearAll = useCallback(() => {
    handleBaseRemove();
    handleColRemove();
    handleTagRemove();
    handleTeamRemove();
    handleFulfillmentRemove();
    handleViewWholeTeamReportRemove();
    handleSMRemove();
    handleStoreRemove();
  }, [
    handleBaseRemove,
    handleColRemove,
    handleTagRemove,
    handleTeamRemove,
    handleFulfillmentRemove,
    handleViewWholeTeamReportRemove,
    handleSMRemove,
    handleStoreRemove,
  ]);

  // Markup
  const activator = useMemo(
    () => (
      <Button onClick={toggleActive} disclosure icon={CalendarMinor}>
        {activatorLabel}
      </Button>
    ),
    [toggleActive, activatorLabel],
  );

  const filters = [
    {
      key: "fulfillment",
      label: "Fulfillment by",
      tagLabel: fulfillment.label,
      onRemove: handleFulfillmentRemove,
      filter: (
        <FilterNoSearchPolaris
          data={fulfillments}
          value={fulfillment}
          loading={loadingFF}
          error={errorFF}
          onChange={({ value, label }) => {
            setFulfillment((prevState) => ({
              ...prevState,
              value,
              label,
            }));
          }}
          onChangeSearch={({ search }) =>
            setFulfillment((prevState) => ({ ...prevState, search }))
          }
        />
      ),
    },
    {
      key: "product-bases",
      label: "Product Bases",
      tagLabel: productBase.label,
      onRemove: handleBaseRemove,
      filter: (
        <FilterHasSearchPolaris
          options={productBases}
          value={productBase}
          loading={loadingPB}
          error={errorPB}
          onChange={handleBaseChange}
          onChangeSearch={handleBaseSearch}
        />
      ),
    },
    {
      key: "collections",
      label: "Collections",
      tagLabel: collection.label,
      onRemove: handleColRemove,
      filter: (
        <FilterHasSearchMVPolaris
          value={collection}
          options={collections}
          loading={loadingC}
          error={errorC}
          onChange={handleColChange}
          onChangeSearch={handleColSearch}
        />
      ),
    },
    {
      key: "tags",
      label: "Tags",
      tagLabel: tag.label,
      onRemove: handleTagRemove,
      filter: (
        <FilterHasSearchMVPolaris
          value={tag}
          options={tags}
          loading={loadingTag}
          error={errorTag}
          onChange={handleTagChange}
          onChangeSearch={handleTagSearch}
        />
      ),
    },
    (isSeller || isMarketplaceManager) && {
      key: "storeManager",
      label: "Store Manager",
      tagLabel: storeManager.label,
      onRemove: handleSMRemove,
      filter: (
        <FilterHasSearchPolaris
          options={storeManagers}
          value={storeManager}
          loading={loadingSM}
          error={errorSM}
          onChange={handleSMChange}
          onChangeSearch={handleSMSearch}
        />
      ),
    },
    (isSeller || isMarketplaceManager) && {
      key: "store",
      label: "Store",
      tagLabel: store.label,
      onRemove: handleStoreRemove,
      filter: (
        <FilterHasSearchPolaris
          options={stores}
          value={store}
          loading={loadingS}
          error={errorS}
          onChange={handleStoreChange}
          onChangeSearch={handleStoreSearch}
        />
      ),
    },
    isAdministrator && {
      key: "teams",
      label: "Teams",
      tagLabel: team.label,
      onRemove: handleTeamRemove,
      filter: (
        <FilterHasSearchPolaris
          options={teams}
          value={team}
          loading={loadingT}
          error={errorT}
          onChange={handleTeamChange}
          onChangeSearch={handleTeamSearch}
        />
      ),
    },
    canView && {
      key: "viewWholeTeamReport",
      label: "View Whole Team Report",
      tagLabel: viewWholeTeamReport ? "True" : "",
      onRemove: handleViewWholeTeamReportRemove,
      filter: (
        <Checkbox
          label="View whole team report"
          checked={viewWholeTeamReport}
          onChange={handleViewWholeTeamReport}
        />
      ),
    },
  ].filter(Boolean);

  const appliedFilters = [];
  for (let { key, label, onRemove, tagLabel } of filters) {
    if (!isEmpty(tagLabel)) {
      appliedFilters.push({
        key,
        label: disambiguateLabel(label, tagLabel),
        onRemove,
      });
    }
  }

  const handleClose = useCallback((evt) => {
    evt.stopPropagation();
    setOpen(false);
  }, []);

  return (
    <Fragment>
      <ButtonGroup>
        <Popover
          activator={activator}
          active={active}
          onClose={toggleActive}
          sectioned
          fluidContent
        >
          <Stack vertical>
            <ChoiceList
              choices={OPTIONS}
              selected={activatorLabel}
              onChange={handleChoiceValue}
            />
            <CompareToDates
              activatorLabel={activatorLabel}
              isCompare={isCompare}
              setIsCompare={setIsCompare}
              ref={compareRef}
            />
          </Stack>
        </Popover>
        {isLeader && (
          <FilterWrapper>
            <Filters
              filters={filters}
              onClearAll={handleClearAll}
              appliedFilters={appliedFilters}
              hideTags
            />
          </FilterWrapper>
        )}
        <ConfigWidgets title={title} />
      </ButtonGroup>
      <Modal open={open} onClose={handleClose} sectioned title="Custom Time">
        <FilterCustomTimePolaris
          customTime={customTime}
          onChange={handleCustomTime}
          isVertical={false}
          isFilter
        />
      </Modal>
    </Fragment>
  );

  function disambiguateLabel(label, value) {
    if (!label) {
      return value;
    }
    return `${label}: ${value}`;
  }
}

function ColumnsMarkup({ state, isFirst, propsByRole, inWrapper }) {
  // Props
  const { open, Secondary, Third } = state;
  const cols = isFirst ? Secondary : Third;

  if (open) return null;
  const key = isFirst ? 1 : 2;
  return cols.map((item, index) => {
    const Comp = item.component;
    if (!Comp) return null;
    const props = propsByRole[item.value];
    return (
      <Comp key={`section-${key}-${index}`} {...props} inWrapper={inWrapper} />
    );
  });
}

// Context
const CollapseContext = createContext({});
function CollapseContextProvider({ children, ...props }) {
  const value = useMemo(() => ({ ...props }), [props]);

  return (
    <CollapseContext.Provider value={value}>
      {children}
    </CollapseContext.Provider>
  );
}

export function useCollapseContext() {
  return useContext(CollapseContext);
}

function formatValue(array = []) {
  if (array?.length === 0) return [];
  return array.map((item) => ({ value: item?.id, label: item?.name }));
}

const getStoreManagers = (
  data,
  fn = (item) => {
    if (objectInvalid(item)) return null;
    const { id, firstName, lastName } = item.user ? item.user : item;
    const fn = [firstName, lastName].filter(Boolean).join(" ");
    return { value: id, label: fn };
  },
) => {
  if (arrInvalid(data)) return [];

  return data.map(fn).filter(Boolean);
};

const getStores = (
  data,
  fn = (item) => {
    if (objectInvalid(item)) return null;
    const { id, title } = item;
    return { value: id, label: title };
  },
) => {
  if (arrInvalid(data)) return [];

  return data.map(fn).filter(Boolean);
};

function getBases(data) {
  const nodes = get(data, "productBasesForSeller.nodes") || [];
  return nodes
    .map((i) => (i ? { value: i.id, label: i.title } : null))
    .filter(Boolean);
}

const FilterWrapper = styled.div`
  .Polaris-Filters-ConnectedFilterControl__CenterContainer {
    display: none;
  }

  .Polaris-Filters-ConnectedFilterControl__MoreFiltersButtonContainer * {
    border-top-left-radius: 3px !important;
    border-bottom-left-radius: 3px !important;
  }
`;

const Wrapper = styled.div`
  .index-wrap {
    width: 6rem;
    display: inline-block;
  }

  .column-wrap {
    width: 7rem;
    display: inline-block;
  }

  .name-wrap {
    display: flex;
    flex: 1 1;
  }
`;
