import { useMutation } from "@apollo/react-hooks";
import { Modal } from "@shopify/polaris";
import React, { useCallback, useRef } from "react";
import { ASSIGN_OWNER_FOR_STORE } from "../../../graphql/mutations";
import { arrInvalid, handleError } from "../../../helper";
import { TEAM_ROLE } from "../../../variable";
import { useToastContext } from "../../shared/ToastContext";
import StoreManagerSelect from "../../store-manager/store-manager-select";
import { useSelectRowsCtx } from "../SelectRowsProvider";

function BulkUpdateStoreManager({ open, onClose }) {
  const { toggleToast, setNotify } = useToastContext();
  const { ids, onCompleted } = useSelectRowsCtx();

  const [assignOwner, { loading }] = useMutation(ASSIGN_OWNER_FOR_STORE, {
    onCompleted: () => {
      setNotify({ msg: "Update store manager success", err: false });
      onCompleted && onCompleted();
    },
    onError: (err) => {
      setNotify({ msg: handleError(err?.toString()), err: true });
    },
  });

  const smRef = useRef(null);

  const handleSubmit = useCallback(() => {
    if (arrInvalid(ids)) return;
    const curRef = smRef.current;

    let ownerIDs = [];
    if (curRef) {
      ownerIDs = curRef.getSelected();
    }

    setNotify({ msg: null, err: false });
    toggleToast(true);

    assignOwner({
      variables: {
        ownerIDs,
        storeIDs: ids,
      },
    });
  }, [assignOwner, ids, setNotify, toggleToast]);

  return (
    <Modal
      title="Update Manager/Owner"
      sectioned
      open={open}
      onClose={onClose}
      primaryAction={{
        content: "Submit",
        onAction: handleSubmit,
        loading: loading,
      }}
      secondaryActions={[{ content: "Cancel", onAction: onClose }]}
    >
      <StoreManagerSelect
        roles={[TEAM_ROLE.StoreManager, TEAM_ROLE.Admin]}
        allowMultiple
        label="Choose store manager"
        required
        ref={smRef}
      />
    </Modal>
  );
}

export default BulkUpdateStoreManager;
