import React, { Component } from "react";
import PropTypes from "prop-types";
import { Form, Icon as LegacyIcon } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { Button, Col, Input, notification, Row, Select } from "antd";
import { isEqual } from "lodash";

class ProductBaseAttributes extends Component {
  state = {
    prevValue: [],
  };
  nameToSlug = (text) => {
    text = text.trim();
    if (!text) {
      return "";
    }
    return text
      .toLowerCase()
      .replace(/[^\w ]+/g, "")
      .replace(/ +/g, "-");
  };
  componentDidMount() {
    const { value, hasProduct } = this.props;
    if (hasProduct) {
      this.setState({ prevValue: { ...value } });
      //this.setState({ prevValue: JSON.parse(JSON.stringify(value)) });
    }
  }

  componentDidUpdate(prevProps) {
    const { value, hasProduct } = this.props;
    if (!isEqual(value, prevProps.value)) {
      if (hasProduct) {
        this.setState({ prevValue: { ...value } });
      }
    }
  }

  render() {
    let { value, hasProduct } = this.props;
    return (
      <div>
        {value &&
          value.map((attribute, index) => (
            <Form.Item key={index}>
              <Row gutter={16}>
                <Col span={5}>
                  <div style={{ minHeight: 40 }}>
                    <label>Options {index + 1}</label>
                  </div>
                  <Input
                    defaultValue={value[index].name}
                    onBlur={(e) => {
                      value[index].name = e.target.value;
                      value[index].slug = this.nameToSlug(e.target.value);
                      if (this.props.onChange) {
                        this.props.onChange(value);
                      }
                    }}
                    onChange={(e) => {
                      this.props.disableSubmitButton(false);
                    }}
                    style={{
                      borderColor: isType(value[index].name)
                        ? "#ff4d4f"
                        : "#c3ced9",
                    }}
                  />
                </Col>
                <Col span={5}>
                  <div style={{ minHeight: 40 }}>
                    <label>Slug</label>
                  </div>
                  <Input
                    defaultValue={value[index].slug}
                    value={value[index].slug}
                    disabled
                  />
                </Col>
                <Col span={14}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      minHeight: 40,
                    }}
                  >
                    <Button
                      disabled={hasProduct}
                      tabIndex={-1}
                      onClick={() => {
                        value.splice(index, 1);
                        if (this.props.onChange) {
                          this.props.onChange(value);
                        }
                      }}
                      type={"link"}
                    >
                      Remove
                    </Button>
                  </div>
                  <Select
                    allowClear={false}
                    value={value[index].options}
                    onChange={(selected) => {
                      if (hasProduct) {
                        const { prevValue } = this.state;
                        const {  options } = prevValue[index] || {}
                        const prevOptions = options  || []
                        let canDelete = true;
                        const prevString = prevOptions.join("");
                        const nextString = selected.join("");
                        for (let i = 0; i < prevString.length; i++) {
                          if (
                            !nextString[i] ||
                            prevString[i] !== nextString[i]
                          ) {
                            canDelete = false;
                          }
                        }
                        if (!canDelete) {
                          notification.warn({
                            message:
                              "Product base has product you can not remove exist options!",
                          });
                          return;
                        }
                      }
                      value[index].options = selected;
                      if (this.props.onChange) {
                        this.props.onChange(value);
                      }
                    }}
                    tokenSeparators={[","]}
                    mode={"tags"}
                  />
                </Col>
                {isType(value[index].name) && (
                  <p style={{ color: "#ff4d4f", paddingLeft: 8 }}>
                    You can't use option name "Type". Please enter other option
                    name.
                  </p>
                )}
              </Row>
            </Form.Item>
          ))}
        <Form.Item>
          <Button
            disabled={hasProduct}
            icon={<LegacyIcon type={"plus"} />}
            onClick={() => {
              /*value.push({
                name: "",
                options: [],
              });*/

				let params = [{
					name: "",
					options: [],
				}];
				if (value && value.length > 0) {
					params = [
						...value,
						...params,
					];
				}
				this.props.onChange(params);
              /* if (this.props.onChange) {
                this.props.onChange(value);
              }*/
            }}
          >
            {value && value.length > 0 ? "Add another option" : "Add option"}
          </Button>
        </Form.Item>
      </div>
    );
  }
}

function isType(value) {
  if (!value) return false;
  const pt = /^type$/i;
  return pt.test(value);
}

ProductBaseAttributes.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.array,
  hasProduct: PropTypes.bool,
  disableSubmitButton: PropTypes.func,
};

export default ProductBaseAttributes;
