import { useMutation } from "@apollo/react-hooks";
import { Modal } from "@shopify/polaris";
import React, { useCallback, useReducer } from "react";
import { ADD_STORE_TO_GROUP } from "../../../graphql/mutations";
import { useToastContext } from "../../shared/ToastContext";
import { arrInvalid, handleError, reducerFn } from "../../../helper";
import { useSelectRowsCtx } from "../SelectRowsProvider";
import { ComponentLabelPolaris } from "../../shared/ComponentLabelPolaris";
import GroupSelect from "../../group/components/group-select";

function BulkAddGroup({ open, onClose }) {
  const { toggleToast, setNotify } = useToastContext();
  const { ids, onCompleted } = useSelectRowsCtx();

  const [state, setState] = useReducer(reducerFn, {
    groupIDs: null,
    error: "",
  });

  const [addGroup, { loading }] = useMutation(ADD_STORE_TO_GROUP, {
    onCompleted: () => {
      setNotify({ msg: "Add store to Group success", err: false });
      onCompleted && onCompleted();
    },
    onError: (err) => {
      setNotify({ msg: handleError(err?.toString()), err: true });
    },
  });

  const handleGroupChange = React.useCallback((val) => {
    let newVal = val;
    if (newVal && typeof newVal === "string") {
      newVal = [newVal];
    }

    let newState = { error: "" };
    if (arrInvalid(newVal)) {
      newState.error = "Group is required";
    }
    setState({ groupIDs: newVal, ...newState });
  }, []);

  const handleSubmit = useCallback(() => {
    if (arrInvalid(ids)) return;

    if (arrInvalid(state.groupIDs)) {
      setState({ error: "Group is required" });
      return;
    } else {
      setState({ error: "" });
    }

    setNotify({ msg: null, err: false });
    toggleToast(true);

    addGroup({
      variables: {
        groupID: state.groupIDs[0],
        storeIDs: ids,
      },
    });
  }, [addGroup, ids, state.groupIDs, setNotify, toggleToast]);

  return (
    <Modal
      title="Add Group"
      sectioned
      open={open}
      onClose={onClose}
      primaryAction={{
        content: "Submit",
        onAction: handleSubmit,
        loading: loading,
      }}
      secondaryActions={[{ content: "Cancel", onAction: onClose }]}
    >
      <ComponentLabelPolaris label="Target group" required />
      <GroupSelect
        labelHidden
        value={state?.groupIDs}
        onChange={handleGroupChange}
        error={state.error || null}
      />
    </Modal>
  );
}

export default BulkAddGroup;
