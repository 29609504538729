import { CircleAlertMajorMonotone, CircleTickMajorMonotone, ReplaceMajorMonotone, VocabularyMajorMonotone } from "@shopify/polaris-icons";
import React from "react";
import { arrInvalid, reducerFn } from "../../../helper";
import { ActionList, Button, Popover } from "@shopify/polaris";
import SuspenseComp from "../../shared/SuspenseComp";
import { ViewDetailPolaris } from "../actions/ViewDetailPolaris";
import { get } from "lodash";
import { TASK_STATUS } from "../idea/utils";
import { useAppContext } from "../../../context";
import { AcceptDesignPolaris } from '../actions/AcceptDesignPolaris'
import { RejectDesignPolaris } from "../actions/RejectDesignPolaris";
import { useSelectRowsCtx } from "../../shared/SelectRow";
import { CompareDesignPolaris } from "../actions/CompareDesignPolaris";

function TableDataNeedReviewAction({ task }) {
  const { currentUser } = useAppContext();
  const role = get(currentUser, "teamUser.role");
  const { refetch, } = useSelectRowsCtx() || {};

  const [state, setState] = React.useReducer(reducerFn, {
    active: false,
    open: false,
    action: null,
  });

  const handleActiveChange = React.useCallback(
    (value) => () => {
      setState({ active: value });
    },
    [],
  );

  const handleOpenChange = React.useCallback(
    (value) => () => {
      setState((p) => ({ open: value, action: !!value ? p.action : null }));
    },
    [],
  );

  const handleActionTrigger = React.useCallback(
    (key) => () => {
      setState({ action: key, open: true });
    },
    [],
  );

  const actions = React.useMemo(() => {
    return ACTIONS.map(
      genActions({ role, onAction: handleActionTrigger }),
    ).filter(Boolean);
  }, [handleActionTrigger, role]);

  const actionMarkup = React.useMemo(() => {
    const props = {
      task,
      ids: task?.id ? [task.id] : [],
      open: state.open,
      status: task?.status,
      refetch,
      hasAcceptDesign: true,
      hasRejectDesign: true,
      modalTitle: "Compare design task",
      onClose: handleOpenChange(false),
      toggleShowModal: handleOpenChange(false),
    };

    return actions.reduce((acc, action) => {
      if (!action || !action.key || !action.comp) return acc;

      acc[action.key] = {
        ...action,
        props: { ...props, ...(action.props || {}) },
      };
      return acc;
    }, {});
  }, [handleOpenChange, actions, task, state.open, refetch]);

  let ActionObj = state.action ? actionMarkup[state.action] : null;
  const activator = React.useMemo(() => {
    return (
      <Button
        children="Actions"
        onClick={handleActiveChange(true)}
        disclosure
      />
    );
  }, [handleActiveChange]);

  return (
    <>
      <Popover
        activator={activator}
        onClose={handleActiveChange(false)}
        active={state.active}
        preferredAlignment="right"
      >
        <ActionList items={actions} />
      </Popover>
      {ActionObj ? (
        <SuspenseComp fallback={null}>
          <ActionObj.comp {...ActionObj.props} />
        </SuspenseComp>
      ) : null}
    </>
  );
}

const ACTIONS = [
  {
    content: "View detail",
    icon: VocabularyMajorMonotone,
    key: "view",
    comp: ViewDetailPolaris,
  },
  {
    content: "Compare Design",
    icon: ReplaceMajorMonotone,
    key: "compareDesign",
    comp: CompareDesignPolaris,
  },
  {
    content: "Accept Task",
    icon: CircleTickMajorMonotone,
    key: "acceptTask",
    comp: AcceptDesignPolaris,
  },
  {
    content: "Reject design",
    icon: CircleAlertMajorMonotone,
    key: "rejectDesign",
    comp: RejectDesignPolaris,
  },
];

const genActions =
  ({ onAction, role }) =>
  (item) => {
    let roleValid = true;
    if (!arrInvalid(item.role)) roleValid = item.role.includes(role);

    if (!roleValid) return false;

    return {
      ...item,
      onAction: onAction(item.key),
    };
  };

function isNeedReviewFn(task) {
  return isStatusFn(task, TASK_STATUS["Need Review"]);
}

function isStatusFn(task, targetStatus) {
  const status = get(task, "status");

  return status === targetStatus;
}

export default TableDataNeedReviewAction;
