import React, {
	useState,
	useCallback,
	useEffect,
	useRef,
	useReducer,
} from "react";
import { Filters } from "@shopify/polaris";
import { arrInvalid, checkRole, formatDataTree, genLabelTree, isEmpty, isPME_TEAM, reducerFn } from "../../../helper";
import { FilterListRadioPolaris } from "../../filters/FilterListRadioPolaris";

import { useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { useTeamMember } from "../../team-member/hooks";
import { TEAM_ROLE } from "../../../variable";
import { genOptions } from "../../team-member/components/team-member-select";
import { useAppContext } from "../../../context";
import { COLLECTIONS_QUERY, TAGS_QUERY } from "../../../pages/seller/ProductFiltersPolaris";
import { get } from "lodash";
import { FilterHasSearchMVPolaris } from "../../filters/FilterHasSearchMVPolaris";
import { FilterCreatedTimePolaris } from "../../product/FilterCreatedTimePolaris";
import { FilterNoSearchPolaris } from "../../filters/FilterNoSearchPolaris";

const ALL_ORDER_OPTIONS = [
	{ value: "DESC", label: "DESC" },
	{ value: "ASC", label: "ASC" },
];

const ALL_ORDER_BY_OPTIONS = [
	{ value: "title", label: "Title" },
	{ value: "created_at", label: "Created At" },
];


const LIST_ALL_CRAWL_DOMAINS = gql`
	query getTiktokCrawlProductDomains {
		getTiktokCrawlProductDomains
	}
`;

const All = "All";
const Yes = "Yes";
const No = "No";

const  BOOL_OPTIONS = [
  { value: All, label: All },
  { value: Yes, label: Yes },
  { value: No, label: No },
];


export default function ProductCrawlItemsFilter({ onFilterChange, filters: filtersProp }) {
	const { currentUser } = useAppContext();
	const isPMTeam = isPME_TEAM(currentUser);
	const { isSeller } = checkRole(currentUser);

	const [filterByDomain, setFilterByDomain] = useState('');
	const [filterOrdering, setFilterOrdering] = useState('DESC');
	const [filterOrderBy, setFilterOrderBy] = useState('created_at');
	const [queryValue, setQueryValue] = useState('');
	const [domainSources, setDomainSources] = useState([]);
	const [state, setState] = useReducer(reducerFn, {
		members: [],
		createdByID: "All",
		createdByName: "All",

		collections: [],
		colValue: [],
		colLabel: [],
		colSearch: "",

		tags: [],
		tagValue: [],
		tagLabel: [],
		tagSearch: "",

		pushValue: null,
		pushLabel: null,

		personalizedValue: null,
		personalizedLabel: null,

		rangeValue: null,
		rangeLabel: null,
	})

	const showCreatedBy = !isPMTeam || isPMTeam && isSeller 
	const typingTimeoutRef = useRef(null);


	const { loading: queryLoading, error: queryError, data: queryData } = useQuery(LIST_ALL_CRAWL_DOMAINS);

	const {
    data,
  } = useTeamMember({ roles: [TEAM_ROLE.Admin,TEAM_ROLE.MarketplaceManager, TEAM_ROLE.StoreManager]}, false, "id role user {id firstName lastName}");


	const {
    loading: loadingCollection,
    error: errorCollection,
    data: dataCollection,
  } = useQuery(COLLECTIONS_QUERY, {
    fetchPolicy: "network-only",
    variables: {
      filter: {
        search: state.colSearch,
        limit: 20,
        offset: 0,
      },
    },
  });

	const {
    loading: loadingTag,
    error: errorTag,
    data: dataTag,
  } = useQuery(TAGS_QUERY, {
    fetchPolicy: "network-only",
    variables: {
      filter: {
        search: state.tagSearch,
        limit: 20,
        offset: 0,
      },
    },
  });


	useEffect(() => {
		const nodes =  get(dataCollection, "collections.nodes") || []
    const newNodes = formatDataTree(nodes);
    setState({ collections: newNodes});
  }, [dataCollection]);


	useEffect(() => {
		const nodes =  get(dataTag, "tags.nodes") || []
    const newTags = formatDataTree(nodes);
    setState({ tags: newTags});
  }, [dataTag]);

	useEffect(() => {
		const nodes = genOptions(data);

		const newState = {
			members: arrInvalid(nodes) ? [] : nodes,
		}

		if (state?.createdByID && !arrInvalid(nodes)) {
			const cur = nodes.find((i) => i?.value === state.createdByID);
			if (cur) {
				newState.createdByID = cur.value;
				newState.createdByName = cur.label;
			}
		}

		setState({...newState})

	}, [JSON.stringify(data), state?.createdByID])

	useEffect(() => {
		let domains = queryData?.getTiktokCrawlProductDomains;
		if (domains?.length > 0) {
			let allDomains = [];
			domains.forEach((domain) => {
				if (domain) {
					allDomains.push({ value: domain, label: domain });
				}
			});
			setDomainSources(allDomains);
		} else {
			setDomainSources([]);
		}
	}, [queryData]);

	useEffect(() => {
		if (typingTimeoutRef.current) {
			clearTimeout(typingTimeoutRef.current);
		}
		typingTimeoutRef.current = setTimeout(() => {
			if (onFilterChange) {
				onFilterChange({
					search: queryValue ? queryValue.trim() : queryValue,
					domain: filterByDomain,
					orderBy: filterOrderBy,
					order: filterOrdering,
					createdByID: state.createdByID === "All" ? null : state.createdByID,
					range: state.rangeValue === "All" ? null : state.rangeValue,
					isPushed:  state.pushValue === Yes ? true : state.pushValue === No? false : null,
					collectionIDs: state.colValue?.length > 0 ? state.colValue : null,
					tagIDs:          state.tagValue?.length > 0 ? state.tagValue : null,
					isPersonalized: state.personalizedValue === Yes ? true : state.personalizedValue === No ? false : null,
				});
			}
		}, 300);
	}, [queryValue, filterByDomain, filterOrderBy, filterOrdering, state.createdByID, state.pushValue, state.colValue, state.tagValue, state.personalizedValue, state.rangeValue]);

	// Handle actions
	const handleQueryValueChange = useCallback(
		(value) => setQueryValue(value),
		[]
	);
	const handleQueryValueRemove = useCallback(() => setQueryValue(null), []);
	const handleCreatedByRemove = useCallback(() => setState({ createdByID: "All" }), []);

	const handleStateRemove = useCallback((key, isSingle) =>
    () => setState({ [`${key}Value`]: isSingle? null: [], [`${key}Label`]: isSingle ? null: []}),
    []
  );

	const handleStateChange = useCallback((key) => ({ value, label }) => {
    setState({ [`${key}Value`]: value, [`${key}Label`]: label})
  }, []);

	const handleFilterClearAll = useCallback(() => {
		handleQueryValueRemove();
		handleRemoveFiltering('origin_source');
		handleRemoveFiltering('ordering');
		handleRemoveFiltering('order_by');
		handleRemoveFiltering('created_by');

		for (let key of ['col', 'tag', 'push', 'personalized', 'range']) {
			let isSingle = ('push', 'personalized', 'range').includes(key);
			handleStateRemove(key, isSingle)()
		}
	}, [ handleQueryValueRemove, handleStateRemove ]);

	const handleRemoveFiltering = (key) => {
		switch (key) {
			case 'origin_source':
				setFilterByDomain('');
				break;
			case 'ordering':
				setFilterOrdering('');
				break;
			case 'order_by':
				setFilterOrderBy('');
				break;
			case 'keyword':
				handleQueryValueRemove();
				break;
			case 'created_by':
				handleCreatedByRemove();
				break;
			default: 
			break;
		}
	}

	const handleStateSearch = useCallback( (key) =>
    (search) => {
			setState({ [`${key}Search`]: search})
		},
    [],
  );

	const filters = [
		{
			key: "origin_source",
			label: "Origin Source",
			filter: (
				<FilterListRadioPolaris
					options={domainSources}
					value={filterByDomain}
					onChange={({ value, label }) => setFilterByDomain(value)}
				/>
			),
		},
		{
      key: "collection",
      label: "Collections",
      filter: (
        <FilterHasSearchMVPolaris
          value={{value: state.colValue }}
          options={state.collections}
          loading={loadingCollection}
          error={errorCollection}
          onChange={handleStateChange('col')}
          onChangeSearch={handleStateSearch('col')}
        />
      ),
    },
		{
      key: "tag",
      label: "Tags",
      filter: (
        <FilterHasSearchMVPolaris
          value={{ value: state.tagValue}}
          options={state.tags}
          loading={loadingTag}
          error={errorTag}
          onChange={handleStateChange('tag')}
          onChangeSearch={handleStateSearch('tag')}
        />
      ),
    },
		showCreatedBy && {
			key: "created_by",
			label: "Created By",
			filter: (
				<FilterNoSearchPolaris
					hasAll
					data={state.members}
					value={{ value: state.createdByID, label: state.createdByName }}
					onChange={({ value, label }) => {
						setState({ createdByID: value, createdByName: label });
					}}
				/>
			),
		},
		{
      key: "pushed",
      label: "Pushed",
      filter: (
        <FilterListRadioPolaris
          value={state.pushValue}
          options={ BOOL_OPTIONS}
          onChange={handleStateChange('push')}
        />
      ),
    },
		{
      key: "personalized",
      label: "Personalized",
      filter: (
        <FilterListRadioPolaris
          value={state.personalizedValue}
          options={BOOL_OPTIONS}
          onChange={handleStateChange('personalized')}
        />
      ),
    },
		{
      key: "created-time",
      label: "Created Time",
      filter: (
        <FilterCreatedTimePolaris
          filterTime={{ range: state.rangeValue, rangeLabel: state.rangeLabel,}}
          onChangeRange={(value, label) => {
            handleStateChange('range')({value, label })
          }}
        />
      ),
    },
		{
			key: "ordering",
			label: "Order",
			filter: (
				<FilterListRadioPolaris
					options={ALL_ORDER_OPTIONS}
					value={filterOrdering}
					onChange={({ value, label }) => setFilterOrdering(value)}
				/>
			),
		},
		{
			key: "order_by",
			label: "Order by",
			filter: (
				<FilterListRadioPolaris
					options={ALL_ORDER_BY_OPTIONS}
					value={filterOrderBy}
					onChange={({ value, label }) => setFilterOrderBy(value)}
				/>
			),
		},
	].filter(Boolean);
	const appliedFilters = [];

	if (!isEmpty(filterByDomain)) {
		const key = "origin_source";
		appliedFilters.push({
			key,
			label: disambiguateLabel(key, filterByDomain),
			onRemove: () => handleRemoveFiltering(key),
		});
	}
	if (!isEmpty(filterOrdering)) {
		const key = "ordering";
		appliedFilters.push({
			key,
			label: disambiguateLabel(key, filterOrdering),
			onRemove: () => handleRemoveFiltering(key),
		});
	}
	if (!isEmpty(filterOrderBy)) {
		const key = "order_by";
		appliedFilters.push({
			key,
			label: disambiguateLabel(key, filterOrderBy),
			onRemove: () =>  handleRemoveFiltering(key),
		});
	}
	if (!isEmpty(queryValue)) {
		const key = "keyword";
		appliedFilters.push({
			key,
			label: disambiguateLabel(key, queryValue),
			onRemove: () => handleRemoveFiltering(key),
		});
	}
	if (showCreatedBy && !isEmpty(state.createdByID) && state.createdByID !== "All" && state.createdByName !== "All") {
		const key = "created_by";
		appliedFilters.push({
			key,
			label: disambiguateLabel(key, state.createdByName),
			onRemove: () => handleRemoveFiltering(key),
		});
	}

	if (!isEmpty(state.colLabel)) {
    const key = "collection";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, state.colLabel),
      onRemove: handleStateRemove('col'),
    });
  }

  if (!isEmpty(state.tagLabel)) {
    const key = "tag";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, state.tagLabel),
			onRemove: handleStateRemove('tag'),
    });
  }

	if (!isEmpty(state.pushLabel)) {
    const key = "pushed";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, state.pushLabel),
      onRemove: handleStateRemove('push', true),
    });
  }

	if (!isEmpty(state.personalizedLabel)) {
    const key = "personalized";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, state.personalizedLabel),
      onRemove: handleStateRemove('personalized', true),
    });
  }

	if (!isEmpty(state.rangeLabel)) {
    const key = "created-time";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, state.rangeLabel),
      onRemove: handleStateRemove('range', true),
    });
  }

	return (
		<Filters
			queryPlaceholder={`Search crawl item`}
			queryValue={queryValue}
			onQueryChange={handleQueryValueChange}
			onQueryClear={handleQueryValueRemove}
			onClearAll={handleFilterClearAll}
			filters={filters}
			appliedFilters={appliedFilters}
		/>
	);
	function disambiguateLabel(key, value) {
		switch (key) {
			case "keyword":
				return `Keyword: ${value}`;
			case "origin_source":
				return `Origin source: ${value}`;
			case "ordering":
				return `Order: ${value}`;
			case "order_by":
				if (ALL_ORDER_BY_OPTIONS) {
					let orderBy = ALL_ORDER_BY_OPTIONS.find((item) => item.value === value);
					if (orderBy && orderBy.label) {
						return `Order by: ${orderBy.label}`;
					}
				}
				return `Order by: ${value}`;
			case 'created_by':
				return `Created by: ${value}`
			case "collection":
				let collectionLabel = genLabelTree(value);
				return `Collections: ${collectionLabel}`;
      case "tag":
        let tagLabel = genLabelTree(value);
        return `Tags: ${tagLabel}`;
			case "pushed":
				return `Pushed: ${value}`;
			case "personalized":
				return `Personalized: ${value}`;
			case "created-time":
				return `Created time: ${value}`;
			default:
				return value;
		}
	}
};
